@use '../../../../assets/scss/utilities/mixins' as *;

.ooredoo-input-field {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  /* Additional styles to remove the yellow background in Chrome */
  input:-webkit-autofill {
    -webkit-text-fill-color: inherit !important;
  }
  @include mq(881px, max) {
    width: 100%;
  }
  & label {
    font-weight: 400;
    line-height: 13px;
    color: #55819a;
    font-size: 1.06rem;

    display: flex;
    justify-content: start;
    align-items: flex-start;

    @media only screen and (min-width: 1210px) {
      font-size: 18px;
    }
    & p {
      font-family: 'Poppins' !important;
      margin-block: 5px;
      @media only screen and (min-width: 1210px) {
        margin-block: 1rem;
      }
    }

    & > .required-field {
      color: #ed3863;
      font-size: 11px;

      padding: 5px 2px;

      @media only screen and (min-width: 1210px) {
        padding: 15px 3px;
      }
    }
    p {
      text-align: end;
    }
  }
  .arabic-error {
    justify-content: flex-end;
  }

  & .label-description {
    font-size: 13px;
    color: #cecece;
    font-weight: 300 !important;
  }
  & .field-wrapper {
    margin-top: 5px;
    & > .field {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28rem;
      height: 2.9rem;
      border-radius: 10px;
      background: #ffffff;
      border: 1px solid #55819a;
      font-size: 15px;
      line-height: 22px;
      width: 100%;
      padding: 0 15px;

      & > img {
        margin-bottom: 2px;
      }
      @media screen and (max-width: 350px) {
        padding: 0 !important;
      }
      &:focus-within {
        border: 2px solid #2ba7df;
      }
      @include mq(881px, max) {
        width: 100%;
      }
      & > input {
        width: 100%;
        outline: none;
        border: none;
        background: white !important;
        border-radius: 46px;
        padding: 0 9px;

        &::placeholder {
          font-family: 'Poppins' !important;
          color: #8d8d8d;
          font-weight: 300;

          font-size: 0.8rem;
          line-height: 13px;
          display: flex;
          align-items: center;
          color: #96aac4;

          @media only screen and (max-width: 1530px) and (min-width: 1209px) {
            font-size: 12px !important;
            line-height: 12px;
          }
          @media only screen and (min-width: 1210px) {
            font-size: 17px;
            line-height: 26px;
          }
        }
      }
    }

    & .helper {
      color: #8b8888;
      font-size: 12px;
      width: 83%;
      font-family: 'Tajawal' !important;
      margin-left: 7px;
    }
    & .arabic-helper {
      text-align: end;
      width: 100% !important;
    }
    & .field-error-style {
      background: #fbe5e8 !important;
      border: 1px solid #ed3863 !important;
      & > input {
        background: #fbe5e8 !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fbe5e8 inset !important;
      }
    }
    & .MuiFormControl-root {
      display: flex;
      justify-content: center;
      width: 300px;
      height: 60px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      font-size: 15px;
      line-height: 22px;
      padding: 0 15px;

      & .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
        border: none !important;
      }
      & .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border: none !important;
      }

      & .css-1vv4lmi::before {
        border: none !important;
      }
      & .css-1vv4lmi::after {
        border: none !important;
      }
    }
    @media screen and (max-width: 650px) {
      padding-top: 0 !important;
    }
  }
}
.customized-input {
  & > .field-wrapper {
    & > .field {
      padding: 0 15px !important;
      & > input {
        padding: 0 !important;
      }
    }
  }
}

.address-input {
  width: 100%;
  & .field {
    width: 100% !important;
  }
}

.update-level-form {
  & .field {
    width: auto !important;
    height: auto !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #e8e8e8 !important;
    &:focus-within {
      border: unset !important;
      border-bottom: 2px solid #2ba7df !important;
    }
  }
}
