.parrain-page-card-info {
  border-radius: 8px !important;
  flex-basis: 69%;
  box-shadow: 0px 3.719px 7.438px 0px rgba(179, 208, 232, 0.6) !important;
  min-width: 16rem;
  height: fit-content;

  @media screen and (max-width: 1500px) and (min-width: 1216px) {
    min-width: 30rem;
  }
  @media screen and (max-width: 1215px) and (min-width: 900px) {
    min-width: 26rem;
  }
  @media screen and (max-width: 1300px) {
    flex-basis: 70%;
  }

  & > iframe {
    width: 100%;
    min-height: 16.2rem;
    border-radius: 8px 8px 0px 0px;
    border: unset !important;
  }
  .parrain-page-card-info-accordion-summary-expanded {
  }
  .parrain-page-card-info-content {
  }
  .parrain-page-card-info-info-details-accordion {
    margin: 0 !important;
  }
  & .parrain-page-card-info-content {
    padding: 0 !important;
    box-shadow: 0px 3.719px 7.438px 0px rgba(179, 208, 232, 0.6) !important;

    .parrain-page-card-info-info-details-accordion {
      &::before {
        background-color: unset !important;
      }
    }
    .parrain-page-card-info-accordion-wrapper {
      box-shadow: unset !important;
      & > .MuiCollapse-root {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 1.837px 7.346px 0px rgba(179, 208, 232, 0.6);
      }

      .parrain-page-card-info-accordion-summary {
        min-height: 4.5rem !important;
        max-height: 4.5rem;
        transition: all ease 0.3s;
        padding: 0 1rem 0 2.1rem !important;

        & > .Mui-expanded {
          margin: 0 !important;
        }
        .parrain-page-card-info-select {
          width: 100%;
          & > p {
            margin-block: 0 !important;
          }
        }
      }
      .parrain-page-card-info-accordion-summary-expanded {
        max-height: 3rem;
        min-height: 2.2rem !important;
        transition: all ease 0.3s;
        padding: 0 1rem 0 2.1rem !important;
      }
      .parrain-page-card-info-info-details-accordion {
        box-shadow: unset !important;
      }
    }

    .MuiAccordionDetails-root {
      flex-direction: column;
    }
    & .parrain-page-card-info-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    & > p {
      color: #000;
      text-align: center;
      font-family: Roboto !important;
      font-size: 13.876px;
      font-weight: 400;
      line-height: 20.637px;
      letter-spacing: 0.083px;
    }
  }
  .parrain-page-card-info-accordion-details {
    & .MuiCollapse-root {
      background: #ebf7fe !important;
    }
    & .MuiAccordionSummary-expandIcon.Mui-expanded {
      transform: rotate(45deg) !important;
    }
    & .MuiAccordionSummary-content {
      & > p {
        color: #000;
        font-family: Roboto !important;
        font-size: 13.775px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }
    .parrain-page-card-info-title {
      max-height: 2.1rem !important;
      transition: all ease 0.3s;
      @media screen and (max-width: 420px) {
        max-height: 4.1rem !important;
      }
    }
    .parrain-page-card-info-title-expanded {
      background: #ebf7fe !important;
      border-bottom: 2px solid #e4eff5;
      max-height: 2.2rem !important;
      min-height: unset;
      @media screen and (max-width: 420px) {
        max-height: 4.2rem !important;
      }
    }
    .parrain-page-card-info-description {
      color: rgba(60, 60, 67, 0.85);
      font-family: Roboto !important;
      font-size: 14.693px;
      font-weight: 400;
      line-height: 147.5%;
      padding: 0.1rem 0.5rem;
    }
  }
}
