.product-card-wrapper {
  all: unset;
  cursor: pointer;
  border-radius: 23px;
  background: #fff;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.06);
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  max-width: 9.5rem;
  min-width: 9.5rem;
  height: 19rem;

  // @media screen and (max-width: 335px) {
  //   max-width: 10rem;
  //   min-width: 10rem;
  // }
  .product-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    justify-content: space-between;
    .product-card-img {
      border-radius: 11px;
      background: #f9f9f9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12.7rem;
      width: 100%;

      & > img {
        // border-radius: 11px;
        // height: 90%;
        // max-height: 100%;

        // object-fit: fill;
        // width: 92%;
        border-radius: 11px;
        height: 100%;
        max-height: 100%;
        object-fit: fill;
        width: 100%;
      }
    }
    .product-card-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      .poduct-name {
        text-transform: capitalize;
        font-weight: 500;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .card-btn {
        font-family: Gothic A1 !important;
        font-size: 13.132px !important;
        font-weight: 700 !important;
        padding: 0.7rem 0.63rem !important;
      }
    }
  }
}
