@use '../../../../assets/scss/utilities/mixins' as *;

.auth-page-languages {
  & .MuiSelect-select {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end !important;
    gap: 10px !important;
    padding-top: 11px !important;
    &:focus {
      background-color: unset !important;
    }
  }

  & .text {
    font-family: 'Tajawal' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #666666;
  }
}

.auth-page-language {
  display: flex !important;
  justify-content: space-between !important;
  font-family: 'Tajawal' !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #666666 !important;
  padding: 6px 10px !important;
  gap: 16px !important;
}
.MuiPaper-elevation8 {
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px !important;
}
