@use '../../assets/scss/utilities/mixins' as *;
@import '../../assets/scss/utilities/variables';

.welcome-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-modal-wrapper {
  z-index: 2300 !important;
  & .close-modal-btn {
    color: $grey !important;
    font-weight: bold !important;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: flex-end;
    opacity: 1;
    margin-right: -13px;

    @media only screen and (max-width: 570px) {
      margin-right: -42px;
      padding: 6px;
      padding-top: 11px;
    }

    &:hover {
      text-shadow: 0px 0px 0.5px $grey;
    }
  }
  & .MuiDialog-paper {
    min-width: 75% !important;
    height: fit-content;
    box-shadow: unset !important;
    border-radius: 55.77px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 96.3vh !important;
    overflow-x: hidden;

    & .MuiDialogTitle-root {
      padding-top: 0 !important;
      margin-top: -0.8rem !important;
      display: flex;
      justify-content: center;
      align-items: center;

      & > .MuiTypography-root {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 380px) {
      margin: 15px !important;
    }
    @media only screen and (max-width: 347px) {
      margin: 7px !important;
    }
    @media only screen and (max-width: 332px) {
      margin: 1px !important;
    }
    @media only screen and (min-width: 1210px) {
      & .MuiDialogTitle-root {
        padding: 0;
      }
    }

    & .MuiDialogContent-root {
      width: 75%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5.5vw;
      direction: rtl;
      flex-direction: column;

      @media only screen and (max-width: 1824px) {
        margin-top: 5.5vw;
      }
      @media only screen and (max-width: 1715px) {
        margin-top: 7vw;
      }
      @media only screen and (max-width: 920px) {
        width: 94%;
      }
      @media only screen and (max-width: 570px) {
        margin-top: 11vw;
      }
      @media only screen and (max-width: 380px) {
        width: unset !important;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      & .welcome-modal-description {
        color: #25406c;
        text-align: center;
        font-family: Tajawal !important;
        font-size: 21px;
        font-weight: 400;
        line-height: 30.674px;
        text-align: center;
        margin-bottom: 1.6rem;
        overflow: auto;
        @media only screen and (max-width: 1150px) {
          margin-bottom: 1.6rem;
        }
        @media only screen and (max-width: 570px) {
          font-size: 1rem;
        }
      }
      & .welcome-modal-btn {
        border-radius: 54.841px;
        background: #35bbe3;
        display: flex;
        width: 73%;
        padding: 10px 9.295px;
        justify-content: center;
        align-items: center;
        gap: 9.295px;
        color: #fff;
        text-align: center;
        font-family: Tajawal !important;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 2rem;
        @media only screen and (max-width: 485px) {
          font-size: 1rem;
        }
      }
    }
  }

  & .welcome-modal-header {
    width: 100%;
    background: #dcfeff;
    border-radius: 0.77px 55.77px 158.016px 158.016px;
    direction: rtl;
    padding: 0rem 4.6rem;
    position: relative;
    height: 48%;
    @media only screen and (max-width: 1150px) {
      height: 33%;
      border-radius: 0.77px 55.77px 124px 124px;
    }
    @media only screen and (max-width: 920px) {
      border-radius: 0.77px 55.77px 97px 97px;
    }
    @media only screen and (max-width: 570px) {
      border-radius: 0.77px 55.77px 57px 57px;
    }
    @media only screen and (max-width: 405px) {
      border-radius: 0.77px 0.77px 47px 47px;
    }
    & .welcome-modal-title {
      direction: rtl;
      color: #30548b;
      text-align: right;
      font-family: Tajawal !important;
      font-size: 36px;
      font-weight: 700;
      margin-top: 0.2rem;
      margin-bottom: -0.6rem;
      @media only screen and (max-width: 930px) {
        font-size: 2rem;
      }
      @media only screen and (max-width: 855px) {
        font-size: 1.7rem;
      }
      @media only screen and (max-width: 640px) {
        font-size: 1.5rem;
      }
      @media only screen and (max-width: 600px) {
        font-size: 1rem;
      }
    }
    & .welcome-img {
      position: absolute;
      top: 6vw;
      width: 39.5%;
      @media only screen and (max-width: 1900px) {
        top: 6vw;
      }
      @media only screen and (max-width: 1780px) {
        top: 7.5vw;
      }
      @media only screen and (max-width: 1580px) {
        top: 9.5vw;
      }
      @media only screen and (max-width: 1470px) {
        top: 8.5vw;
      }

      @media only screen and (max-width: 1290px) {
        top: 9.5vw;
      }
      @media only screen and (max-width: 1250px) {
        top: 9.5vw;
      }
      @media only screen and (max-width: 1160px) {
        top: 10.5vw;
      }
      @media only screen and (max-width: 1096px) {
        top: 14.5vw;
      }
      @media only screen and (max-width: 1048px) {
        top: 14.5vw;
      }
      @media only screen and (max-width: 970px) {
        top: 15.5vw;
      }
      @media only screen and (max-width: 930px) {
        top: 15.5vw;
      }
      @media only screen and (max-width: 825px) {
        top: 19.5vw !important;
      }
      @media only screen and (max-width: 720px) {
        top: 22.5vw !important;
      }
      @media only screen and (max-width: 660px) {
        top: 24.5vw !important;
      }
      @media only screen and (max-width: 570px) {
        top: 20vw !important;
      }
      @media only screen and (max-width: 540px) {
        top: 22vw !important;
      }
      @media only screen and (max-width: 520px) {
        top: 24vw !important;
      }
      @media only screen and (max-width: 495px) {
        top: 25vw !important;
      }
      @media only screen and (max-width: 480px) {
        top: 18vw !important;
      }
      @media only screen and (max-width: 450px) {
        top: 20vw !important;
      }
      @media only screen and (max-width: 420px) {
        top: 23vw !important;
      }
      @media only screen and (max-width: 400px) {
        top: 25vw !important;
      }
      @media only screen and (max-width: 365px) {
        top: 27vw !important;
      }
      @media only screen and (max-width: 345px) {
        top: 29vw !important;
      }
      @media only screen and (max-width: 325px) {
        top: 32vw !important;
      }
    }
    & .welcome-bg {
      width: 85%;
      margin-top: 2rem;

      @media only screen and (max-width: 1096px) {
        width: 100%;
      }
      @media only screen and (max-width: 825px) {
        width: 32rem;
      }
      @media only screen and (max-width: 650px) {
        width: 26rem;
      }
      @media only screen and (max-width: 570px) {
        width: 27rem;
      }
      @media only screen and (max-width: 485px) {
        width: 19rem;
      }
    }

    & > .MuiButtonBase-root {
      padding: 0px 10px;
      border-radius: 29px;
      background: #fff;
      margin-top: 1.8rem;
      padding-top: 10px;

      @media only screen and (max-width: 485px) {
        margin-right: -3.5rem;
        margin-top: 1rem;
      }

      & > span {
        & > span {
          height: 40px;
          color: #135f98;
          font-family: Tajawal !important;
          font-size: 28px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 570px) {
            font-size: 1rem;
            height: 13px;
          }
        }
      }
    }
  }
}
