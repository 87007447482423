.convert-coins-modal {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      max-width: 74.5rem;
      border-radius: 20px;
      background: #f8fdff;
      align-self: center;
      margin-top: 5rem;

      @media screen and (max-width: 1520px) {
        max-width: 70.5rem;
      }

      @media screen and (max-width: 810px) {
        align-self: center;
        height: 80%;
      }
      @media screen and (max-width: 620px) {
        align-self: flex-end;
      }

      @media screen and (max-width: 685px) {
        height: 82%;
      }
      @media screen and (max-width: 380px) {
        margin: 15px !important;
        height: 86%;
        align-self: center;
        margin-top: 3rem !important;
      }
      @media screen and (max-width: 300px) {
        height: 84%;
      }
      @media screen and (max-width: 289px) {
        height: 80%;
      }

      & > .MuiDialogTitle-root {
        align-self: flex-end;
        padding-bottom: 0 !important;
      }
      & .ta-modal-content-scroll {
        padding: 0rem 5rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2.2rem;
        max-height: 85vh !important;
        padding-top: 3vw !important;

        @media screen and (max-height: 796px) {
          padding-top: 5vw !important;
        }

        @media screen and (max-width: 860px) {
          padding: 0rem 2rem !important;
          gap: 0.2rem;
        }

        @media screen and (max-width: 485px) {
          padding: 3rem 1rem 3rem !important;
        }

        @media screen and (max-width: 380px) {
          padding: 3rem 1rem 1rem !important;
        }
      }
      & .close-btn {
        position: relative;
        width: 2rem;

        & > img {
          position: absolute;
        }

        &:hover {
          transition: all ease 0.3s;
          & > img {
            transform: scale(1.1);
            filter: invert(56%) sepia(74%) saturate(2749%) hue-rotate(167deg) brightness(98%)
              contrast(98%);
          }
        }
      }
    }
  }
  .convert-coins-modal-description {
    color: #293f4f;
    text-align: center;
    font-family: Roboto !important;
    font-size: 21px;
    font-weight: 400;
    line-height: 28.178px;
    letter-spacing: 0.346px;
    margin-block: 0 !important;

    @media screen and (max-width: 860px) {
      width: 90%;
      font-size: 18px;
    }

    @media screen and (max-width: 810px) {
      font-size: 17px;
      line-height: 23px;
    }
  }
  .convert-coins-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-bottom: 3.2rem;

    @media screen and (max-width: 988px) {
      gap: 2rem;
    }
    @media screen and (max-width: 860px) {
      height: 75%;
    }

    @media screen and (max-width: 810px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 620px) {
      flex-direction: column;
      overflow: auto;
      justify-content: flex-start;
      padding: 2rem 1rem;
    }
  }
}
