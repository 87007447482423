@use '../../../assets/scss/utilities/mixins' as *;

.form-control {
  padding: 5px 14px 0px 5px;
  margin-left: 10px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  /* Additional styles to remove the yellow background in Chrome */
  input:-webkit-autofill {
    -webkit-text-fill-color: inherit !important;
  }
  @include mq(881px, max) {
    width: 100%;
  }
  @media screen and (max-width: 285px) {
    margin-left: 0 !important;
  }
  & label {
    font-family: 'Tajawal' !important;
    font-weight: 500;
    font-size: 22px;
    line-height: 14px;
    color: #494949;
    display: flex;
    justify-content: start;
    align-items: flex-start;

    // white-space: nowrap;

    @media (min-width: 1351px) and (max-width: 1512px) {
      font-size: 20px !important;
    }
    @media (min-width: 1055px) and (max-width: 1350px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 787px) {
      line-height: 22px;
    }

    & > .required-field {
      color: #ed3863;
      font-size: 13px;
      padding: 0px 5px;
      @media screen and (max-width: 1550px) and (min-width: 650) {
        padding: unset;
        padding-left: 5px;
      }
    }
    p {
      // text-align: end;
      text-align: center;
      margin-block: 4px;
    }

    @media screen and (max-width: 1550px) and (min-width: 650) {
      font-size: 18px;
    }
    @media screen and (max-width: 650px) {
      font-size: 1.2rem;
      line-height: 32px;
      p {
        margin-bottom: 0 !important;
      }
    }
    @media screen and (max-width: 380px) {
      font-size: 1rem;
    }
  }
  .arabic-error {
    justify-content: flex-end;
  }
  & .arabic-label {
    display: flex;
    justify-content: end;
    // margin-right: 11px;

    @media screen and (max-width: 1350px) and (min-width: 1055) {
      padding-right: 9px;
    }
    & > p {
      font-family: 'Tajawal' !important;
    }
  }
  & .label-description {
    font-size: 13px;
    color: #cecece;
    font-weight: 300 !important;
  }

  & .field-wrapper-reset-form {
    padding-top: 0px !important;
  }
  & .field-wrapper {
    // padding-top: 15px;
    padding-top: 43px;
    @media screen and (max-width: 1350px) {
      // padding-top: 26px !important;
    }

    & .arabic-field {
      padding: 0 15px 0 26px !important;
    }
    & > .field {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 67px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 46px;
      font-size: 15px;
      line-height: 22px;
      width: 100%;
      // padding: 0 15px;
      padding: 0 26px 0 15px;

      @media (min-width: 1351px) and (max-width: 1512px) {
        height: 57px !important;
      }
      @media (min-width: 1055px) and (max-width: 1350px) {
        height: 48px !important;
      }
      @media screen and (max-width: 414px) {
        height: 54px !important;
        & input {
          height: 51px !important;
        }
      }

      &:focus-within {
        border: 2px solid #2ba7df;
      }
      @include mq(881px, max) {
        width: 100%;
      }
      & > input {
        width: 100%;
        outline: none;
        border: none;
        background: white !important;
        border-radius: 46px;
        padding: 0 15px;
        height: 60px;
        font-size: 15px;
        &::placeholder {
          font-family: 'Tajawal' !important;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #8d8d8d;
          @media (min-width: 1055px) and (max-width: 1350px) {
            font-size: 0.8rem;
            line-height: 8px;
          }

          @media screen and (max-width: 650px) {
            font-size: 1.2rem;
          }
          @media screen and (max-width: 412px) {
            font-size: 1rem;
          }
        }
        @media (min-width: 1351px) and (max-width: 1512px) {
          height: 52px !important;
        }

        @media (min-width: 1055px) and (max-width: 1350px) {
          height: 46px;
        }
      }

      .phone-number-tag {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #616161;
        margin-right: 8px;
      }
    }

    & .helper {
      color: #8b8888;
      font-size: 12px;
      width: 83%;
      font-family: 'Tajawal' !important;
      margin-left: 7px;
    }
    & .arabic-helper {
      text-align: end;
      margin-left: -2px !important;
      width: 100% !important;
    }
    & .field-error-style {
      background: #fbe5e8 !important;
      border: 1px solid #ed3863 !important;
      & > input {
        background: #fbe5e8 !important;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fbe5e8 inset !important;
      }
    }
    & .MuiFormControl-root {
      display: flex;
      justify-content: center;
      width: 300px;
      height: 60px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      font-size: 15px;
      line-height: 22px;
      padding: 0 15px;

      & .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
        border: none !important;
      }
      & .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border: none !important;
      }

      & .css-1vv4lmi::before {
        border: none !important;
      }
      & .css-1vv4lmi::after {
        border: none !important;
      }
    }

    @media (min-width: 1351px) and (max-width: 1512px) {
      padding-top: 6px;
    }
    @media screen and (max-width: 650px) {
      padding-top: 10px !important;
    }
    @media screen and (max-width: 414px) {
      padding-top: 0 !important;
    }
  }
}
.arabic-form-control {
  & input {
    direction: rtl;
  }
}
.customized-input {
  & > .field-wrapper {
    & > .field {
      padding: 0 15px !important;
      & > input {
        padding: 0 !important;
      }
    }
  }
}

.address-input {
  width: 100%;
  & .field {
    width: 100% !important;
  }
}

.update-level-form {
  & .field {
    width: auto !important;
    height: auto !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #e8e8e8 !important;
    &:focus-within {
      border: unset !important;
      border-bottom: 2px solid #2ba7df !important;
    }
  }
}
