@use "./../../assets/scss/utilities/mixins" as *;

.form-error {
  color: #ed3863;
  font-size: 14px;
  display: flex;
  margin-bottom: -21px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & > svg {
    width: 16px !important;
  }
}
.login-page {
  & .auth-page-content {
    margin-top: 21px !important;
  }

  @media screen and (max-width: 1350px) {
    .content-title {
      margin-bottom: 30px !important ;
    }

    .login-page-content {
      margin-top: 1rem !important;
    }
  }
}
.login-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
  .forget-password-remmeber-me-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 1351px) and (max-width: 1512px) {
    gap: 1.5rem;
    margin-top: 4rem;

    & .small-form {
      & form {
        & .MuiBox-root {
          row-gap: 15px !important;
        }

        & .form-control {
          & label {
            line-height: 2rem;
          }
        }
      }
    }
  }
}
