.optimium-modal {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background-color: #edf5ff;
        border-bottom: 4px solid #66a3d0;
        border-radius: 10px 10px 100px 100px;
        gap: 15px;
        position: relative;
        span {
            color: #000;
            text-align: center;
            font-family: Tajawal;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }
        .close-optimium {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .description {
        padding-top: 40px;
        color: #366eab;
        text-align: center;
        padding: 20px;
        font-family: Tajawal;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
    .subtitle {
        color: #35bbe3;
        text-align: center;
        font-family: Tajawal;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        padding-top: 0px;
        line-height: 26px;
    }
    .options-list {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px 30px;
        .item {
            display: flex;
            padding: 10px;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }
    .buy-btn-container {
        display: flex;
        padding-top: 10px;
        padding-bottom: 25px;
        justify-content: center;
        .buy-btn {
            color: #fff;
            cursor: pointer;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 10px;
            background: #54a8ee;
            box-shadow: 0px 4px 13px 2px rgba(84, 168, 238, 0.33);
            width: 160.298px;
            height: 41.556px;
            flex-shrink: 0;
        }
    }
}
