button {
  all: unset;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  gap: 10px;
  text-transform: capitalize;
  cursor: pointer;
  transition: background 0.8s;
  background-position: center;
}

.white-btn {
  border: 1px solid #e8e8e8;
  background: white;
  color: #91939a;
  padding: 10px 14.6px;
  &:hover {
    background-color: darken(#e8e8e8, 10);
    color: white;
    background-image: radial-gradient(circle, transparent 1%, darken(#e8e8e8, 10) 1%);
    background-position: center;
    background-size: 15000%;
  }
  &:active {
    transition: background 0s;
    background-color: #f0f0f0;
    background-size: 100%;
  }
}
.blue-btn {
  gap: 10px;
  background: #00aaf9;
  border: none;
  color: white !important;
  padding: 10px 13.5px;
  &:hover {
    background-color: darken(#00aaf9, 12);
    color: white;
    background-image: radial-gradient(circle, transparent 1%, darken(#00aaf9, 12) 1%);
    background-position: center;
    background-size: 15000%;
  }
  &:active {
    transition: background 0s;
    background-color: #6eb9f7;
    background-size: 100%;
  }
}

.small {
  padding: 0;
  padding: 5px 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px !important;
}

.outlined-blue {
  border: 1px solid #00aaf9 !important;
  color: #0098de;

  &:hover {
    background-color: #e4f0fd !important;
    color: #0098de;
    border: 1px solid #0098de;
    background-image: radial-gradient(circle, transparent 1%, darken(#e4f0fd, 4) 1%);
    background-position: center;
    background-size: 15000%;
  }
  &:active {
    background-color: #e4f0fd !important;
    color: #0098de;
    border: 1px solid #0098de;
    transition: background 0s;
    background-size: 100%;
  }
}
.outlined-red {
  border: 1px solid #ed3863 !important;
  color: #ed3863;

  &:hover {
    background-color: #f0dee2 !important;
    color: #ed3863;
    border: 1px solid #ed3863;
    background-image: radial-gradient(circle, transparent 1%, darken(#f0dee2, 4) 1%);
    background-position: center;
    background-size: 15000%;
  }
  &:active {
    background-color: #f0dee2 !important;
    color: #ed3863;
    border: 1px solid #ed3863;
    transition: background 0s;
    background-size: 100%;
  }
}

.gray-btn {
  background: #f0f0f0;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #000000 !important;
  &:hover {
    background-color: derken(#f0f0f0, 10);
    background-position: center;
    background-size: 15000%;
  }
  &:active {
    transition: background 0s;
    background-color: #f0f0f0;
    background-size: 100%;
  }
}

.sort-btn {
  padding: 10px 31px !important;
}

.export-btn {
  @extend .white-btn;
  padding: 10px 17px !important;
}
.signin-btn {
  width: 97%;
  margin-top: 11px;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #ffffff;
}

.confirm-btn {
  padding: 8px 38px;
  background: #00aaf9;
  opacity: 0.8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.cancel-btn {
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-size: 14px;
  color: #000000;
  padding: 7px 40px;
}

.disabled-btn {
  cursor: not-allowed !important;
  background: rgb(215, 212, 212) !important;
  pointer-events: none !important;
}

.not-allowed {
  cursor: not-allowed !important;
  &:hover {
    color: rgb(229, 226, 226) !important;
  }
}
