.couponModal {
  display: flex;
  justify-content: center;
  align-items: center;

  /*.MuiDialog-paperFullWidth{
    margin-top: 120px;
   margin-left: 80px;
  }*/
  .MuiDialogContent-root {
    overflow-y: clip;
  }
  .MuiTypography-root {
    margin-left: -20px;
  }
  .modalTitle > * {
    color: $lighter_black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .coupon-img {
    max-height: 60px;
    align-items: center;
    justify-content: center;
  }

  .modal-close-coupon {
    font-weight: bold !important;
    display: flex;
    justify-content: flex-end;
  }

  .close-coupon {
    color: $grey !important;
    font-weight: bold !important;
    font-size: 18px;
    opacity: 1;

    &:hover {
      text-shadow: 0px 0px 0.5px $grey;
    }
  }

  .message-group {
    margin-top: 18px;
    margin-top: 18px;
    max-width: 300px;
  }

  .message-discount-success {
    font-size: 18px;
    display: flex;
    justify-content: center;
    font-weight: bold !important;
    color: rgb(53, 211, 38);
  }

  .message-discount {
    font-size: 12px;
    display: flex;
    justify-content: center;
    font-weight: bold !important;
    color: $lighter_black;
  }

  .message-discount-warning {
    display: flex;
    justify-content: center;
    flex-wrap: wrap !important;
    font-size: 18px;
    opacity: 0.6;
    font-weight: bold !important;
    color: $orange3;
  }
  .content {
    min-height: 370px !important ;
    min-width: 400px !important;
    margin-right: auto;
    margin-left: auto;
    @include mq(775px, max) {
      width: auto !important;
      min-width: 350px !important;
    }
  }
  .content-price {
    width: 98%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(220, 220, 220, 0.241);
    padding: 15px;
    border-radius: 5px;
    margin-right: auto;
    margin-bottom: -20px;
    margin-left: auto;
    margin-left: 5px;

    min-width: 350px !important;
  }

  .content-price {
    width: 98%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(220, 220, 220, 0.241);
    padding: 15px;
    border-radius: 5px;
    margin-right: auto;
    margin-bottom: -20px;
    margin-left: auto;
    margin-left: 5px;
  }

  .content-price {
    width: 98%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(220, 220, 220, 0.241);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: -20px;
    margin-left: 5px;
  }
  .simple-price-offer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin-left: 40px;
  }

  .Content-ooredoo {
    display: flex;
    justify-content: center;
    flex-direction: row;
    @include mq(775px, max) {
      width: auto !important;
      min-width: 350px !important;
    }
  }
  .offer-Title {
    font-size: 30px;
    font-weight: bold;
    margin: 50px;
  }
  .message-code {
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .price-header {
    margin-top: -10px;
    margin-bottom: 70px;
    font-size: 20px;
  }

  .discount-price {
    font-weight: bold;
    font-size: 30px;
    font-size: 26px;
    color: black;
    justify-content: center;
  }

  .total {
    color: #737373;
    text-decoration: none;
    font-size: 24px;
  }

  .before-discount {
    font-weight: bold;
    text-decoration: none;
    font-size: 30px;
    margin-top: 10px;
  }

  .after-discount {
    font-weight: none;
    text-decoration: line-through;
    opacity: 0.6;
    font-size: 20px;
  }

  .discount-value {
    color: gray;
    font-size: 20px;
  }

  .code-list-item {
    font-size: 16px;
    opacity: 0.8;
  }

  .group-btn {
    display: flex;
    justify-content: center;
  }

  .validate {
    width: 90px;
    color: white !important;
    font-weight: bold !important;
    font-size: 12px;
    text-transform: none !important;
    height: 40px !important;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 2px 0px 2px rgba(133, 132, 132, 0.781);

    &:hover {
      filter: saturate(70%);
    }

    & > .MuiIconButton-root {
      border-radius: 2em;
    }
  }
}

.paiment-ooredoo-box {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(220, 220, 220, 0.241);
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10px;

  @include mq(775px, max) {
    width: auto !important;
  }
}

.paiment-ooreedoo-icon {
  margin-top: 20px;
}
.ooreedoo-periods {
  color: $grey1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  padding: 5px;
  height: 70px !important;
}

.acheter {
  height: 100px !important;
  justify-content: center;
  width: 100%;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px;
  text-transform: none !important;
  height: 40px !important;
  margin-top: 5px;
  padding: auto;
  display: flex;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    filter: opacity(0.6);
  }
}

.content-promo {
  background-color: white;
  bottom: 0;
  border-radius: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100% !important;
  padding: 10px;
  margin-top: 20px;
  .content-items {
    display: flex;
    flex-direction: column;
    color: #2d5f89;
  }

  #success {
    border-color: green;
  }

  #error {
    border-color: red;
  }
}

.coupon-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.content-price-rtl {
  direction: rtl;
  display: flex;
  justify-content: space-between;

  background-color: rgba(220, 220, 220, 0.241);
  padding: 10px;
  border-radius: 5px;
  margin: 40px;
  margin-bottom: -20px;
}

.price-header-rtl {
  direction: rtl;
  margin-bottom: 70px;
  font-size: 20px;
  padding-left: 200px;
}

.message-code-rtl {
  direction: rtl;
}

.code-promo-rtl {
  direction: rtl;
}
.ooreedoo-price-rtl {
  font-weight: bold;
  font-size: 14px;
  direction: rtl;
}

.paiment-ooreedoo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include mq(775px, max) {
    width: auto;
  }
}

.text-paiment-ooreedoo {
  font-size: 12px;
  margin: 20px;
  text-align: center;
}

.Paiment-titel {
  font-weight: 400;
  font-size: 31px;
  margin-top: 40px;
}

.acheter-mini {
  width: 139px !important;
  height: 29.61px !important;
  justify-content: center;
  width: 100%;
  color: white !important;
  font-weight: bold !important;
  font-size: 14px;
  text-transform: none !important;
  height: 40px !important;
  margin-bottom: 10px !important;
  display: flex;
  border-radius: 10px !important;
  box-shadow: 0px 4px 13px 2px rgba(84, 168, 238, 0.33);
  cursor: pointer;
  &:hover {
    filter: opacity(0.6);
  }
}

.remove-icon {
  color: rgb(92, 87, 87);
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;

  &:hover {
    filter: opacity(0.6);
  }
}
.offerPeriodDialog {
  color: $grey1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  padding: 5px;
  height: 70px !important;
}
.offer-periods {
  display: flex;
  border-radius: 5px;
  margin: -60px auto 20px;
  justify-content: center;
  width: 100% !important;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 1150px) {
    margin: -70px auto 20px;
  }

  .period {
    width: 60% !important;
    color: $grey1;
    border: black 2px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    text-align: center;
    padding: 5px;
    height: 70px !important;

    @media screen and (max-width: 1150px) {
      padding: 5px 2px;
    }

    @media screen and (min-width: 1380px) {
      padding: 5px 5px;
    }
  }

  .last-period {
    color: $grey1;
    border: black 2px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 2px;
    border-right: 20px !important;
    cursor: pointer;
    height: 70px;
    text-align: center;
  }

  .selected-period * div {
    cursor: pointer !important;
    height: 70px;
  }

  .period:first-child {
    border-radius: 5px 0px 0px 5px;
    height: 70px;
    text-align: center;
  }

  .period:last-child {
    border-radius: 0px 5px 5px 0px;
    height: 70px;
    text-align: center;
  }

  .period:nth-child(2) {
    border-left: 0px;
    height: 70px;
    text-align: center;
  }

  .period:nth-child(3) {
    border-left: 0px;
    height: 70px;
    text-align: center;
  }

  .period:nth-child(4) {
    border-left: 0px;
    height: 70px;
    text-align: center;
  }

  .selected-period {
    color: white;
    font-weight: bold;
    height: 70px;
    text-align: center;
  }
}

.offer-header-container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-size: cover;
  background-size: cover;
  fill: #4a3c98;
  opacity: 0.99;

  .offer-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    .offer-name {
      color: white !important;
      margin: 20px 0px 0px 0px;
      font-size: 20px;
    }

    .offer-price-in-coupon {
      text-shadow: 0px 2px 4px #00000029;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: '10px';

      & > p {
        margin-bottom: 5px;
      }

      .base-offer-price-in-coupon {
        color: black;
        font-weight: bold;
        font-size: 23px;
        text-decoration: line-through;
        margin-right: 10px;
        padding: 0;

        @media screen and (max-width: 1351px) {
          font-size: 16px;
        }
      }

      .default-base-offer-price-in-coupon {
        text-shadow: 0px 2px 4px #00000029;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .base-offer-price-in-coupon {
          color: black;
          font-weight: bold;
          font-size: 23px;
          text-decoration: line-through;

          @media screen and (max-width: 1351px) {
            font-size: 16px;
          }
        }
      }

      .final-offer-price-in-coupon {
        color: black;
        font-weight: bold;
        font-size: 35px;

        @media screen and (max-width: 1351px) {
          font-size: 25px;
        }

        @include mqs(1700px, 1351px) {
          font-size: 30px;
        }
      }
    }
  }
}
#Remise {
  font-family: system-ui !important;
  text-decoration: none !important;
  font-weight: 1000 !important;
  border-radius: 10%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 15px;
  border: 2px solid white;
  background-color: red;
  color: white;
  background-clip: padding-box;
  padding: 3px;
  margin-left: 5px;

  @media screen and (max-width: 1351px) {
    font-size: 10px;
  }

  #dinnar {
    font-size: 12px;
    padding: top 20 !important;
    vertical-align: super;

    @media screen and (max-width: 1351px) {
      font-size: 8px;
    }
  }
}
.rtl {
  .offers-card {
    .offer-card {
      .offer-periods {
        flex-direction: row-reverse;

        .period:first-child {
          border-radius: 0px 5px 5px 0px;
        }

        .period:last-child {
          border-radius: 5px 0px 0px 5px;
          border-left: solid;
        }

        .selected-period {
          border-radius: none;
        }
      }
    }

    .offer-price-cards {
      .subject-card {
        direction: rtl;
      }
    }
  }
  .total {
    direction: rtl;
  }
}

.offer-price-in-coupon {
  & > p {
    margin-bottom: 5px;
  }
}
