.notifications-list {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > img {
    width: 1.1rem;
    height: 1.1rem;
    filter: grayscale(100%) opacity(60%);
    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
  .notification-icon {
    margin-left: 5px;
  }
  .notification-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-left: 10px;
  }
  .notification-body {
    font-size: 15px;
    font-family: tajawal;
    color: #7c8494;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  .notification-time {
    color: #b7becd;
    font-size: 13px;
    font-family: tajawal;
    text-align: right;
    @media (max-width: 480px) {
      font-size: 11px;
    }
  }
}

.rtl {
  .notifications-list {
    flex-direction: row-reverse;

    & > .menu-item-title {
      direction: rtl;
      padding-right: 16px;
    }
    & > .MuiMenuItem-root {
      direction: rtl;
    }
    & > .notification-wrapper {
      flex-direction: row-reverse;
      margin-right: 10px;
    }
    & > .notification-icon {
      flex-direction: row-reverse;
      margin-right: 5px !important;
    }
    & > .notification-time {
      flex-direction: row-reverse;
    }
  }
}
