.subs-item-2 {
  justify-content: center;
  // border-bottom: 1px solid $white2;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 8px !important;
  & .subs-item-2-img {
    border-radius: 50%;
    border: 1px solid #e8e8e8;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    & > img {
      width: 35px;
    }
  }
  & .subs-item-2-details {
    flex: 1;
    & .label {
      color: $blue;
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      display: flex;
    }
    & .MuiLinearProgress-root {
      height: 6px;
      border-radius: 6px;
      margin: 4px 0;
    }
    & .due-date {
      color: $grey;
      font-size: 11px;
      width: 100%;
      display: flex;
      text-align: start;
    }
  }
}
