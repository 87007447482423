.subject-tabs {
  padding-bottom: 0;
  & > .MuiCardContent-root {
    padding: 6px !important;
    & > .child-full {
      margin-bottom: 0;
      & > .header {
        display: flex;
        flex-wrap: wrap;
        & > .menu-item {
          color: #8bb4c6;
          font-size: 18px;
          margin-right: 12px;
          position: relative;
          user-select: none;
          cursor: pointer;
          transition: all 300ms ease;
          &.active {
            color: #18698d;
            font-weight: 600;
            &::after {
              content: '';
              width: auto;
              height: 3px;
              display: flex;
              background: #18698d;
              border-radius: 20px;
              bottom: -3px;
              left: 0;
            }
          }
          &:last-child {
        //    margin-right: 0;
          }
        }
      }
      & > .chapters-container {
        margin-top: 18px;
        & > .chapter-item {
          width: 100%;
          display: flex;
          border: 1px solid #2ba7df;
          border-radius: 12px;
          padding: 18px 32px;
          margin-bottom: 18px;
          transition: all 300ms ease;
          cursor: pointer;
          @include mqs(1300px, 1200px) {
            padding: 18px;
          }
          @include mqs(1080px, 980px) {
            padding: 18px;
          }
          @include mqs(740px, 600px) {
            padding: 18px;
          }
          @include mq(480px, max) {
            padding: 18px;
          }
          &:hover {
            background: rgba(233, 248, 253, 0.6);
          }
          & > .number {
            color: #ed5454;
            font-size: 28px;
            font-weight: 600;
            margin-right: 18px;
          }
          & > .details {
            display: flex;
            flex-direction: column;
            margin-right: 0;
            width: 100%;
            & > .label {
              color: #18698d;
              font-size: 18px;
              font-weight: 400;
            }
            & > .infos {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }
            & > .progress-container {
              display: flex;
              align-items: center;
              margin-top: 12px;
              & > .progress {
                color: #4a3c98;
                font-size: 20px;
                font-weight: 600;
                margin-left: 16px;
              }
              & > .MuiLinearProgress-root {
                background: #e3e3e4;
                height: 6px;
                width: 100%;
                border-radius: 6px;
                & > .MuiLinearProgress-bar {
                  border-radius: 6px;
                  background-color: $green;
                }
                &.bg-orange > .MuiLinearProgress-bar {
                  background: #fe726e;
                }
                &.bg-green > .MuiLinearProgress-bar {
                  background: #50bf88;
                }
                &.bg-purple > .MuiLinearProgress-bar {
                  background: #4a3c98;
                }
              }
            }
          }
        }
        & > .chapter-section {
          width: 100%;
          display: flex;
          border: 1px solid #2ba7df;
          background-color: rgba(233, 248, 253, 0.6);
          border-radius: 12px;
          padding: 18px 32px;
          margin-bottom: 18px;
          transition: all 300ms ease;
          @include mqs(1300px, 1200px) {
            padding: 18px;
          }
          @include mqs(1080px, 980px) {
            padding: 18px;
          }
          @include mqs(740px, 600px) {
            padding: 18px;
          }
          @include mq(480px, max) {
            padding: 18px;
          }
          & > .details {
            display: flex;
            flex-direction: column;
            margin-right: 0;
            width: 100%;
            & > .label {
              color: #18698d;
              font-size: 18px;
              font-weight: 400;
            }
          }
        }

      }
    }
  }
}


.rtl{
  .subject-tabs {
    & > .MuiCardContent-root {
      & > .child-full {
       flex-direction: row-reverse;

        & > .header {
         // flex-direction: row-reverse;
          & > .menu-item {
            &:first-child {
              margin-right: 0;
            }
          }
        }
        & > .chapters-container{
          & > .chapter-item{
        //    flex-direction: row-reverse;
        & > .number {
          margin-left: 10px;
          margin-right: 2px;
            }
          }
        }
      }
    }
  }
}