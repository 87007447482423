@import './common/SessionModal/sessionModal';
@import './Popups/popupsModal';
@import './RatingSession/ratingSession';
@import './CircularProgressWithLabel/circular_progress';
@import './BenefitsHistory/bebefitHistory.scss';
@import './PdfPreview/pdfPreview.scss';

//Blue Button
.blueButton {
  @include blueButton();
  @media screen and (max-width: 425px) {
    width: 25% !important;
  }
  @media screen and (max-width: 350px) {
    width: 15% !important;
  }
  .buttonText {
    @include buttonText();
  }
}
.modalLine {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $blue;
}
