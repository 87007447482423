.calendar-months {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  & > .month {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-transform: capitalize;
    color: #c2c2c2;

    margin: 8px 0;
    height: 36px;
    border-radius: 18px;
    cursor: pointer;

    &:hover {
      background: #e7e6e6;
    }
  }
  & .selected-month {
    color: #fff;
    background-color: #00aaf9;
    &:hover {
      background: #00aaf9;
    }
  }
}
