@mixin parrain-array-title {
  text-align: center;
  font-family: Roboto !important;
  font-size: 13.016px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.314px;
  letter-spacing: 0.139px;
  width: fit-content;
  padding: 0.1rem 0.5rem;
}
.parrain-array-title {
  @include parrain-array-title;
  color: #a8afb2;
}

.parrain-array-selected-title {
  @include parrain-array-title;
  color: #fff;
  background: #21aded;
  border-radius: 5px;
}
