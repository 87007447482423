@use '../../../assets/scss/utilities/mixins' as *;

.date-picker-wrapper {
  position: relative;
  @include mq(648px, max) {
    width: 100%;
  }
  .arabic-error {
    justify-content: flex-end;
  }
  & .date-picker {
    & .field {
      justify-content: flex-end !important;
      & > img {
        cursor: pointer;
      }
    }
  }
  & .arabic-label {
    display: flex;
    justify-content: end;
  }
}
