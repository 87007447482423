.add-to-cart-product-card {
  border-radius: 23px;
  background: #fff;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  flex-basis: 13%;
  padding: 0.8rem 0.5rem;
  min-height: 22rem;
  width: fit-content;
  min-width: 15rem;

  @media screen and (max-width: 380px) {
    border-radius: 13px;
  }
  .product-card-img {
    border-radius: 11px;
    background: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8.7rem;
    width: 100%;
    @media screen and (max-width: 380px) {
      border-radius: 8px;
    }

    & > img {
      border-radius: 11px;
      width: 86%;
      object-fit: cover;
      height: 90%;
      max-height: 100%;

      @media screen and (max-width: 380px) {
        border-radius: 8px;
      }
    }
  }
  .product-card-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    .poduct-name {
      text-transform: capitalize;
    }
    .card-btn {
      font-family: Gothic A1 !important;
      font-size: 13.132px !important;
      font-weight: 700 !important;
      padding: 0.7rem 0.63rem !important;
    }
  }

  @media screen and (max-width: 1530px) and (min-width: 1379px) {
    min-height: 14rem;
    max-width: 16.5rem;
  }
  @media screen and (max-width: 380px) {
    flex: 1;
    max-width: unset;
    min-width: unset;
  }

  .add-to-cart-product-img {
    background: #f9f9f9;
    border-radius: 15px;
    height: 16.7rem;
    width: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > img {
      width: 100%;
      object-fit: fill;
      height: 100%;
      max-height: 100%;
      border-radius: 9px;
    }
  }
  .add-to-cart-product-name {
    color: #1c1c1c;
    text-align: center;
    font-family: Gothic A1 !important;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .add-to-cart-product-description {
    color: #a2a2a7;

    font-family: Gothic A1 !important;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > img {
    width: 100%;
  }

  & hr {
    width: 100%;
    margin-top: -1px;
  }

  .add-to-cart-product-card-footer {
    width: 87%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 1530px) and (min-width: 1379px) {
      gap: 0.7rem;
    }
    @media screen and (max-width: 380px) {
      gap: 1.3rem;
    }
    @media screen and (max-width: 350px) {
      gap: 0.7rem;
    }

    @media screen and (max-width: 325px) {
      gap: 0.4rem;
      width: 93%;
    }
    @media screen and (max-width: 318px) {
      gap: 1rem;
    }

    @media screen and (max-width: 302px) {
      gap: 0.5rem;
    }
    @media screen and (max-width: 290px) {
      gap: 0.3rem;
    }
    .add-to-cart-card-coins {
      color: #2b9af4;
      text-align: center;
      font-family: Gothic A1 !important;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
      @media screen and (max-width: 318px) {
        font-size: 12px;
      }
    }
    .ar-add-to-cart-card-coins {
      direction: rtl;
    }
    .add-to-cart-card-btn {
      border-radius: 83.306px;
      background: #2b9af4;
      color: #fff;
      text-align: center;
      font-family: Gothic A1 !important;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
      cursor: pointer;
      width: fit-content;
      padding: 0.7rem 1.25rem;
      font-size: 14.364px;
      transition: all ease 0.3s;
      @media screen and (max-width: 420px) {
        padding: 0.7rem 1rem;
      }
      @media screen and (max-width: 318px) {
        font-size: 12px;
      }
      &:hover {
        background: darken(#2b9af4, 10);
      }
    }
  }
}
.fr-product-card {
  min-width: 17rem !important;

  & .add-to-cart-product-card-footer {
    width: 91% !important;
  }
}
