.no-data-illustration {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 15%;
    height: auto;
  }
  & > p {
    color: #a2a2a7;
    font-size: 14px;
    font-family: tajawal;
    opacity: 0.8;
  }
}
