.skeleton-card-wrapper {
  background: #fff !important;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  min-height: 18rem;

  .skeleton-card-icon {
    width: 101px;
    height: 101px;
    margin-bottom: 1rem;
  }
  .skeleton-card-description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90% !important;
    span:first-child {
      width: 90%;
    }
    span:nth-child(2) {
      width: 68%;
    }
  }
  .skeleton-card-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90% !important;
    margin-top: 2rem;
    gap: 1rem;

    span:first-child {
      width: 100%;
      height: 3.5rem;
      border-radius: 22px;
    }
    span:nth-child(2) {
      width: 100%;
      height: 3.5rem;
      border-radius: 22px;
    }
  }
  .skeleton-card-details {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.3rem;
    span {
      width: 100%;
    }
  }
  .skeleton-card-button {
    width: 95%;
    height: 3.5rem;
    border-radius: 25px;
    margin-top: 1rem;
  }
}
