@use '../../../../assets/scss/utilities/mixins' as *;
@import '../../../../assets/scss/utilities/variables';

.ooredoo-form {
  display: flex;
  justify-content: center;
  align-items: center;

  & form {
    & .form-grid {
      gap: 17px;

      @media only screen and (min-width: 1210px) {
        gap: 2.8rem;
      }
    }

    & .choose-offer-section {
      margin-top: 2.6rem;

      @media only screen and (min-width: 1210px) {
        margin-top: 2.7rem;
      }
    }
    & .choose-offer-section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      & > span {
        font-family: 'Poppins' !important;
        font-weight: 400;
        color: #55819a;
        white-space: nowrap;
        font-size: 1.06rem;
        line-height: 13px;

        @media only screen and (min-width: 1210px) {
          font-size: 18px;
          line-height: 27px;
        }
      }

      & hr {
        width: 100%;

        @media only screen and (min-width: 1210px) {
          border: 1px solid #55819a;
        }
      }
    }
    & .choose-offer-section-cards {
      display: flex;
      justify-content: center;
      gap: 2rem;
      padding-top: 1.5rem;

      @media only screen and (min-width: 1210px) {
        justify-content: space-between;
      }
      @media only screen and (max-width: 690px) {
        flex-wrap: wrap;
      }
    }
  }
}
.ooredoo-modal-wrapper {
  & .close-modal-btn {
    color: $grey !important;
    font-weight: bold !important;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-self: flex-end;
    opacity: 1;

    margin-right: -13px;
    &:hover {
      text-shadow: 0px 0px 0.5px $grey;
    }
  }
  & .MuiDialog-paper {
    min-width: 55.9% !important;
    box-shadow: unset !important;
    border-radius: 17.434px !important;
    padding: 2.6rem 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem 2rem 1rem !important;
    max-height: 95vh !important;
    margin: 13px;
    overflow-x: hidden;
    & .MuiDialogTitle-root {
      padding-top: 0 !important;
      margin-top: -0.8rem !important;
      & img {
        width: 14.3rem;
      }
    }

    @media only screen and (max-width: 380px) {
      margin: 15px !important;
    }
    @media only screen and (max-width: 347px) {
      margin: 7px !important;
    }
    @media only screen and (max-width: 332px) {
      margin: 1px !important;
    }
    @media only screen and (min-width: 1210px) {
      padding: 0.7rem 0.7rem 1.5rem 0.7rem;
      & .MuiDialogTitle-root {
        padding: 0;
        padding-bottom: 22px;

        & img {
          width: 20rem;
        }
      }
    }

    & .MuiDialogContent-root {
      padding: 2rem 17px !important;
      padding-top: 0 !important;
      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      @media only screen and (min-width: 1340px) {
        padding: 0rem 3.2rem !important;
      }
    }
  }
}
