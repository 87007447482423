.purchase-history-item-wrapper {
  border-radius: 20px;
  background: #f9f9f9;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 0.6rem 0.5rem 1.6rem;
  align-items: center;

  @media screen and (max-width: 670px) {
    width: 32rem;
  }
  .purchase-history-item {
    display: flex;
    gap: 1.4rem;
    flex-basis: 20%;
    .purchase-history-item-img {
      width: 3.9rem;
      height: 3.7rem;
      border-radius: 10px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        object-fit: fill;
        width: 80%;
        height: 94%;
        border-radius: 5px;
      }
    }
    & .purchase-history-item-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > h3 {
        color: #1c1c1c;
        text-align: center;
        font-family: Gothic A1 !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
      }
      & > p {
        color: #1c1c1c;
        text-align: center;
        font-family: Gothic A1 !important;
        font-size: 14px;
        font-weight: 300;
        margin-block: 0;
        white-space: nowrap;
      }
    }
  }

  .purchase-history-item-coins {
    color: #1c1c1c;
    text-align: center;
    font-family: Gothic A1 !important;
    font-size: 16px;
    font-weight: 500;
    flex-basis: 9%;
  }
  .purchase-history-item-description {
    color: #1c1c1c;
    font-family: Gothic A1 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    max-width: 17rem;
    position: relative;
    flex-basis: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .purchase-history-item-action {
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 1rem;
    gap: 1rem;
    height: 3rem;
    width: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 11%;
    min-width: 9rem;

    .purchase-history-item-action-img {
      display: flex;
      justify-content: center;
      align-items: center;
      & > .MuiSvgIcon-root {
        font-size: 28px !important;
        color: #fff !important;
      }

      & > img {
      }
    }

    & > p {
      color: #fff;
      text-align: center;
      font-family: Gothic A1 !important;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
