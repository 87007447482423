.parrain-confirm-order {
  padding: 2rem;

  @media screen and (max-width: 659px) {
    padding: 1rem;
  }
  @media screen and (max-width: 413px) {
    padding: 0;
  }
  .error {
    color: #ed3863;
    margin-top: 0 !important;
  }

  .parrain-confirm-order-form-card {
    margin-top: 1rem;
    border-radius: 8px !important;
    background: #fff;

    .ta-form {
      padding: 1.1rem 5rem 1.5rem 1.4rem;
      height: 100%;

      @media screen and (max-width: 1210px) {
        padding: 1.1rem 1rem 2.5rem 1.4rem;
      }
      @media screen and (max-width: 330px) {
        padding: 1.1rem 1rem 4.5rem 1.4rem;
      }

      .card-title {
        color: #1c1c1c;
        font-family: Gothic A1 !important;
        font-size: 19px;
        font-weight: 700;
        line-height: normal;
        text-align: justify;
        margin-bottom: 1rem;
      }
      .note {
        margin-top: 1.2rem !important;
      }

      .parrain-confirm-order-form,
      .parrain-confirm-order-products-form {
        border-radius: 15.325px;
        background: #fff;
        flex-basis: 80%;
        height: 100%;
        row-gap: 0 !important;
        & .MuiFormControl-root {
          gap: 0.5rem;
        }
        & .MuiBox-root {
          row-gap: 0 !important;
        }
      }

      .parrain-confirm-order-products-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 0.878px solid #c8c8e7;
        padding: 2rem 1rem;

        .parrain-confirm-order-products-list {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;
        }
        .parrain-confirm-order-products-list-title {
          display: flex;
          align-items: center;
          gap: 1.3rem;
          justify-content: flex-start;

          & > hr {
            flex: 1;
            border-color: #ededed;
          }

          & > p {
            color: #1c1c1c;
            font-family: Gothic A1 !important;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            white-space: nowrap;
          }
        }
        .parrain-confirm-order-products {
          display: flex;
          flex-direction: column;
          height: 29rem;

          overflow-y: auto;
          overflow-x: hidden;
          margin: 1rem 0 2rem;
        }
      }
      & > form {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;

        @media screen and (max-width: 1080px) {
          flex-direction: column;
        }
        .fix-display {
          display: contents;
          font-size: 13px;
          font-weight: 500;
        }

        .parrain-confirm-order-form-content {
          display: flex;
          flex-direction: column;
          flex-basis: 54%;
          height: 100%;

          .parrain-confirm-order-first-form {
            margin-bottom: 1rem;
          }

          & .MuiFormControl-root {
            & .label-content {
              color: #0d0e0f;
              opacity: 1;
              letter-spacing: 0;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              overflow: hidden;
            }

            & > label {
              color: #000000 !important;
            }
            & .MuiFormControl-root {
              & > .MuiInputBase-root {
                border: 1px solid #e0e0ff !important;
                fieldset {
                  border: unset !important;
                }
              }
              fieldset {
                border: unset !important;
              }
            }
          }
        }
        .parrain-confirm-order-form-products {
          flex-basis: 31%;
          height: 94%;
        }
      }
    }
  }
  .parrain-confirm-order-product {
    max-height: 8.4rem;
    width: 97%;
    align-self: center;
    min-width: 19rem;
    min-height: 7rem;
    margin: 0.3rem 1rem 1rem;
    overflow: visible !important;
    padding: 11px 8px !important;
    @media screen and (max-width: 490px) {
      max-height: unset;
      min-width: unset;
      width: 92%;
      padding: 0.4rem 0.4rem 0.9rem;
      min-height: unset !important;
    }
    & > .MuiCardContent-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      height: 100%;

      @media screen and (max-width: 490px) {
        gap: 0.4rem;
        flex-direction: column-reverse;
      }

      & > .MuiBox-root {
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 100%;
        @media screen and (max-width: 490px) {
          flex-direction: column;
        }
      }
    }
    .parrain-confirm-order-product-img {
      border-radius: 8px;
      background: #f9f9f9;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.4rem;
      max-width: 6rem;
      max-height: 10rem;
      height: 100%;
      & > img {
        width: 100%;
        border-radius: 6px;
        object-fit: contain;
        height: 100%;
      }
    }
    .parrain-confirm-order-product-details {
      @media screen and (max-width: 490px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
      }
    }
    .parrain-confirm-order-product-name {
      color: #1c1c1c;

      text-align: center;
      font-family: Gothic A1 !important;
      font-size: 15.795px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
    .parrain-confirm-order-product-coins {
      color: #1c1c1c;

      text-align: start;
      font-family: Gothic A1 !important;
      font-size: 12.285px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 0.5rem;
    }
    .parrain-confirm-order-product-close-btn {
      @media screen and (max-width: 490px) {
        align-self: flex-end;
      }
      & > img {
        cursor: pointer;
        transition: all ease 0.3s;
        align-self: flex-start;
        &:hover {
          scale: 1.1;
        }
      }
    }
    .disabled-btn {
      cursor: not-allowed !important;
      pointer-events: none !important;
    }
  }
  .parrain-confirm-order-form-btn {
    border-radius: 17.55px;
    background: #2998f4;
    color: #fff;
    width: 100%;
    text-align: center;
    font-family: Gothic A1 !important;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1rem;
    cursor: pointer;
    transition: all ease 0.3s;
    @media screen and (max-width: 430px) {
      font-size: 14px;
    }
    &:hover {
      background: darken(#2998f4, 10);
    }
  }
  .total-coins {
    color: #1c1c1c;
    text-align: center;
    font-family: Gothic A1 !important;
    font-size: 15.795px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 1rem 0 2rem 0;
  }
}

.parrain-confirm-order-full-sidebar {
  @extend .parrain-confirm-order;
  @media screen and (max-width: 821px) {
    padding: 2rem 0;
  }
  .ta-form {
    @media screen and (max-width: 1310px) {
      & > form {
        flex-direction: column !important;
      }
    }
  }

  @media screen and (max-width: 810px) {
    .delivery-information {
      & .MuiBox-root {
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }
}
