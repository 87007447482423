.pdfPreview {
  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }

  & > .MuiDialog-container {
    & > .MuiPaper-root {
      border-radius: 20px;
      min-width: 600px;
      @include mq(775px, max) {
        min-width: 80%;
      }
      & > .MuiDialogTitle-root {
        & > .MuiTypography-root {
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > .label {
            font-size: 20px;
            color: #2ba7df;
            @include mq(775px, max) {
              font-size: 16px;
            }
          }
          & > .close-btn {
            font-size: 12px;
            color: #2ba7df;
            font-weight: 600;
            cursor: pointer;
            transition: all 300ms ease;
            &:hover {
              color: #3870a3;
            }
          }
        }
      }
    }
  }
}