@import '../../assets/scss/utilities/variables';

.preferences-list {
  margin: 1rem;

  & > p {
    color: #a2a2a7;
    font-size: 14px;
    font-family: tajawal;
    opacity: 0.8;
  }

  .collapse-container {
    background-color: #f8f8fd;
    border-radius: 8px;
    margin-bottom: 8px;
    padding-left: 8px;
  }

  .collapse-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .family-title {
      color: $pure_black;
      font-size: 16px;
      font-family: tajawal;
    }
  }

  .preference-container {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .preference-description {
      color: $pure_black;
      font-size: 14px;
      font-family: tajawal;
    }
  }

  &.rtl {
    flex-direction: row-reverse;
    padding-right: 10px;
    padding-left: 0;
    & > p {
      text-align: right;
    }

    .collapse-container {
      padding-right: 8px;
      padding-left: 0;
    }

    .collapse-title {
      flex-direction: row-reverse;
    }

    .preference-container {
      flex-direction: row-reverse;
      padding-right: 10px;
      padding-left: 0;

      .preference-description {
      }
    }
  }
}
