.social-media-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  transition: all ease 0.3s;

  @media screen and (max-width: 412px) {
    font-size: 13px;
  }

  &:hover {
    background: #e3f4ff;
  }
  .social-media-item-img {
    width: 2rem;
    align-self: center;
    display: flex;
  }
  & > p {
    color: #000;

    text-align: center;
    font-family: Poppins !important;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-block: 0 !important;
  }
}
