.table-responsive {
  width: 100%;
  height: 350px;
  position: relative;
  & > .table-container {
    max-height: 320px;
    overflow: auto;
    position: absolute;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(245, 245, 245, 0.6) !important;
  }
  & > .MuiTablePagination-root {
    margin-top: 320px;
    position: absolute;
    & > .MuiTablePagination-actions {
      direction: initial;
    }
  }
  & th,
  td {
    min-width: max-content;
    white-space: nowrap;
    border-bottom: none;
    padding: 2px 4px;
    text-align: center;
  }
  & .MuiTableCell-stickyHeader {
    background-color: #ebebf3 !important;
    padding: 2px 6px;
    color: #ababb0;
    text-align: center;
  }
  & .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(245, 245, 245, 0.6);
  }
  .tab-nav-link {
    text-decoration: none;
    color: black;
  }
  .buttons-container {
    align-items: center;
    text-align: center;
  }
  .status-container {
    display: flex;
    align-items: center;
    text-align: left;
    margin-left: 2rem;
    opacity: 1;
    font-size: 15px;
    &.rtl {
      flex-direction: row-reverse;
    }
    & > span {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .report-status {
    justify-content: flex-start;
    text-align: left;
    &.pending {
      color: #fe7b32;
    }
    &.resolved {
      color: #00c620;
    }
    &.inprogress {
      color: #2fa5de;
    }
    &.rejected {
      color: #ec171d;
    }
  }
}
.parent-requests-action {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 2rem !important;
}
.rtl {
  .table-responsive {
    direction: rtl;
    & .MuiTableCell-root {
      direction: ltr;
    }
  }
  .MuiTablePagination-actions {
    direction: ltr;
  }
}
