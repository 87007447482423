.free-videos-item {
  display: flex;
  flex-direction: row;
  transition: all 300ms ease;
  cursor: pointer;

  @include mqs(720px, 600px) {
    flex-direction: column;
  }
  @include mqs(600px, 400px) {
    flex-direction: row;
  }
  @include mq(400px, max) {
    flex-direction: column;
  }
  &:hover {
    background: rgba(233, 248, 253, 0.6);
    border-radius: 4px;
  }
  & > .thumbnail {
    position: relative;
    width: 148px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    min-width: 148px;
    & > img {
      position: absolute;
      width: 148px;
      height: 84px;
      top: 0;
      left: 0;
      z-index: 4;

      //   pointer-events: none;
    }
    & > .title {
      position: relative;
      z-index: 6;
      font-size: 14px;
      font-weight: 600;
      color: white;
      text-shadow: 0px 2px 8px #002954;

      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      -ms-user-select: none;
      user-select: none;

      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .textes {
    margin-top: 6px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #a9a9ae;
    & > .subdiv {
      display: flex;
      flex-direction: row;
      color: black;
      font-weight: 500;
      & > .subdiv,
      .strong-division,
      .strong-subject {
        color: black;
        font-weight: 500;
      }
      & > .strong-division {
        direction: ltr;
      }
    }
    @include mq(760px, max) {
      font-size: 14px;
    }
    @include mqs(720px, 600px) {
      margin-left: 0;
    }
    @include mqs(600px, 400px) {
      margin-left: 14px;
    }
    @include mq(400px, max) {
      margin-left: 0;
    }
  }
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}
.rtl {
  .free-videos-item {
    flex-direction: row-reverse;
    .textes {
      direction: rtl;
      margin-right: 10px;
      & > .strong {
        direction: rtl;
      }
    }
  }
}
