.convert-coins-page {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 1.5rem 1rem 1rem 2.3rem;
  gap: 3rem;

  @media screen and (max-width: 946px) {
    margin: 0;
  }
  @media screen and (max-width: 700px) {
    padding: 0;
    padding-top: 1.5rem;
    gap: 1.5rem;
  }
  @media screen and (max-width: 633px) {
    padding: 1rem 0rem 1rem 0rem;
  }

  .convet-coins-card {
    border-radius: 8px;
    background: #fff;
    box-shadow: unset !important;
    margin: 0px 17.8rem 0 0rem;
    width: 78%;

    @media screen and (max-width: 1460px) {
      margin: 0px 4.8rem 0 0rem;
      width: 92%;
    }
    @media screen and (max-width: 1087px) {
      margin: 0px 1.8rem 0 0rem;
      width: 98%;
    }
    @media screen and (max-width: 946px) {
      margin: 0px 1.5rem;
    }

    @media screen and (max-width: 700px) {
      margin: 0;
    }

    & > .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 6rem !important;

      @media screen and (max-width: 700px) {
        padding-bottom: 3rem !important;
      }
      @media screen and (max-width: 555px) {
        padding: 0.2rem;
      }
    }

    .convet-coins-card-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5.2rem 4rem;
      gap: 3rem;

      @media screen and (max-width: 960px) {
        flex-direction: column;
      }
      @media screen and (max-width: 700px) {
        padding: 1rem;
      }

      @media screen and (max-width: 555px) {
        padding: 0.5rem;
      }

      @media screen and (max-width: 330px) {
        padding-top: 1.5rem;
        gap: 1rem;
      }
      .convet-coins-card-content-description {
        color: #000;

        font-family: Roboto !important;
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.346px;
        margin: 5.2rem 0 2.8rem;

        @media screen and (max-width: 1564px) {
          margin: 2.2rem 0 1.8rem;
        }
        @media screen and (max-width: 700px) {
          font-size: 20px;
        }
        @media screen and (max-width: 555px) {
          font-size: 17px;
          margin: 1.5rem 0;
        }
      }

      .convet-coins-card-content-field-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        @media screen and (max-width: 555px) {
          gap: 0;
        }
        .convet-coins-card-content-field-content {
          display: flex;
          width: 100%;
          gap: 2rem;
          @media screen and (max-width: 555px) {
            gap: 0.5rem;
          }
          .convet-coins-card-content-field-description {
            color: #29b8f8;
            white-space: nowrap;

            text-align: center;
            font-family: Roboto !important;
            font-size: 21px;
            font-weight: 500;
            line-height: 37.18px;
            letter-spacing: 0.15px;

            @media screen and (max-width: 700px) {
              font-size: 18px;
            }
            @media screen and (max-width: 555px) {
              font-size: 14px;
            }
          }
        }
        .convet-coins-card-content-field-label {
          color: #000;
          text-align: center;
          font-family: Roboto !important;
          font-size: 20px;
          font-weight: 400;
          line-height: 37.18px;
          letter-spacing: 0.15px;
          @media screen and (max-width: 700px) {
            font-size: 18px;
          }

          @media screen and (max-width: 555px) {
            font-size: 15px;
          }
        }

        .convet-coins-card-content-field {
          border-radius: 10px;
          border: 0.796px solid #e0e0ff;
          background: #fff;
          transition: all ease 0.3s;

          min-height: 4rem;
          display: flex;
          justify-content: center;
          flex-basis: 65%;
          width: 100%;
          margin-top: 0.5rem;
          margin-bottom: 0.4rem;

          @media screen and (max-width: 555px) {
            min-height: unset;
          }
          & .MuiOutlinedInput-notchedOutline {
            border-color: unset !important;
            border: unset !important;
          }
          &::placeholder {
            color: #d1d1d1;
            font-family: Gothic A1 !important;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
      .convet-coins-card-content-field-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        @media screen and (max-width: 1564px) {
          align-items: flex-start;
          gap: 0.4rem;
        }

        @media screen and (max-width: 555px) {
          gap: 0.5rem;
          margin-top: 0.5rem;
          & > img {
            width: 5%;
          }
        }
        @media screen and (max-width: 360px) {
          & > img {
            width: 6%;
          }
        }
        & > span {
          color: #494949;

          font-family: Roboto !important;
          font-size: 17px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0.685px;
          @media screen and (max-width: 700px) {
            font-size: 14px;
          }
          @media screen and (max-width: 555px) {
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
    }

    .convet-coins-card-img {
      align-self: center;

      @media screen and (max-width: 700px) {
        width: 12rem;
      }

      @media screen and (max-width: 330px) {
        min-width: 11rem;
      }
      & > img {
        width: 100%;
      }
    }

    .convet-coins-card-form {
      flex-basis: 58.7%;
      .convet-coins-card-user-card {
        border-radius: 8px;
        border: 1px solid #2ba7df;
        display: flex;
        flex-direction: column;
        padding: 1.2rem;
        align-items: center;
        justify-content: center;
        .convet-coins-card-user-card-title {
          color: #000;

          text-align: center;
          font-family: Roboto !important;
          font-size: 20px;
          font-weight: 500;
          line-height: 21.178px;
          letter-spacing: 0.346px;

          @media screen and (max-width: 700px) {
            font-size: 17px;
          }
        }

        .convet-coins-card-user-card-wrapper {
          border-radius: 15.931px;
          background: #f7fcff;
          display: flex;
          padding: 1.2rem;
          gap: 1rem;
          min-width: 21.5rem;
          align-items: center;

          @media screen and (max-width: 555px) {
            padding: 0.6rem;
            min-width: unset;
          }

          .convet-coins-card-user-card-user-name-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            @media screen and (max-width: 555px) {
              gap: 0.2rem;
            }
            .convet-coins-card-user-name {
              color: #2ba7df;

              text-align: center;
              font-family: Roboto !important;
              font-size: 21.216px;
              font-weight: 500;
              line-height: 30.308px;
              letter-spacing: 0.606px;
              text-transform: capitalize;

              @media screen and (max-width: 700px) {
                font-size: 19px;
              }
              @media screen and (max-width: 555px) {
                font-size: 16px;
                line-height: 16px;
                text-align: justify;
              }
            }
            .convet-coins-card-user-division {
              color: #a2a2a7;

              text-align: center;
              font-family: Roboto !important;
              font-size: 18.185px;
              font-weight: 400;
              line-height: 18.185px;
              letter-spacing: 0.606px;
              text-transform: capitalize;

              @media screen and (max-width: 700px) {
                font-size: 16px;
              }
              @media screen and (max-width: 555px) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .convet-coins-card-action {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      width: 100%;
      padding: 0 1rem;

      @media screen and (max-width: 700px) {
        margin-top: 1rem;
      }
      @media screen and (max-width: 555px) {
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 1rem;
      }

      .convet-coins-card-btn {
        min-height: 4rem;
        border-radius: 8px;
        border: 1px solid #29b8f8;
        flex-basis: 48%;
        max-width: 25rem;
        box-shadow: 0px 4px 0px 0px #9be0ff;
        font-family: Roboto !important;
        font-size: 22px;
        font-weight: 500;
        line-height: 21.178px;
        letter-spacing: 0.346px;
        text-transform: capitalize;
        min-width: 9rem;

        @media screen and (max-width: 700px) {
          font-size: 18px;
          flex-basis: 35%;
          min-height: 3rem;
        }
        @media screen and (max-width: 555px) {
          flex-basis: 42%;
        }

        @media screen and (max-width: 390px) {
          flex: 1;
        }
      }
      .convert-btn {
        background: #29b8f8;
        color: #fff;
      }
      .disabled-btn {
        background: rgb(219 225 227);
        border: 1px solid rgb(219 225 227);
        box-shadow: 0px 4px 0px 0px rgb(233 241 243);
        pointer-events: none !important;
      }

      .cancel-btn {
        background: #ffff;
        color: #29b8f8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.convert-coins-page-full-sidebar {
  @extend .convert-coins-page;

  .convet-coins-card-content {
    @media screen and (max-width: 1250px) and (min-width: 600px) {
      flex-direction: column;
    }
    @media screen and (max-width: 900px) and (min-width: 600px) {
      padding: 1rem;
    }
  }

  @media screen and (max-width: 840px) and (min-width: 600px) {
    padding: 0 !important;
    padding-top: 1.5rem !important;
    gap: 1.5rem !important;

    .convet-coins-card {
      margin: 0 !important;

      & > .MuiCardContent-root {
        padding-bottom: 3rem !important;
      }
    }

    .convet-coins-card-content-description {
      font-size: 20px !important;
    }
    .convet-coins-card-content-field-description,
    .convet-coins-card-content-field-label {
      font-size: 18px !important;
    }

    .convet-coins-card-content-field-info {
      & > span {
        font-size: 14px !important;
      }
    }
    .convet-coins-card-img {
      width: 12rem !important;
    }

    .convet-coins-card-user-card-title {
      font-size: 17px !important;
    }
    .convet-coins-card-user-name {
      font-size: 19px !important;
    }

    .convet-coins-card-user-division {
      font-size: 16px !important;
    }
    .convet-coins-card-action {
      margin-top: 1rem !important;
    }
    .convet-coins-card-btn {
      font-size: 18px !important;
      flex-basis: 35% !important;
      min-height: 3rem !important;
    }
  }
}
.parrain-page-arabic {
  direction: rtl;
}
