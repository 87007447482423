.error {
  margin: 0;
  color: #ed3863;

  display: flex;
  padding-top: 4px;

  font-size: 0.7rem;
  align-items: center;
  gap: 2px;
  margin-top: 0px;

  @media only screen and (min-width: 1210px) {
    margin-top: 2px;
    font-size: 13px;
  }
  &::after {
    content: '\a';
    white-space: pre;
  }
  svg {
    width: 17px;
    margin-bottom: 2px;
    height: 14px;

    @media only screen and (min-width: 1210px) {
      width: 17px;
    }
  }
}
.register-error {
  margin: 5px 0 0 !important;
}
