.parrain-page-products-list {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(179, 208, 232, 0.6);
  padding: 1.1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  height: fit-content;
  & > div {
    display: flex;
    justify-content: space-around;
    column-gap: 2rem;
    row-gap: 2rem;

    align-items: center;

    @media screen and (max-width: 1500px) {
      gap: 3rem;
    }

    @media screen and (max-width: 1404px) {
      gap: 2rem;
    }
    @media screen and (max-width: 980px) {
      gap: 1rem;
    }
    @media screen and (max-width: 537px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 335px) {
      justify-content: flex-end;
    }
  }
  .parrain-page-products-list-card-header {
    border-radius: 8px;
    background: #e3f4ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0.6rem;
    @media screen and (max-width: 647px) {
      gap: 1rem;
      justify-content: space-between !important;
      padding: 0.5rem !important;
    }
    @media screen and (max-width: 412px) {
      justify-content: center !important;
      padding: 1rem 0;
    }

    & .parrain-page-products-list-card-title {
      color: #2ba7df;
      font-family: Roboto !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 7.701px;
      letter-spacing: 0.126px;
      @media screen and (max-width: 475px) {
        font-size: 1rem;

        line-height: 1.4rem;
      }

      @media screen and (max-width: 412px) {
        line-height: 16.701px;
        text-align: center;
      }
    }
    & .hide-see-more-btn {
      padding: 1rem 0;
    }
  }
  .parrain-page-products-list-card-header-full-sidebar {
    @extend .parrain-page-products-list-card-header;
    @media screen and (max-width: 740px) and (min-width: 600px) {
      gap: 1rem;
      justify-content: center !important;
      padding: 1rem 0.5rem;
    }
    & .parrain-page-products-list-card-title {
      line-height: 16.701px;
      text-align: center;
      @media screen and (max-width: 1239px) {
        font-size: 1rem;

        line-height: 17.701px;
      }
      @media screen and (max-width: 740px) and (min-width: 600px) {
        line-height: 16.701px;
        text-align: center;
      }
    }
  }
  .blue-btn {
    border-radius: 24px;
    background: #2ba7df;
    color: #fff;
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    padding: 0.6rem;
    cursor: pointer;
    text-align: center;

    &:hover {
      background: darken(#2ba7df, 10);
    }
  }
}
