.ooredoo-page {
  background: #eff9ff;
  min-height: 100vh;
  width: 100%;

  & .ooredoo-page-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.8rem 0.24rem;
    padding: 0.6rem 0.95rem;
    border-radius: 53px;
    flex-wrap: wrap;

    @media screen and (max-width: 1670px) {
      margin: 2.57rem 2.6rem 2.57rem 3rem !important;
    }

    @media only screen and (min-width: 1210px) {
      margin: 2.57rem 4.6rem 2.57rem 5.4rem !important;
      padding: 1.3rem 3.5rem 1.3rem 4.2rem;
    }

    @media screen and (max-width: 473px) {
      justify-content: center;
      gap: 0.5rem;
      margin: 2.57rem 1rem 2.57rem 1rem !important;
    }

    @media screen and (max-width: 400px) {
      justify-content: center;
      gap: 1rem;
    }

    & .auth-btns {
      display: flex;
      gap: 0.8rem;

      @media only screen and (min-width: 1210px) {
        gap: 1.2rem;
      }
      a {
        border-radius: 36.5543px;
        padding: 0.5rem 1.55rem;
        color: #fff;
        border: 1px solid #2ba7df;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease;

        & span {
          font-family: 'Tajawal' !important;
        }
        @media only screen and (min-width: 1210px) {
          font-size: 1.03rem;
          padding: 1.07rem 3rem;
          font-size: 1.2rem;
        }

        &:hover {
          scale: 1.02;
        }
      }
      & .login-btn {
        background: #2ba7df;
        color: #fff;
        @media only screen and (min-width: 1210px) {
          font-size: 1.12rem;
          font-weight: 600;
          padding: 1.07rem 2.6rem;
        }
      }
      & .register-btn {
        color: #2ba7df;
        background: #fff;

        @media only screen and (min-width: 1210px) {
          font-size: 1.1rem;
          font-weight: 500;
          padding: 1rem 2.8rem;
        }
      }
    }
  }

  & .ooredoo-page-content {
    margin: 2.1rem 0.7rem;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    @media only screen and (max-width: 1670px) {
      margin: 2.1rem 3rem 2.1rem 3rem !important;
    }
    @media only screen and (max-width: 1436px) {
      margin: 2.1rem 2rem 2.1rem 2rem !important;
    }
    @media only screen and (min-width: 1671px) {
      margin: 2.1rem 4.7rem 2.1rem 4rem !important;
    }

    @media only screen and (max-width: 1210px) {
      margin: 2.1rem;
    }
    @media only screen and (max-width: 380px) {
      margin: 2.1rem 0.7rem !important;
    }
    & .ooredoo-page-title {
      & > h1 {
        font-family: 'Tajawal' !important;
        font-weight: 700;
        font-size: 1.49rem;
        line-height: 1.9rem;
        text-align: center;
        color: #000000;
        margin-block: 0.7rem;

        @media only screen and (min-width: 1210px) {
          font-size: 2rem;
          line-height: 3.5rem;
          margin-right: 13px;
          margin-bottom: 0;
          margin-top: 1.23rem;
          word-spacing: -4px;
        }
      }

      & .ooredoo-offer-description {
        & p {
          font-family: 'Tajawal' !important;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 2rem;
          text-align: center;
          color: #004f8b;
          margin-block: 0;

          @media only screen and (min-width: 1210px) {
            font-size: 1.15rem;
            line-height: 2rem;
            word-spacing: -1.4px;
          }
        }
        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          @media only screen and (min-width: 1210px) {
            text-align: center !important;
          }

          @media only screen and (max-width: 1210px) {
            align-items: center;
            gap: 6px;
          }

          & .ooredoo-logo {
            & > img {
              width: 4.2rem;

              @media only screen and (max-width: 1210px) {
                width: 5.5rem;
              }
            }
          }
        }
      }
    }
    & .ooredoo-offers-tablet {
      display: none !important;
      @media only screen and (max-width: 1300px) {
        display: flex !important;
        flex-direction: column !important;
      }
    }
    .ooredoo-offers-skeleton {
      flex-direction: row !important;
    }
    & .ooredoo-offers {
      display: flex;
      @media only screen and (max-width: 1300px) {
        display: none !important;
      }
    }
    & .ooredoo-offers,
    .ooredoo-offers-tablet {
      justify-content: center;
      align-items: center;
      gap: 1.2783rem;
      flex-direction: row-reverse;

      .rec-carousel-wrapper {
        .rec-carousel {
          .rec-item-wrapper {
            padding: 0 30px 30px !important;
            justify-content: flex-start !important;
            @media only screen and (max-width: 380px) {
              padding: 0 !important;
            }
          }

          .rec-slider-container {
            @media only screen and (max-width: 380px) {
              margin: 0 !important;
            }
          }
        }
        .rec-pagination {
          .rec-dot {
            background-color: #d9d9d9 !important;
            box-shadow: none !important;

            height: 9px !important;
            width: 9px !important;
          }
          .rec-dot_active {
            background-color: #2ba7df !important;
            box-shadow: none !important;
          }
        }
      }

      @media only screen and (max-width: 1426px) {
        flex-wrap: nowrap;
        margin-top: 2rem;
      }
      @media only screen and (max-width: 1310px) and (min-width: 1210px) {
        justify-content: center !important;
      }
      @media only screen and (min-width: 1210px) {
        justify-content: space-between;
      }
    }
    & .ooredoo-subsription {
      @media only screen and (min-width: 1210px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 12rem;
        margin-top: 3.6rem;
        padding: 0 !important;
        margin-bottom: 6.2rem;
      }

      & .title {
        font-family: 'Tajawal' !important;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 4rem;
        text-align: center;
        color: #2ba7df;
        margin-block: 0;

        @media only screen and (min-width: 1210px) {
          font-size: 2rem;
          text-align: end;
          width: 100%;
        }
      }
      & .ooredoo-how-to-subscribe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        @media only screen and (min-width: 1210px) {
          & > .title {
            margin-right: 5rem;
          }
        }
        .youtube-video-container {
          height: 14rem;
          width: 100%;
          @media only screen and (min-width: 700px) and (max-width: 1209px) {
            width: 52%;
          }
          @media only screen and (min-width: 500px) and (max-width: 699px) {
            width: 72%;
          }
          @media only screen and (min-width: 700px) {
            height: 20rem;
          }

          @media only screen and (min-width: 1210px) {
            height: 22.5rem;
            width: 100%;
            max-width: 50rem;
          }
          & iframe {
            width: 100%;
            height: 14rem;
            border-radius: 9.43554px;
            right: 1.2rem;
            @media only screen and (min-width: 700px) {
              height: 20rem;
            }
            @media only screen and (min-width: 1210px) {
              height: 21rem;
              width: 100%;
              position: relative;
              right: -1.8rem;
              top: 0.7rem;
            }
          }
        }

        .hidden {
          opacity: 0;
          display: none;
        }
        & button {
          all: unset;
          position: relative;
          transition: opacity 0.3s;
          width: 100%;
          margin: 1.1rem 0;
          cursor: pointer;

          @media only screen and (min-width: 1210px) {
            margin-left: 1.8rem;
            margin-top: 0.7rem;
            width: 100%;
          }
          @media only screen and (min-width: 700px) and (max-width: 1209px) {
            width: 52%;
          }
          @media only screen and (min-width: 500px) and (max-width: 699px) {
            width: 72%;
          }

          & .video-img {
            background: #186097;
            background-image: url('../../assets/img/ooredoo-thumbnail.png');
            border-radius: 9.43554px;
            height: 14.2rem;
            position: relative;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @media only screen and (min-width: 700px) {
              height: 20rem;
            }

            @media only screen and (min-width: 1210px) {
              height: 22.5rem;
              border-radius: 15px;
            }
          }

          .play-btn {
            background: #2ba7df;
            font-size: 50px;
            width: 70px;
            height: 70px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            cursor: pointer;
            img {
              position: absolute;
              top: 1rem;
              right: 1rem;
            }
            &::before,
            &::after {
              content: '';
              position: absolute;
              border: 2px solid #ccc;
              border-radius: 50%;
              left: -20px;
              right: -20px;
              top: -20px;
              bottom: -20px;
              animation: animate 1.5s linear infinite;
              opacity: 0;
            }
            &::after {
              animation-delay: 0.5s;
            }
            @keyframes animate {
              0% {
                transform: scale(0.5);
              }
              50% {
                opacity: 1;
              }
              100% {
                transform: scale(1.5);
              }
            }
          }
        }
      }

      & .who-is-takiacademy {
        @media only screen and (min-width: 1210px) {
          flex-basis: 42%;
          gap: 2rem;
          display: flex;
          flex-direction: column;
        }
        & .who-is-takiacademy-description {
          & p {
            font-family: 'Tajawal' !important;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 2.3rem;
            text-align: center;
            color: #004f8b;
            margin-block: 0;

            @media only screen and (min-width: 1210px) {
              font-size: 1.7rem;
              text-align: end;
            }

            @media only screen and (max-width: 1430px) {
              font-size: 1.5rem !important;
            }
          }

          & .takiacademy-offer {
            @media only screen and (min-width: 1210px) {
              margin-top: 2.9rem;
            }
            @media only screen and (max-width: 1470px) {
              margin-top: 0.9rem !important;
            }
          }
        }
      }
    }
    & .ooredoo-offer-details {
      display: flex;
      flex-wrap: wrap;
      gap: 1.7rem;

      @media only screen and (min-width: 1210px) {
        justify-content: space-between;
      }

      @media only screen and (max-width: 350px) {
        gap: 0.7rem !important;
      }
    }
    & .ooredoo-offer-subsribe-now {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      background: #2ba7df;
      border-radius: 28px;
      color: #ffffff;
      margin-top: 0.2rem;
      flex-direction: row-reverse;
      padding: 2rem;
      grid-gap: 1.8rem;
      gap: 1.8rem;
      flex-wrap: wrap;

      @media only screen and (min-width: 1210px) {
        margin: 3.2rem 0 3.4rem;
        padding: 3.2rem 3rem 3.2rem 0rem;
        font-size: 1.9rem;
      }

      & .subsribe-now-details {
        @media only screen and (min-width: 1210px) {
          flex-basis: 53%;
          text-align: end;

          & br {
            display: none;
          }
        }

        & span {
          font-family: 'Tajawal' !important;

          margin-block: 0;
        }

        & span:last-child {
          font-weight: 500;
        }
      }

      & .subscribe-now-btn {
        all: unset;
        display: flex;
        align-items: center;
        text-align: center;
        color: #2ba7df;
        background: #ffffff;
        border-radius: 31.2731px;
        padding: 1.1rem 4.2rem;
        cursor: pointer;
        transition: 0.3s ease;

        & > span {
          font-family: 'Tajawal' !important;
          font-weight: 700;
          font-size: 1.17rem;
          line-height: 1rem;
          @media only screen and (min-width: 1210px) {
            font-size: 1.43rem;
          }
        }

        @media only screen and (min-width: 1210px) {
          padding: 1.6rem 5.5rem;
        }

        &:hover {
          scale: 1.02;
        }
      }
    }
  }
}
.dots {
  display: none;
  @media only screen and (max-width: 1670px) {
    display: flex;
    justify-content: center;
    gap: 7px;
    width: 85px !important;
    height: 20px;
    flex-direction: row-reverse;
  }
  .dot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #d9d9d9;
    border: none;
    cursor: pointer;
  }
  .active {
    background-color: #2ba7df;
    height: 10px !important;
    width: 10px !important;
  }
}
