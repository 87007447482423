.basic {
  width: 100%;
 // max-width: 340px;
  //max-height: 200px;
  //display: flex;
  //flex-direction: row;
  align-items: center !important;
  margin-top: 5px !important;
/*  @include mq(487px, max) {
    width: 100%;
    max-width: 250px !important;
  }
  
  @include mq(391px, max) {
    //width: '100%';
    width: 150px !important;
  }
  @include mq(485px, max) {
    width: '100%';
    max-width: 250px !important;
  }*/
  .buttonAdd {
    background-color: #2ba7df;
    color: #ffffff;
    justify-content: center !important;
    align-items: center;
    //right: -76%  !important;
    //position: relative;
    text-transform: none;
    &.lrt{
      left: 80%  !important;
      position: relative;
    }
    @media screen and (max-width:360px){
      left: 28px;

    }
    @include mq(487px, max) {
      left: 185px !important;
    }
    @include mq(391px, max) {
      left: 130px !important;
    }
    @include mqs(485px, 481px) {
      left: 160px !important;
    }
    @include mqs(490px, 477px) {
      left: 160px !important;
    }
  }
  .confirm-link {
    color: #2ba7df;
    text-decoration: underline;
    text-underline: #2db3f3;
    font-weight: bold;
    justify-content: center !important;
    align-items: center;
    position: relative;
    bottom: 30px;
  }
  .basic-fields {
    width: 350 !important;
    height: 40 !important;
    // @include mq(487px, max) {
    //   max-width: 230px !important;
    // }
    @include mq(391px, max) {
      max-width: 200px !important;
    }
    @include mqs(485px, 481px) {
      width: 320px !important;
    }
    @include mqs(490px, 477px) {
      width: 300px !important;
    }
    @include mqs(475px, 443px) {
      width: 280px !important;
    }
    @include mqs(444px, 390px) {
      width: 250px !important;
    }
  }
  .Qrloader{
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
    color: grey;
  }
}

