.MuiTooltip-tooltip {
  background-color: transparent !important;
}

.more-information {
  background: #5ec9ef;
  opacity: 0.9;
  border: 1.70769px dashed #003561;
  border-radius: 15.3692px;
  display: flex;
  flex-direction: row-reverse;
  padding: 11px;
  // max-width: 300px;
  min-width: 10rem;
  width: fit-content;
  gap: 10px;

  .more-information-content {
    display: flex;
    flex-direction: column;
    flex-basis: 94%;
    .more-information-title {
      font-weight: 500;
      color: #012744;
      font-size: 23px;
      @media screen and (max-width: 978px) {
        font-size: 19px;
      }
    }
    .more-information-details {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2rem;
      /* or 146% */
      white-space: nowrap;
      text-align: right;

      color: #012744;
      display: flex;
      flex-direction: column;
      & h5 {
        font-family: 'Tajawal' !important;
        margin-block: 0;
      }
      @media screen and (max-width: 978px) {
        font-size: 18px;
      }
    }
  }

  .more-information-icon {
    color: #f1f9ff;
    border-radius: 50%;
    background: #2ba7df;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    margin-top: 0.25rem;
    box-shadow: 0 0 0 1px #f1f9ff;
    & > span {
      font-family: 'Tajawal' !important;
      margin-top: 3px;
    }
  }
}
