.calendar {
  top: 96px;
  right: -5px;

  display: flex;
  flex-direction: column;
  padding: 12px 37px;
  gap: 13px;
  z-index: 1000;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
  border-radius: 10px;
  min-width: 23.5rem;

  @media screen and (max-width: 430px) {
    right: -19px;
  }
  @media screen and (max-width: 410px) {
    right: -27px;
  }
  @media screen and (max-width: 398px) {
    right: -33px;
  }
  @media screen and (max-width: 389px) {
    right: -36px;
  }
  @media screen and (max-width: 383px) {
    right: -40px;
    padding: 12px 21px;
    min-width: 21.5rem;
  }
  @media screen and (max-width: 368px) {
    right: -41px;
  }
  @media screen and (max-width: 351px) {
    right: -40px;
  }
  @media screen and (max-width: 340px) {
    right: -38px;
  }
  @media screen and (max-width: 329px) {
    right: -40px;
    padding: 12px 22px;
    min-width: 20.5rem;
  }
  @media screen and (max-width: 321px) {
    right: -31px;
    overflow-x: auto;
    max-width: 23rem;
    min-width: 4rem;
    padding: 8px 8px;
  }
  @media screen and (max-width: 307px) {
    right: -30px;
    max-width: 17rem;
    min-width: 3rem;
    padding: 8px 12px;
  }
  & > .calendar-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #6c737b;
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
  }
  .arabic-calendar-header {
    display: flex;
    justify-content: flex-end;
    font-family: Tajawal !important;
  }
  & > .calendar-body {
    & > .calendar-navigator {
      display: flex;
      justify-content: space-between;
      padding: 4px 0 18px;

      & > .calendar-navigator-date {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-transform: capitalize;
        color: #000000;
        & > .current-date-month {
          cursor: pointer;
        }
        & > .current-date-year {
          cursor: pointer;
        }
      }

      & > .calendar-navigator-btns {
        display: flex;
        gap: 4px;
        & > svg {
          cursor: pointer;
        }
      }
      & > .arabic-calendar-navigator-btns {
        flex-direction: row-reverse;
      }
    }
    & .days-of-week {
      display: flex;
      justify-content: space-between;
    }
    & .day-of-week {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      color: #6c737b;
    }
    & .days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(6, 1fr);
      height: 278px;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      padding-top: 20px;
      text-align: center;
      & > .day {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #c2c2c2;
        border-radius: 50%;
        padding: 4px 6px;
        cursor: pointer;
        &:hover {
          background: #e7e6e6;
        }
      }
      & .start-of-month-monday {
        grid-column-start: 0;
      }
      .start-of-month-tuesday {
        grid-column-start: 2;
      }
      .start-of-month-wednesday {
        grid-column-start: 3;
      }
      .start-of-month-thursday {
        grid-column-start: 4;
      }
      .start-of-month-friday {
        grid-column-start: 5;
      }
      .start-of-month-saturday {
        grid-column-start: 6;
      }
      .start-of-month-sunday {
        grid-column-start: 7;
      }
      .today {
        color: #35bbe3 !important;
        border: 1px solid #35bbe3 !important;
      }
      .same-month {
        color: #000000;
      }
      .selected-day {
        background: #242121 !important;
        border: 1px solid #242121;
        color: white !important;
      }
      .selected-today {
        background: #35bbe3 !important;
        color: white !important;
      }
    }
  }

  & .arabic-calendar-body {
    direction: rtl;
  }

  & > .calendar-footer {
    border-top: 1px solid #ebebeb;
    padding: 13px 0;
    display: flex;
    gap: 10px;
  }
}
