.parrain-purchase-history-page {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem 3rem 1rem 3.8rem;

  @media screen and (max-width: 820px) {
    padding: 1rem 0rem 1rem 0rem;
  }

  @media screen and (max-width: 480px) {
    width: 87vw;
  }
  @media screen and (max-width: 400px) {
    width: 85vw;
  }

  .no-products {
    text-align: center;
  }
  .parrain-purchase-history-list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1.8rem;
    height: fit-content;
    gap: 6rem;

    @media screen and (max-width: 1268px) {
      padding: 0rem;
    }

    .parrain-purchase-history-list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding-bottom: 2rem;
    }
  }
  .parrain-purchase-history-products-list {
    border-radius: 10px;
    gap: 2rem;
    display: flex;
    padding: 1rem 1.8rem 2rem 1rem;
    background: #fff;

    height: fit-content;
    box-shadow: 0px 4px 8px 0px rgba(179, 208, 232, 0.6) !important;

    @media screen and (max-width: 380px) {
      padding: 1rem 0.4rem 2rem 0.4rem;
    }

    & > .MuiCardContent-root {
      gap: 2rem;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      width: 100%;
    }
    .parrain-purchase-history-page-title {
      color: #2ba7df;
      font-family: Roboto !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 17.701px;
      letter-spacing: 0.126px;
      border-radius: 10px;
      background: #e3f4ff;
      width: 100%;
      height: 3rem;
      display: flex;
      align-items: center;
      padding: 1rem;
    }

    .parrain-purchase-history-page-table {
      flex: 1;
      @media screen and (max-width: 670px) {
        min-width: 12rem;
        overflow-x: auto;
        max-width: 75vw;
      }
      @media screen and (max-width: 599px) {
        max-width: 84vw;
      }
      @media screen and (max-width: 363px) {
        max-width: 86vw;
      }
      @media screen and (max-width: 326px) {
        max-width: 96vw;
      }
      .parrain-purchase-history-page-table-head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        @media screen and (max-width: 670px) {
          width: 33rem;
        }

        & > .th {
          color: #000;
          text-align: center;
          font-family: Gothic A1 !important;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @media screen and (max-width: 730px) {
            font-size: 17px;
          }

          &:nth-child(1) {
            flex-basis: 14%;
            text-align: right;
            @media screen and (max-width: 690px) {
              flex-basis: 28%;
              text-align: center;
            }
          }
          &:nth-child(2) {
            flex-basis: 15%;
            text-align: right;
            margin-left: -3rem;

            @media screen and (max-width: 690px) {
              text-align: center;
            }
          }
          &:nth-child(3) {
            flex-basis: 24%;
            text-align: left;
            @media screen and (max-width: 690px) {
              text-align: left;
              flex-basis: 32%;
            }
          }
        }

        & > .ar-th {
          color: #6e787c;
          font-family: Gothic A1 !important;
          font-size: 1rem;
          font-weight: 500;

          &:nth-child(1) {
            flex-basis: 24%;
            text-align: center;
          }
          &:nth-child(2) {
            text-align: end;
            flex-basis: 10%;
            @media screen and (max-width: 1094px) {
              flex-basis: 15%;
            }
          }
          &:nth-child(3) {
            flex-basis: 17%;
            text-align: center;
          }
          &:nth-child(4) {
            flex-basis: 14%;
            text-align: left;
          }
        }
      }
    }
  }
  .parrain-purchase-history-page-content {
    display: flex;
    gap: 1.5rem;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      height: 100%;
    }
  }
  .order-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    padding: 2.2rem 2.3rem;
    flex-basis: 30%;
    height: fit-content;

    @media screen and (max-width: 800px) {
      padding: 2.2rem 1rem;
    }
    @media screen and (max-width: 670px) {
      min-width: 12rem;
      overflow-x: auto;
      height: fit-content;
      min-height: 33rem;
    }
    @media screen and (max-width: 480px) {
      min-height: 35rem;
      justify-content: unset;
    }
    @media screen and (max-width: 460px) {
      padding: 2.2rem 1rem;
    }
    @media screen and (max-width: 501px) {
      min-height: 36rem;
    }
    @media screen and (max-width: 454px) {
      min-height: 37rem;
    }

    @media screen and (max-width: 434px) {
      min-height: 38rem;
    }
    @media screen and (max-width: 393px) {
      min-height: 39rem;
    }

    @media screen and (max-width: 380px) {
      padding: 2.2rem 0.5rem;
      min-height: 33rem;
    }
    @media screen and (max-width: 368px) {
      min-height: 34rem;
    }
    @media screen and (max-width: 347px) {
      min-height: 35rem;
    }
    @media screen and (max-width: 313px) {
      min-height: 36rem;
    }
    .parrain-confirm-order-products-list-title {
      display: flex;
      align-items: center;
      gap: 1.3rem;
      justify-content: flex-start;
      margin-bottom: 2.2rem;

      & > hr {
        border-color: #ededed;
        flex: 1;
      }

      & > p {
        color: #1c1c1c;
        text-align: center;
        font-family: Gothic A1 !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
      }
    }
  }
  .delivery-details {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    .note {
      color: #1c1c1c;
      font-family: Gothic A1 !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .note-content {
      border-radius: 10.53px;
      border: 0.796px solid #d6d6da;
      background: #f8f8fd;
      color: #1c1c1c;
      font-family: Gothic A1 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      padding: 1rem;
      @media screen and (max-width: 670px) {
        width: 18rem;
        overflow-x: auto;
        min-width: 100%;
      }
      @media screen and (max-width: 430px) {
        width: 17rem;
      }
      @media screen and (max-width: 386px) {
        width: 16rem;
      }
      @media screen and (max-width: 380px) {
        width: 15rem;
      }
      @media screen and (max-width: 376px) {
        width: unset;
      }
    }
  }
}
