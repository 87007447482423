.products-slider {
  display: flex;

  gap: 20px;
  .rotate-arrow {
    transform: rotate(180deg);
  }

  .products-slider-list-container {
    display: flex;
    gap: 27px;
    position: relative;

    & > .navigation-wrapper {
      display: flex;
      align-items: center;
      max-width: 47vw;
      @media screen and (max-width: 1600px) {
        max-width: 46vw;
      }
      @media screen and (max-width: 1512px) {
        max-width: 44vw;
      }
      @media screen and (max-width: 1500px) {
        max-width: 42vw;
      }
      @media screen and (max-width: 1480px) {
        max-width: 40vw;
      }
      @media screen and (max-width: 1455px) {
        max-width: 39vw;
      }

      @media screen and (max-width: 1408px) {
        max-width: 38vw;
      }
      @media screen and (max-width: 1397px) {
        max-width: 37vw;
      }
      @media screen and (max-width: 1361px) {
        max-width: 36vw;
      }
      @media screen and (max-width: 1338px) {
        max-width: 34vw;
      }
      @media screen and (max-width: 1300px) {
        max-width: 33vw;
      }
      @media screen and (max-width: 1280px) {
        max-width: 32vw;
      }
      @media screen and (max-width: 1259px) {
        max-width: 31vw;
      }
      @media screen and (max-width: 1243px) {
        max-width: 27vw;
      }
      @media screen and (max-width: 1239px) {
        max-width: 29vw;
      }
      @media screen and (max-width: 1199px) {
        max-width: 33vw;
      }
      @media screen and (max-width: 1189px) {
        max-width: 70vw;
      }
      @media screen and (max-width: 1178px) {
        max-width: 68vw;
      }
      @media screen and (max-width: 1137px) {
        max-width: 67vw;
      }
      @media screen and (max-width: 1128px) {
        max-width: 68vw;
      }
      @media screen and (max-width: 1099px) {
        max-width: 67vw;
      }
      @media screen and (max-width: 1066px) {
        max-width: 66vw;
      }
      @media screen and (max-width: 1030px) {
        max-width: 65vw;
      }
      @media screen and (max-width: 1003px) {
        max-width: 64vw;
      }
      @media screen and (max-width: 975px) {
        max-width: 62vw;
      }
      @media screen and (max-width: 947px) {
        max-width: 62vw;
      }
      @media screen and (max-width: 924px) {
        max-width: 61vw;
      }
      @media screen and (max-width: 900px) {
        max-width: 60vw;
      }
      @media screen and (max-width: 875px) {
        max-width: 59vw;
      }
      @media screen and (max-width: 860px) {
        max-width: 58vw;
      }
      @media screen and (max-width: 853px) {
        max-width: 57vw;
      }
      @media screen and (max-width: 815px) {
        max-width: 56vw;
      }
      @media screen and (max-width: 799px) {
        max-width: 55vw;
      }
      @media screen and (max-width: 781px) {
        max-width: 54vw;
      }
      @media screen and (max-width: 763px) {
        max-width: 53vw;
      }
      @media screen and (max-width: 749px) {
        max-width: 52vw;
      }
      @media screen and (max-width: 733px) {
        max-width: 51vw;
      }
      @media screen and (max-width: 712px) {
        max-width: 48vw;
      }
      @media screen and (max-width: 676px) {
        max-width: 47vw;
      }
      @media screen and (max-width: 662px) {
        max-width: 46vw;
      }

      @media screen and (max-width: 614px) {
        max-width: 58vw;
      }
      @media screen and (max-width: 603px) {
        max-width: 59vw;
      }
      @media screen and (max-width: 599px) {
        max-width: 70vw;
      }
      @media screen and (max-width: 588px) {
        max-width: 70vw;
      }
      @media screen and (max-width: 558px) {
        max-width: 67vw;
      }
      @media screen and (max-width: 537px) {
        max-width: 68vw;
      }

      @media screen and (max-width: 515px) {
        max-width: 66vw;
      }
      @media screen and (max-width: 505px) {
        max-width: 83vw;
      }
      @media screen and (max-width: 466px) {
        max-width: 81vw;
      }
      @media screen and (max-width: 417px) {
        max-width: 79vw;
      }
      @media screen and (max-width: 394px) {
        max-width: 78vw;
      }
      @media screen and (max-width: 357px) {
        max-width: 76vw;
      }

      @media screen and (max-width: 324px) {
        max-width: 74vw;
      }
      @media screen and (max-width: 295px) {
        max-width: 77vw;
      }
    }

    .keen-slider {
      cursor: pointer;
      .keen-slider__slide {
        padding: 1rem 2rem;
        overflow: unset !important;
        @media screen and (max-width: 390px) {
          padding: 1rem 1.6rem !important;
        }
        @media screen and (max-width: 370px) {
          padding: 1rem 1.4rem !important;
        }
        @media screen and (max-width: 360px) {
          padding: 1rem 0.6rem !important;
        }
        @media screen and (max-width: 335px) {
          padding: 1rem 0.4rem !important;
        }
      }
    }
  }
  .arrow-wrapper {
    @media screen and (max-width: 1580px) {
      margin-top: 50px;
      display: flex;
      flex-direction: row-reverse;
      gap: 50px;
    }

    .next-image {
      cursor: pointer;
      img {
        width: 50px !important;
        height: 50px !important;
      }
      span {
        cursor: pointer;
      }
    }
    .previous-image {
      cursor: pointer;
      @media screen and (max-width: 1580px) {
        position: unset;
        left: unset;
      }
      span {
        cursor: pointer;
      }

      img {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
}

.arrow-image {
  cursor: pointer;
  background: #2ba7df !important;
  padding: 0.9rem 1.14rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;

  @media screen and (max-width: 660px) {
    padding: 0.5rem 0.6rem;

    & > img {
      width: 0.5rem;
    }
  }
  @media screen and (max-width: 505px) {
    display: none;
  }
}
.products-slider-full-sidebar {
  .arrow-image {
    @media screen and (max-width: 1360px) {
      padding: 0.5rem 0.6rem;

      & > img {
        width: 0.5rem;
      }
    }
    @media screen and (max-width: 1288px) {
      display: none;
    }
  }
}
.navigation-wrapper-full-sidebar {
  max-width: 41vw;
  & .keen-slider {
    .keen-slider__slide {
      @media screen and (max-width: 690px) {
        padding: 1rem 0.3rem !important;
      }
    }
  }
  @media screen and (max-width: 1710px) {
    max-width: 40vw;
  }
  @media screen and (max-width: 1645px) {
    max-width: 38vw;
  }
  @media screen and (max-width: 1545px) {
    max-width: 37vw;
  }
  @media screen and (max-width: 1512px) {
    max-width: 35vw;
  }
  @media screen and (max-width: 1500px) {
    max-width: 29vw;
  }

  @media screen and (max-width: 1498px) {
    max-width: 29vw;
  }
  @media screen and (max-width: 1485px) {
    max-width: calc(48vw - 280px);
  }
  @media screen and (max-width: 1478px) {
    max-width: 28vw;
  }
  @media screen and (max-width: 1476px) {
    max-width: calc(47vw - 280px);
  }
  @media screen and (max-width: 1460px) {
    max-width: 27vw;
  }
  @media screen and (max-width: 1434px) {
    max-width: 25vw;
  }
  @media screen and (max-width: 1397px) {
    max-width: 24vw;
  }
  @media screen and (max-width: 1376px) {
    max-width: 23vw;
  }
  @media screen and (max-width: 1361px) {
    max-width: 26vw;
  }
  @media screen and (max-width: 1333px) {
    max-width: 24vw;
  }
  @media screen and (max-width: 1306px) {
    max-width: 22vw;
  }

  @media screen and (max-width: 1288px) {
    max-width: 28vw;
  }
  @media screen and (max-width: 1274px) {
    max-width: 27vw;
  }

  @media screen and (max-width: 1259px) {
    max-width: 24vw;
  }
  @media screen and (max-width: 1215px) {
    max-width: 28vw;
  }

  @media screen and (max-width: 1189px) {
    max-width: 64vw;
  }
  @media screen and (max-width: 1167px) {
    max-width: 63vw;
  }
  @media screen and (max-width: 1128px) {
    max-width: 61vw;
  }

  @media screen and (max-width: 1080px) {
    max-width: 60vw;
  }
  @media screen and (max-width: 1028px) {
    max-width: 58vw;
  }

  @media screen and (max-width: 986px) {
    max-width: 56vw;
  }
  @media screen and (max-width: 966px) {
    max-width: 54vw;
  }
  @media screen and (max-width: 952px) {
    max-width: 52vw;
  }
  @media screen and (max-width: 929px) {
    max-width: 54vw;
  }
  @media screen and (max-width: 903px) {
    max-width: 52vw;
  }
  @media screen and (max-width: 890px) {
    max-width: 50vw;
  }
  @media screen and (max-width: 862px) {
    max-width: 48vw;
  }
  @media screen and (max-width: 834px) {
    max-width: 46vw;
  }
  @media screen and (max-width: 805px) {
    max-width: 44vw;
  }

  @media screen and (max-width: 734px) {
    max-width: 42vw;
  }

  @media screen and (max-width: 710px) {
    max-width: 40vw;
  }

  @media screen and (max-width: 678px) {
    max-width: 38vw;
  }
  @media screen and (max-width: 661px) {
    max-width: 37vw;
  }

  @media screen and (max-width: 656px) {
    max-width: 36vw;
  }
  @media screen and (max-width: 656px) and (min-width: 600px) {
    & .product-card-wrapper {
      max-width: 10rem !important;
      min-width: 10rem !important;
    }
  }
  @media screen and (max-width: 641px) {
    max-width: 35vw;
  }
  @media screen and (max-width: 638px) {
    max-width: 39vw;
  }
  @media screen and (max-width: 616px) {
    max-width: 37vw;
  }

  @media screen and (max-width: 599px) {
    max-width: 86vw;
  }
  @media screen and (max-width: 562px) {
    max-width: 84vw;
  }
  @media screen and (max-width: 496px) {
    max-width: 82vw;
  }
  @media screen and (max-width: 435px) {
    max-width: 80vw;
  }
  @media screen and (max-width: 382px) {
    max-width: 78vw;
  }
  @media screen and (max-width: 357px) {
    max-width: 76vw;
  }
  @media screen and (max-width: 324px) {
    max-width: 74vw;
  }
  @media screen and (max-width: 306px) {
    max-width: 72vw;
  }
  @media screen and (max-width: 300px) {
    max-width: 75vw;
  }
  @media screen and (max-width: 296px) {
    max-width: 76vw;
  }
  @media screen and (max-width: 291px) {
    max-width: 75vw;
  }
  @media screen and (max-width: 285px) {
    max-width: 80vw;
  }
}
