.offer-card-wrapper {
  background: #ea8121;
  border-radius: 16.9007px;
  box-shadow: 0px 10px 15px 11px rgba(219, 241, 251, 0.91), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 23rem;
  min-width: 23rem;
  border-radius: 32px;
  @media only screen and (max-width: 1670px) {
    max-width: 20rem !important;
    min-width: unset !important;
    margin: 0 !important;
  }
  @media only screen and (max-width: 1300px) and (min-width: 471px) {
    margin: 2rem 1rem !important;
  }
  @media only screen and (max-width: 1430px) and (min-width: 488px) {
    margin: 0px 42px 31px 21px;
  }
  @media only screen and (max-width: 487px) and (min-width: 385px) {
    margin: 0 28px 33px 16px;
  }
  @media only screen and (max-width: 470px) {
    min-width: 19rem !important;
  }
  @media only screen and (max-width: 470px) {
    min-width: 17rem !important;
    max-width: 17rem !important;

    box-shadow: none !important;
  }
  @media only screen and (max-width: 880px) {
    max-width: 22rem;
    min-width: 22rem;
  }
  @media only screen and (max-width: 475px) {
    max-width: 21rem;
    min-width: 21rem;
  }

  & .offer-card-level {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & > h2 {
      font-family: 'Tajawal' !important;
      font-weight: 700;
      text-align: center;
      color: #fffdf5;
      font-size: 1.05rem;
      line-height: 1.2rem;
      @media only screen and (max-width: 1670px) {
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
    & .info {
      color: #f1f9ff;
      color: #f1f9ff;
      border-radius: 50%;
      background: #2ba7df;
      display: flex;
      justify-content: center;
      box-shadow: 0 0 0 0.7px #f1f9ff;
      width: 1rem;
      height: 1rem;
      font-size: 0.8rem;
      margin-bottom: 0.6rem;
      & > span {
        font-family: 'Tajawal' !important;
        margin-top: 1px;
      }
    }
  }
  & .offer-card-container {
    background: #b76315;
    padding-top: 4px;
    border-radius: 32px;

    & .offer-card-content {
      border: 0.528146px solid #dadada;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1.5rem 1.5rem 0.1rem 1.5rem;
      border-radius: 32px;
      @media only screen and (max-width: 1670px) {
        padding: 1rem 0rem 0.1rem 0rem !important;
      }

      & .offer-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
          width: 100%;
          @media only screen and (max-width: 1670px) {
            width: 85%;
          }
        }
      }
      & .offer-title {
        font-family: 'Tajawal' !important;
        font-weight: 700;
        text-align: center;
        color: #0391c7;
        margin-block-end: 0px;
        font-size: 1.58rem;
        line-height: 1.9rem;

        @media only screen and (max-width: 1670px) {
          font-size: 1.4rem;
        }
        @media only screen and (max-width: 470px) {
          font-size: 1.48rem;
        }
      }
      & .offer-divisions {
        font-style: normal;
        font-weight: 400;
        text-align: center;
        color: #545454;
        display: flex;
        flex-direction: row-reverse;
        gap: 2px;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        line-height: 2.1rem;
        word-spacing: 1.2px;

        @media only screen and (max-width: 1670px) {
          font-size: 1rem;
        }

        @media only screen and (max-width: 470px) {
          font-size: 1rem;
        }
        & p {
          font-family: 'Tajawal' !important;
          margin-block: 0;
          & span {
            font-family: 'Tajawal' !important;
          }
        }
      }
      & .costs-details {
        display: flex;
        flex-direction: column;
        margin: 0.9rem 0;
        gap: 1rem;
        @media only screen and (max-width: 1670px) {
          margin: 0.8rem 0;
          justify-content: center;
          align-items: center;
        }
        .costs:first-child {
          @media only screen and (max-width: 1670px) {
            padding: 1.1rem 3.35rem;
          }
        }
        .costs:nth-child(2) {
          @media only screen and (max-width: 1670px) {
            padding: 1.1rem 2.65rem;
          }
        }
        & .costs {
          background: linear-gradient(0deg, #f2fafd, #f2fafd), #ffffff;
          border-radius: 9.50662px;
          padding: 1.1rem 3.6rem;

          @media only screen and (max-width: 470px) {
            padding: 1.1rem 1.6rem;
          }
          & > span {
            font-family: 'Tajawal' !important;
            font-weight: 700;
            line-height: 1rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;

            @media only screen and (max-width: 1670px) {
              font-size: 1.3rem;
              line-height: 0.8rem;
            }

            @media only screen and (max-width: 470px) {
              font-size: 1.2rem;
              white-space: nowrap;
            }
            & p {
              font-family: 'Tajawal' !important;
              margin-block: 0;
            }
          }
        }
      }

      & .offer-details {
        & .detail-offer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          & > img {
            width: 17px;
          }
          & p {
            font-family: 'Tajawal' !important;
            font-weight: 400;
            line-height: 0.6rem;
            text-align: right;
            color: #000000;
            white-space: nowrap;
            flex: 1;
            font-size: 0.92rem;

            @media only screen and (max-width: 1670px) {
              font-size: 0.78rem;
              line-height: 0.5rem;
            }
            @media only screen and (max-width: 470px) {
              font-size: 0.7rem;
              line-height: 0.9rem;
            }
          }
        }
      }
      & > .subscribe-now-btn {
        all: unset;
        background: #2ba7df;
        font-family: 'Tajawal' !important;
        font-weight: 700;
        line-height: 0.8rem;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        transition: 0.3s ease;

        padding: 1.18rem 5.6rem;
        border-radius: 31px;
        margin: 1rem 0rem;
        font-size: 1.12rem;
        @media only screen and (max-width: 1670px) {
          padding: 1.18rem 4.56rem;
          font-size: 1rem;
        }
        @media only screen and (max-width: 470px) {
          padding: 1rem 2.6rem;
          white-space: nowrap;
        }

        &:hover {
          scale: 1.02;
        }
      }
    }
  }
}
