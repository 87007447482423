@use '../../../assets/scss/utilities/mixins' as *;

.select-wrapper {
  padding: 5px 14px 21px 5px;
  margin-left: 10px;
  position: relative;
  padding-top: 15px;
  //   width: 47%;
  width: 97%;

  @media (min-width: 1351px) and (max-width: 1512px) {
    padding-top: 9px !important;
    padding-bottom: 0 !important;
  }
  @include mq(881px, max) {
    width: 100%;
  }
  @media screen and (max-width: 650px) {
    padding: 5px 14px 0px 5px !important;
  }
  .arabic-error {
    justify-content: flex-end;
  }
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 67px;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 46px;
    font-size: 15px;
    line-height: 22px;
    padding-left: 15px;
    margin-top: 15px;
    padding: 0 15px;
    cursor: pointer;

    @media (min-width: 1351px) and (max-width: 1512px) {
      height: 57px !important;
    }
    @media (min-width: 1055px) and (max-width: 1350px) {
      height: 48px !important;
    }
    @media screen and (max-width: 414px) {
      height: 54px !important;
    }
    @media screen and (max-width: 1055px) {
      width: unset !important;
    }

    &:focus-within {
      border: 2px solid #2ba7df !important;
    }
    @include mq(881px, max) {
      width: 100%;
    }

    @media screen and (max-width: 650px) {
      margin-top: 10px !important;
    }
    @media screen and (max-width: 1550px) and (min-width: 650) {
      width: 100%;
    }

    .selected-option-value {
      font-family: 'Tajawal' !important;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: right;
      color: #8d8d8d;
      @media (min-width: 1055px) and (max-width: 1350px) {
        font-size: 0.8rem;
        line-height: 8px;
      }
      @media screen and (max-width: 650px) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: 412px) {
        font-size: 1rem;
      }
    }

    img {
      filter: invert(0%) sepia(90%) saturate(7500%) hue-rotate(96deg) brightness(88%) contrast(109%);

      margin: 8px 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      transform: rotateZ(180deg);
      transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
    }
  }
  & .field-error-style {
    background: #fbe5e8 !important;
    border: 1px solid #ed3863 !important;
    & > input {
      background: #fbe5e8 !important;
    }
  }
  & .disabled-select {
    background: #f8f7f7;
    cursor: not-allowed;
    & > .selected-option-value {
      color: #9b9393 !important;
    }
    & > img {
      filter: none !important;
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }
  .select-options {
    padding: 9px 0px;
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 10%) -4px 9px 25px -6px;
    border-radius: 8px;
    margin-top: 10px;
    display: block;
    position: absolute;
    width: 94%;
    z-index: 1000;
    top: 107px;

    max-height: 189px;
    overflow: scroll;
    @media (min-width: 1351px) and (max-width: 1512px) {
      top: 84px;
      left: 16px;
    }
    @media (min-width: 1055px) and (max-width: 1350px) {
      width: 100%;
      top: 91px;
      max-height: 139px;
    }
    @media screen and (max-width: 650px) {
      top: 98px;
    }

    @media screen and (max-width: 318px) {
      width: 100% !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    .select-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 27px;
      font-family: 'Tajawal' !important;

      color: #898989;
      padding: 10px;
    }
    .no-options {
      font-size: 15px;
      line-height: 27px;
      padding-left: 10px;
    }
    & .radio-btn:hover {
      background: #f0f0f0;
    }
  }
  .open-select {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  .closed-select {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .no-options-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & label {
    font-family: 'Tajawal' !important;
    font-weight: 500;
    font-size: 22px;
    line-height: 14px;
    color: #494949;
    display: flex;
    white-space: nowrap;

    & p {
      margin-block: 4px !important;
    }
    @media (min-width: 1055px) and (max-width: 1350px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 1550px) and (min-width: 650) {
      font-size: 18px;
      line-height: 15px !important;
      & p {
        margin-block: 0 !important;
      }
    }

    & > .required-field {
      color: #ed3863;
      font-size: 13px;
      padding: 0px 5px;
      @media screen and (max-width: 1550px) and (min-width: 650) {
        padding: unset;
        padding-left: 5px;
      }
    }
    @media screen and (max-width: 650px) {
      font-size: 1.2rem;
      line-height: 32px;
      p {
        margin-bottom: 0 !important;
      }
    }
    @media screen and (max-width: 380px) {
      font-size: 1rem;
    }
  }

  & .arabic-label {
    display: flex;
    justify-content: end;
    margin-right: 11px;

    @media screen and (max-width: 1350px) and (min-width: 1055) {
      padding-right: 9px;
    }
    & > p {
      font-family: 'Tajawal' !important;
    }
  }
}
.full-width {
  grid-column: 1/-1;

  & > .select {
    width: 100% !important;
  }
}

.radio-btn {
  display: flex;
  gap: 10px;
  padding: 8px 0 0;
  cursor: pointer;

  & .option-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #585a60;
    font-family: 'Tajawal' !important;
    cursor: pointer;
    margin-bottom: 9px !important;
    transition: font-size 0.2s ease;

    @media (min-width: 1055px) and (max-width: 1350px) {
      font-size: 0.68rem;
      line-height: 15px;
    }

    @media screen and (max-width: 929px) {
      font-size: 0.8rem !important;
    }
    @media screen and (max-width: 811px) {
      font-size: 0.7rem !important;
    }

    @media screen and (max-width: 650px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 412px) {
      font-size: 0.7rem !important;
      line-height: 15px !important;
    }

    &:hover {
      font-size: 15px;
    }
  }
}

.custom-radio-btn {
  width: 20px;
  height: 20px;
  border: 1px solid #e8e8e8;
  margin-left: 11px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (min-width: 1055px) and (max-width: 1350px) {
    width: 14px;
    height: 14px;
  }
  @media screen and (max-width: 929px) {
    width: 17px !important;
    height: 17px !important;
  }
  @media screen and (max-width: 811px) {
    width: 15px !important;
    height: 15px !important;
  }

  @media screen and (max-width: 650px) {
    width: 20px !important;
    height: 20px !important;
  }
  @media screen and (max-width: 412px) {
    width: 15px !important;
    height: 15px !important;
  }

  & .checkmark {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;
    display: inline-block;
    background-color: #00aaf9;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  & input {
    display: none;
  }
  & input:checked + .checkmark {
    opacity: 1;
  }
}
.checked-btn {
  border: 1px solid #00aaf9 !important;
  background: #e4f0fd !important;
}
