.delivery-details-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #d6d6da;
  padding: 0.2rem 1.7rem;
  gap: 1rem;

  @media screen and (max-width: 670px) {
    min-width: 100%;

    height: fit-content;
    overflow-x: auto;
  }
  @media screen and (max-width: 430px) {
    width: 17rem;
  }
  @media screen and (max-width: 386px) {
    width: 16rem;
  }
  @media screen and (max-width: 380px) {
    width: 15rem;
    padding: 0.2rem;
  }
  @media screen and (max-width: 376px) {
    width: unset;
  }

  & > div {
    display: flex;
    gap: 0.3rem;

    & > p {
      color: #000;
      text-align: center;
      font-family: Gothic A1 !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media screen and (max-width: 380px) {
        font-size: 14px;
      }
    }
  }
  & > p {
    color: #1c1c1c;
    text-align: center;
    font-family: Gothic A1 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
