.takiacademy-logo {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.2rem;
  cursor: pointer;
  & > img {
    @media only screen and (min-width: 1210px) {
      width: 60px;
    }
  }
  & .takiacademy-logo-details {
    color: #2ba7df;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    & .takiacademy-title {
      font-weight: 500;
      font-size: 0.81rem;
      line-height: 0.6rem;
      @media only screen and (min-width: 1210px) {
        font-size: 1.8rem;
        line-height: 1rem;
      }
      & > span {
        font-weight: 600;
      }
    }
    & .takiacademy-website {
      font-weight: 400;
      font-size: 0.47rem;
      line-height: 0.9rem;

      @media only screen and (min-width: 1210px) {
        font-size: 1.03rem;
        line-height: 2rem;
      }
      & > span {
        font-weight: 500;
        & span {
          font-weight: 600;
        }
      }
    }
  }
}
