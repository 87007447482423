.parrain-back-link {
  border-radius: 8.571px !important;
  border: 0px solid #e4e7ec !important;
  background: #2ba7df !important;
  margin: 0.6rem !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 21.429px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  transition: all ease 0.3s !important;
  min-width: 8.1rem !important;
  min-height: 2.5rem !important;
  align-self: flex-start !important;
  &:hover {
    background: darken(#2ba7df, 1) !important;
  }
}
