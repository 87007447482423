.no-coins-page {
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;

  height: 100%;

  justify-content: space-evenly;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
  .no-coins-page-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    background: #fff;
    box-shadow: unset !important;
    height: 100%;
    margin-top: 0.8rem;
    height: 79vh;

    .no-coins-page-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      max-width: 29rem;

      .no-coins-page-img {
        max-width: 22.7rem;

        @media screen and (max-width: 1013px) {
          max-width: 14.7rem;
        }
        @media screen and (max-width: 500px) {
          //   max-width: 9.7rem;
          max-width: 12.7rem;
        }

        & > img {
          width: 100%;
        }
      }

      & > p {
        color: #000;

        text-align: center;
        font-family: Roboto !important;
        font-size: 20px;
        font-weight: 400;
        line-height: 37.18px;
        letter-spacing: 0.15px;
        @media screen and (max-width: 1013px) {
          font-size: 16px;
        }
        @media screen and (max-width: 500px) {
          font-size: 14px;
          line-height: 19.18px;
        }
      }
      .no-coins-page-back-home-btn {
        border-radius: 17.55px;
        background: #2998f4;
        color: #fff;

        text-align: center;
        font-family: Gothic A1 !important;
        font-size: 17px;
        font-weight: 400;
        line-height: normal;
        margin-top: 2rem;
        width: 100%;
        height: 4.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 0.3s;

        @media screen and (max-width: 1013px) {
          font-size: 15px;
          margin-top: 0.5rem;
          height: 3.2rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 13px;
          margin-top: 0;
          height: 2.5rem;
        }

        &:hover {
          background: darken(#2998f4, 10);
        }
      }
    }
  }
}
