.parrain-page-cart {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8.7px 0px rgba(62, 184, 249, 0.2);
  display: flex;
  padding: 0rem 1.1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-height: 3rem;
  min-height: 3rem;
  cursor: pointer;

  .parrain-page-cart-icon {
    width: 2rem;
    height: 2rem;
  }
  .parrain-page-cart-text {
    color: #0068b4;
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }
  .parrain-page-cart-items-number {
    border-radius: 3px;
    border: 1px solid #0068b4;
    background: #c9f0f2;
    color: #0068b4;

    font-family: Inter !important;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 0.3rem 0.6rem;
  }
}
