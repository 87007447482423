.edit-profile-widget {
  & > .MuiCardContent-root {
    & > .child-full {
      display: flex;
      flex-direction: column;
      & > .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        & > img {
          width: 60px;
          margin-right: 12px;
        }
        & > .MuiBadge-root {
          margin-right: 12px;
        }
        & > .variant-btn {
          max-width: max-content;
          padding: 4px 12px !important;
          margin-left: 4px;
        }
      }
      & > .ta-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        justify-content: start !important;
        & > * {
          width: calc(50% - 4px);
          // margin-bottom: 8px;
          @include mq(780px, max) {
            width: calc(100%);
          }
        }
        & > :nth-child(even) {
          margin-left: 8px;
          @include mq(780px, max) {
            margin-left: 0;
          }
        }
        & > .MuiFormControl-root > .fix-display {
          display: contents;
          font-size: 13px;
          font-weight: 500;
        }
        &.rtl {
          & > * {
            direction: rtl !important;
            & > :nth-child(even) {
              & > .MuiFormControl-root > .fix-display {
                direction: rtl !important;
              }
            }
          }
        }
      }
    }
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2ba7df !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #2ba7df !important;
}

.rtl {
  .edit-profile-widget {
    & > .MuiCardContent-root {
      & > .child-full {
        & > .row {
          direction: rtl;
          & > .MuiBadge-root {
            margin-left: 12px;
          }
          & > .variant-btn {
            margin-right: 4px;
          }
        }
        & > .ta-form {
          & > * {
            padding-left: 5px;
          }
          & > .MuiFormControl-root > .fix-display {
            direction: rtl;
          }
        }
      }
    }
  }
}
