.parrain-page-header {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 851px) {
    gap: 1rem;
  }
  @media screen and (max-width: 420px) {
    gap: 1rem;
  }
  & .parrain-page-title-wrapper {
    // flex-basis: 62%;
    & .parrain-page-title {
      width: 100%;
      a {
        position: relative;
        transition: color 0.3s ease-in-out;

        &::before {
          content: '';
          position: absolute;
          top: 100%;
          width: 100%;
          height: 3px;
          background-color: #a2a2a7;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }

        &:hover {
          color: #a2a2a7;
        }

        &:hover::before {
          transform: scaleX(1);
        }
      }

      & span {
        font-family: Roboto !important;
        font-size: 26px;
        line-height: 37.18px;
        letter-spacing: 0.15px;

        @media screen and (max-width: 515px) {
          font-size: 18px;
        }
      }
      .parrain-page-current-path {
        color: #2ba7df;
        font-weight: 700;
      }
      .parrain-page-root-path {
        color: #a2a2a7;
        font-weight: 400;
      }
    }
    & .parrain-page-description {
      color: #000;
      font-family: Poppins !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 24.2px;
      letter-spacing: 0.15px;
    }
  }
}
