.parrain-page {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1rem 3rem 1rem 3.8rem;

  @media screen and (max-width: 633px) {
    padding: 1rem 0rem 1rem 0rem;
  }

  .parrain-page-content {
    display: flex;
    gap: 3.2rem;

    @media screen and (max-width: 1423px) {
      flex-direction: column;
    }
    .parrain-page-left-cards {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      & .parrain-page-content-wrapper {
        display: flex;
        padding-top: 1.9rem;
        & .parrain-page-parrain-cards {
          flex-basis: 59.2%;
          display: flex;
          flex-direction: column;
          gap: 1.8rem;

          .cards-after-code-generaltion-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.8rem;
            .cards-after-code-generaltion {
              display: flex;
              justify-content: space-between;

              .parrain-page-parrain-card-content-code-field {
                color: #086088 !important;
                font-size: 18px !important;
              }
              & .parrain-page-parrain-card-content-code-field-copy-btn-wrapper {
                background: #ffff !important;
                padding: 0.6rem 1.1rem !important;
                & img {
                  filter: invert(56%) sepia(94%) saturate(1202%) hue-rotate(167deg) brightness(92%)
                    contrast(90%);
                }
              }
            }
          }
        }

        .parrain-page-info-card {
        }
      }

      & .parrain-page-parrain-card {
        border-radius: 8px;
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 4.4rem;
        width: 100%;
        box-shadow: 0px 3.719px 7.438px 0px rgba(179, 208, 232, 0.6) !important;

        .parrain-page-parrain-card-content {
          display: flex;
          justify-content: space-between;
          padding: 0 !important;
          @media screen and (max-width: 1076px) {
            flex-direction: column;
          }

          .parrain-page-parrain-left-card {
            flex-basis: 50%;
            & .parrain-page-parrain-card-header {
              display: flex;
              align-items: center;

              & .parrain-page-parrain-card-icon {
                padding: 0.5rem 2.6rem;
                background: #2e9ff4;
                border-top-left-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                & > img {
                }
              }

              & .parrain-page-parrain-card-title {
                color: #2ba7df;
                font-family: Roboto !important;
                font-size: 18px;
                font-weight: 500;
                line-height: 11.687px;
                letter-spacing: 0.047px;
                width: 100%;
                text-align: center;
                margin-right: 4rem;
              }
            }
            .parrain-page-parrain-left-card-content {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              padding: 1rem 2rem 3rem 2rem;
              grid-gap: 2rem;
              gap: 2rem;
              .parrain-page-left-card-img {
                & > img {
                  width: 100%;
                }
              }
              .parrain-page-left-card-text {
                color: #000;
                text-align: center;
                font-family: Poppins !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 19.8px;
                letter-spacing: 0.135px;
              }
            }
          }

          .parrain-page-parrain-right-card {
            display: flex;
            justify-content: center;
            border-radius: 7.201px;
            background: #2ba7df;
            padding: 3.4rem 0 4rem;
            flex-basis: 50%;

            .parrain-page-parrain-card-content {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 3rem;
              width: 84%;

              .parrain-page-parrain-card-content-icon {
                border-radius: 71px;
                background: #dfedfb;
                padding: 1.8rem 0.95rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .parrain-page-parrain-card-content-text {
                font-family: Roboto !important;
                color: #fff;
                text-align: center;
                font-size: 14.096px;
                font-style: normal;
                font-weight: 400;
                line-height: 20.5px;
                letter-spacing: 0.042px;
              }

              .parrain-page-parrain-card-content-generate-code-btn {
                width: 100%;
                border-radius: 9.001px;
                background: #fff;
                box-shadow: 0px 7.1px 0px 0px #a7e3ff;
                color: #2ba7df;
                text-align: center;
                cursor: pointer;
                transition: all ease 0.3s;
                display: flex;
                justify-content: center;
                align-items: center;
                & > button {
                  width: 100%;
                  padding: 0.8rem;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22.053px;
                  letter-spacing: 0.36px;
                  font-family: Roboto !important;

                  @media screen and (max-width: 475px) {
                    font-size: 1rem;

                    line-height: 1.4rem;
                  }
                }
                &:hover {
                  background: darken(#a7e3ff, 3);
                }
              }
            }
          }
        }
      }
    }
  }

  .parrain-page-content-full-sidebar {
    @extend .parrain-page-content;

    @media screen and (max-width: 1480px) and (min-width: 600px) {
      flex-direction: column !important;
    }

    .parrain-page-card-info {
      @media screen and (max-width: 1761px) {
        min-width: 28rem !important;
      }
      @media screen and (max-width: 1706px) {
        min-width: 26rem !important;
      }
      @media screen and (max-width: 1662px) {
        min-width: 24rem !important;
      }
      @media screen and (max-width: 830px) {
        min-width: 16rem !important;
      }
    }

    & .products-slider-list-container {
      @media screen and (max-width: 1189px) {
        display: block !important;
      }
    }
  }
  .navigation-wrapper-full-sidebar {
    max-width: 38vw !important ;

    @media screen and (max-width: 1873px) {
      max-width: 37vw !important ;
    }
    @media screen and (max-width: 1841px) {
      max-width: 36vw !important ;
    }
    @media screen and (max-width: 1827px) {
      max-width: 39vw !important ;
    }
    @media screen and (max-width: 1800px) {
      max-width: 38vw !important ;
    }
    @media screen and (max-width: 1771px) {
      max-width: 37vw !important ;
    }
    @media screen and (max-width: 1760px) {
      max-width: 35vw !important ;
    }
    @media screen and (max-width: 1738px) {
      max-width: 34vw !important ;
    }
    @media screen and (max-width: 1630px) {
      max-width: 30vw !important;
    }
    @media screen and (max-width: 1603px) {
      max-width: 32vw !important ;
    }
    @media screen and (max-width: 1566px) {
      max-width: 30vw !important ;
    }
    @media screen and (max-width: 1520px) {
      max-width: 29vw !important ;
    }
    @media screen and (max-width: 1497px) {
      max-width: 30vw !important ;
    }
    @media screen and (max-width: 1441px) {
      max-width: 29vw !important ;
    }
    @media screen and (max-width: 1434px) {
      max-width: 28vw !important;
    }
    @media screen and (max-width: 1401px) {
      max-width: 27vw !important;
    }
    @media screen and (max-width: 1380px) {
      max-width: 26vw !important;
    }
    @media screen and (max-width: 1363px) {
      max-width: 25vw !important;
    }
    @media screen and (max-width: 1360px) {
      max-width: 28vw !important;
    }
    @media screen and (max-width: 1335px) {
      max-width: 27vw !important;
    }
    @media screen and (max-width: 1312px) {
      max-width: 26vw !important;
    }
    @media screen and (max-width: 1300px) {
      max-width: 25vw !important;
    }

    @media screen and (max-width: 1288px) {
      max-width: 30vw !important;
    }
    @media screen and (max-width: 1251px) {
      max-width: 29vw !important;
    }
    @media screen and (max-width: 1192px) {
      max-width: 27vw !important;
    }
    @media screen and (max-width: 1189px) {
      max-width: 57vw !important;
    }
    @media screen and (max-width: 1082px) {
      max-width: 56vw !important;
    }
    @media screen and (max-width: 1063px) {
      max-width: 58vw !important;
    }
    @media screen and (max-width: 1064px) {
      max-width: 58vw !important;
    }
    @media screen and (max-width: 1046px) {
      max-width: 56vw !important;
    }
    @media screen and (max-width: 982px) {
      max-width: 54vw !important;
    }
    @media screen and (max-width: 977px) {
      max-width: 51vw !important;
    }
    @media screen and (max-width: 953px) {
      max-width: 49vw !important;
    }
    @media screen and (max-width: 950px) {
      max-width: 56vw !important;
    }
    @media screen and (max-width: 889px) {
      max-width: 54vw !important;
    }
    @media screen and (max-width: 851px) {
      max-width: 52vw !important;
    }

    @media screen and (max-width: 815px) {
      max-width: 50vw !important;
    }
    @media screen and (max-width: 783px) {
      max-width: 48vw !important;
    }

    @media screen and (max-width: 742px) {
      max-width: 46vw !important;
    }

    @media screen and (max-width: 720px) {
      max-width: 44vw !important;
    }
    @media screen and (max-width: 687px) {
      max-width: 42vw !important;
    }
    @media screen and (max-width: 647px) {
      max-width: 38vw !important;
    }
    @media screen and (max-width: 614px) {
      max-width: 36vw !important;
    }

    @media screen and (max-width: 599px) {
      max-width: 83vw !important;
    }
    @media screen and (max-width: 505px) {
      max-width: 81vw !important;
    }
    @media screen and (max-width: 420px) {
      max-width: 80vw !important;
    }
    @media screen and (max-width: 396px) {
      max-width: 79vw !important;
    }
    @media screen and (max-width: 385px) {
      max-width: 78vw !important;
    }
    @media screen and (max-width: 366px) {
      max-width: 77vw !important;
    }
    @media screen and (max-width: 345px) {
      max-width: 75vw !important;
    }
    @media screen and (max-width: 330px) {
      max-width: 74vw !important;
    }
    @media screen and (max-width: 305px) {
      max-width: 70vw !important;
    }
    @media screen and (max-width: 290px) {
      max-width: 78vw !important;
    }
    @media screen and (max-width: 280px) {
      max-width: 80vw !important;
    }
  }
  .navigation-wrapper {
    @media screen and (max-width: 1761px) {
      max-width: 45vw !important;
    }
    @media screen and (max-width: 1742px) {
      max-width: 49vw !important;
    }
    @media screen and (max-width: 1710px) {
      max-width: 47vw !important;
    }
    @media screen and (max-width: 1690px) {
      max-width: 45vw !important;
    }
    @media screen and (max-width: 1629px) {
      max-width: 44vw !important;
    }
    @media screen and (max-width: 1595px) {
      max-width: 43vw !important;
    }
    @media screen and (max-width: 1588px) {
      max-width: 42vw !important;
    }
    @media screen and (max-width: 1569px) {
      max-width: 40vw !important;
    }
    @media screen and (max-width: 1509px) {
      max-width: 39vw !important;
    }

    @media screen and (max-width: 1497px) {
      max-width: 37vw !important;
    }

    @media screen and (max-width: 1488px) {
      max-width: 35vw !important;
    }
    @media screen and (max-width: 1290px) {
      max-width: 33vw !important;
    }
    @media screen and (max-width: 1189px) {
      max-width: 63vw !important;
    }
    @media screen and (max-width: 1076px) {
      max-width: 60vw !important;
    }
    @media screen and (max-width: 1068px) {
      max-width: 58vw !important;
    }
    @media screen and (max-width: 1018px) {
      max-width: 56vw !important;
    }
    @media screen and (max-width: 975px) {
      max-width: 58vw !important;
    }
    @media screen and (max-width: 950px) {
      max-width: 65vw !important;
    }

    @media screen and (max-width: 943px) {
      max-width: 65vw !important;
    }
    @media screen and (max-width: 932px) {
      max-width: 63vw !important;
    }
    @media screen and (max-width: 892px) {
      max-width: 61vw !important;
    }
    @media screen and (max-width: 856px) {
      max-width: 59vw !important;
    }
    @media screen and (max-width: 821px) {
      max-width: 58vw !important;
    }
    @media screen and (max-width: 793px) {
      max-width: 56vw !important;
    }
    @media screen and (max-width: 761px) {
      max-width: 55vw !important;
    }
    @media screen and (max-width: 719px) {
      max-width: 53vw !important;
    }
    @media screen and (max-width: 707px) {
      max-width: 51vw !important;
    }
    @media screen and (max-width: 681px) {
      max-width: 49vw !important;
    }
    @media screen and (max-width: 659px) {
      max-width: 58vw !important;
    }
    @media screen and (max-width: 656px) {
      max-width: 55vw !important;
    }
    @media screen and (max-width: 633px) {
      max-width: 55vw !important;
    }
    @media screen and (max-width: 599px) {
      max-width: 67vw !important;
    }
    @media screen and (max-width: 505px) {
      max-width: 76vw !important;
    }
    @media screen and (max-width: 420px) {
      max-width: 79vw !important;
    }
    @media screen and (max-width: 396px) {
      max-width: 77vw !important;
    }
    @media screen and (max-width: 385px) {
      max-width: 76vw !important;
    }
    @media screen and (max-width: 366px) {
      max-width: 75vw !important;
    }
    @media screen and (max-width: 345px) {
      max-width: 74vw !important;
    }
    @media screen and (max-width: 330px) {
      max-width: 73vw !important;
    }
    @media screen and (max-width: 308px) {
      max-width: 72vw !important;
    }
    @media screen and (max-width: 290px) {
      max-width: 78vw !important;
    }
    @media screen and (max-width: 280px) {
      max-width: 80vw !important;
    }
  }

  .parrain-page-card-info {
    min-width: 30rem !important;
    @media screen and (max-width: 1827px) {
      min-width: 26rem !important;
    }
    @media screen and (max-width: 1762px) {
      min-width: 25rem !important;
    }
    @media screen and (max-width: 1712px) {
      min-width: 26rem !important;
    }
    @media screen and (max-width: 1424px) {
      min-width: 24rem !important;
    }
    @media screen and (max-width: 1285px) {
      min-width: 16rem !important;
    }
  }
  .parraing-gift-code-page-products-and-info-card {
    display: flex;
    gap: 1.6rem;
    flex: 1;
    @media screen and (max-width: 1189px) {
      flex-direction: column;
    }
  }
}
.parrain-page-parrain-card-content-code-field-copy-btn-tooltip {
  background: #686767 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 4px;
  border-radius: 4px;
  margin-top: -9px !important;
}
.parrain-page-full-sidebar {
  @media screen and (max-width: 950px) and (min-width: 634px) {
    padding: 1rem !important;
  }
  .products-slider-full-sidebar {
    @media screen and (max-width: 1500px) {
      gap: 1rem !important;
    }
  }
}
