.control-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  padding-bottom: 10px;
  .global {
    margin-left: 25px;
    margin-right: 5px;
    margin-top: 15px;
    color: #ffffff;
    max-height: 28px;
    min-height: 28px;
    min-width: 28px;
    max-width: 28px;
    box-shadow: 0px 6px 9px #0000001c;
    opacity: 1;
  }
  .fabStyle {
    margin-top: 15px;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: 0px 6px 9px #0000001c;
    opacity: 1;
    max-height: 28px;
    min-height: 28px;
    min-width: 28px;
    max-width: 28px;
    &:disabled {
      display: none;
    }
  }
  .reportFab {
    margin-top: 15px;
    margin-left: 20px;
    color: #ffffff;
    box-shadow: 0px 6px 9px #0000001c;
    opacity: 1;
    max-height: 28px;
    min-height: 28px;
    min-width: 28px;
    max-width: 28px;
  }
  .iconStyle {
    color: #2ba7df;
    width: 20px;
    //height: 22;
    opacity: 1;
  }
  .iconReactionStyle {
    color: #2ba7df;
    width: 16px;
    opacity: 1;
  }
  .iconReactionSelected {
    color: #ed5454;
    width: 16px;
    opacity: 1;
  }
  .bookmarkIcon {
    opacity: 1;
    width: 11.2px;
    height: 20px;
  }
  .title {
    color: #ffffff;
    font-size: 11px;
    letter-spacing: 0;
    opacity: 0.5;
    font-family: tajawal;
    margin-top: 22px;
    margin-left: 10px;
  }
  .bookmarkImg {
    text-align: center;
    width: 11.2px;
    height: 20px;
    opacity: 1;
    &.bookmarked {
      filter: invert(45%) sepia(10%) saturate(6147%) hue-rotate(325deg) brightness(104%)
        contrast(86%);
    }
    &.unbookmarked {
      filter: invert(26%) sepia(32%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(89%);
    }
  }
}
