// File: App.scss
// This file contains all styles related to the site/application.
@import "./assets/scss/utilities/fonts";
@import "./assets/scss/utilities/mixins";
@import "./assets/scss/utilities/variables";
@import "./assets/scss/utilities/base";
@import "./components/Modal/modal";
@import "./layouts/Sidebar/sidebar";
@import "./pages/pages";
@import "./pages/Wallet/wallet";
@import "./components/Logo/logo";
@import "./components/WalletWidget/walletwidget";
@import "./components/MenuDropdown/menuDropdown";
@import "./components/ViewTitle/viewTitle";
@import "pages/Events/Desktop/calender";
@import "./pages/Exams/_exam.scss";
@import "./components/CardTitle/cardTitle";
@import "./components/Table/table";
@import "./components/BanqueCard/banqueCard";
@import "./components/ServiceCard/serviceCard";
@import "./components/courses/ButtonGroupComponent/buttonGroup";
@import "./components/courses/ContentTable/content-table";
@import "./components/courses/ControlsButton/control-button";
@import "./components/courses/VideoTitle/video-title";
@import "./components/courses/VideoContainer/video-container";
@import "./components/courses/VideoPath/video-path";
@import "./components/courses/VideoInformation/video-information";
@import "./components/courses/LockedVideo/locked-video";
@import "./components/courses/VideoView/video-view";
@import "./components/subjects/Progress/progress";
@import "./components/subjects/SubjectCard/subject-card";
@import "./components/subjects/SubjectTabItemContainer/tab-item";
@import "./components/common/BasicModalFields/basic";
@import "./components/common/FormTransferDialog/transfer";
@import "./components/ButtonTransfert/_buttonTransfert";
@import "./components/ButtonAddPoint/_buttonAddPoint";
@import "./components/components";
@import "./components/courses/players/players";
@import "./components/ActionLink/actionLink";
@import "./components/Card/card";
@import "./components/common/Modal/modal";
@import "./components/Button/taButton";

@import "./components/BankAccountsItem/bankAccountsItem";
@import "./components/WalletBalance/walletBalance";
@import "./components/PaymentMethodsList/paymentMethodsList";
@import "./components/PaymentMethodsItem/paymentMethodsItem";
@import "./components/TransferLink/transferLink";
@import "./components/WalletDetails/walletDetails";
@import "./components/BankAccountsList/bankAccountsList";

@import "./components/ResponsiveTable/responsiveTable";

@import "./components/BookmarksWidget/bookmarksWidget";
@import "./components/TodayEventsWidget/todayEventsWidget";
@import "./components/TodayEventsItem/todayEventsItem";

@import "./components/MyProgressWidget/myProgressWidget";
@import "./components/MyProgressItem/myProgressItem";
@import "./components/ShowMoreLink/showMoreLink";

@import "./components/ProfileWidget/profileWidget";
@import "./components/SubscriptionItem1/subscriptionItem1";
@import "./components/SubscriptionItem2/subscriptionItem2";

@import "./components/ProfileInfos/profileInfos";

@import "./pages/Offers/offers.scss";
@import "./pages/Confirm/_index.scss";
@import "./pages/Login";
@import "./components/PointsTransferButton/point-transfer";
@import "./components/TransferLink/transferLink";

@import "./components/VariantButton/variantButton";
@import "./components/SecurityWidget/securityWidget";

@import "./components/EditProfileWidget/editProfileWidget";
@import "./components/UpgradeCard/upgrade-card";

@import "./components/Dialog/dialog";
@import "./components/ActionsGroup/actionsGroup";

@import "./components/VideoCard/videoCard";
@import "./components/common/VideoModal/videoModal";
@import "./components/MessagesDropDown/message";
@import "./components/NotificationsDropDown/notification";
@import "./components/NotificationPreferenceList/preferencesList";

@import "./components/WalletServices/walletServices";
@import "./components/Empty/empty";
@import "./pages/Subjects/subjects";
@import "./pages/Ooredoo/ooredooCard";
@import "./components/common/FormD17Dialog/d17";

@import "./components/FreeVideosWidget/freeVideosWidget";
@import "./components/FreeVideoItem/freeVideoItem";
@import "./components/WarningWidget/_warningwidget.scss";

@import "./components/PubsCards/pubsCards";
@import "./components/CouponModal/couponModal";
@import "https://fonts.googleapis.com/css2?family=Tajawal:wght@200&display=swap";
@import "./components/offerConfirmation/confirmationModal.scss";
@import "./layouts/Header/search";
@import "./pages/Revenue/Revenue";
@import "./pages/Parrain";
@import "./components/Language/language.scss";

@import "./components/CouponModal/OoredooModals/ooredooCard";
@import "./components/NoDataIllustration/noDataIllustration";
@import "./components/NotificationsList/notificationsList";
@import "./components/Loader/loader";
@import "./components/optimiumModal/optimiumModal.scss";

/* STYLES GO HERE */
html * {
  // font-family: Tajawal !important;
}
body {
  background: $darker_white !important;
}

a {
  text-decoration: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-width: 4px;
  scrollbar-color: #2897f3 #e5edf7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  z-index: 120;
}

*::-webkit-scrollbar-track {
  background: #e5edf7;
}

*::-webkit-scrollbar-thumb {
  background-color: #2897f3;
  border-radius: 0;
  border: 0px solid #e5edf7;
}
.maintenace {
  font-family: "Tajawal" !important;
}
#icon {
  font-size: 130px !important;
  color: #f0e445 !important ;
}

.rtl {
  input {
    text-align: right;
  }
}
