@use '../../../../assets/scss/utilities/mixins' as *;

.ooredoo-select-field {
  margin-left: 10px;
  position: relative;
  width: 100%;
  padding: 0 !important;
  margin-left: 0 !important;
  @media only screen and (min-width: 1210px) {
  }
  @include mq(881px, max) {
    width: 100%;
  }

  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.9rem;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #55819a;
    font-size: 15px;
    line-height: 22px;
    padding-left: 15px;
    margin-top: 5px !important;
    cursor: pointer;
    padding: 0 0 0 15px;

    & .select-placeholder {
      display: flex;

      & > img {
        margin: 8px;
      }
    }

    &:focus-within {
      border: 2px solid #2ba7df !important;
    }
    @include mq(881px, max) {
      width: 100%;
    }

    @media screen and (max-width: 1550px) {
      width: 100%;
    }

    .selected-option-value {
      font-family: 'Poppins' !important;
      color: #8d8d8d;
      font-weight: 300;
      font-size: 0.8rem;
      line-height: 13px;
      display: flex;
      align-items: center;
      color: #96aac4;

      @media only screen and (max-width: 1530px) and (min-width: 1209px) {
        font-size: 12px !important;
        line-height: 12px;
      }
      @media only screen and (min-width: 1210px) {
        font-size: 17px;
        line-height: 26px;
      }
    }

    & > img {
      margin: 8px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      transform: rotateZ(180deg);
      transition: all 0.4s cubic-bezier(0.54, 0.01, 0, 1.49);
    }
  }
  & .field-error-style {
    background: #fbe5e8 !important;
    border: 1px solid #ed3863 !important;
    & > input {
      background: #fbe5e8 !important;
    }
  }
  & .disabled-select {
    background: #f8f7f7;
    cursor: not-allowed;
    & > .selected-option-value {
      color: #9b9393 !important;
    }
    & > img {
      filter: none !important;
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }
  .select-options {
    padding: 9px 0px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    border-radius: 8px;
    margin-top: 25px;
    display: block;
    position: absolute;
    width: 100%;
    z-index: 1000;

    max-height: 191px;
    top: 151px;
    overflow: scroll;

    @media only screen and (min-width: 1210px) {
      max-height: 204px;
      top: 96px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    .no-options {
      font-size: 15px;
      line-height: 27px;
      padding-left: 10px;
    }
    & .radio-btn:hover {
      background: #f0f0f0;
    }
  }
  .open-select {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  .closed-select {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .no-options-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & label {
    font-family: 'Poppins' !important;
    font-weight: 400;
    font-size: 1.06rem;
    line-height: 13px;
    color: #55819a;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    & p {
      font-family: 'Poppins' !important;
      margin-block: 5px;
      @media only screen and (min-width: 1210px) {
        margin-block: 1rem;
      }
    }

    @media only screen and (min-width: 1210px) {
      font-size: 18px;
    }

    & > .required-field {
      color: #ed3863;
      font-size: 11px;

      padding: 5px 2px;

      @media only screen and (min-width: 1210px) {
        padding: 15px 3px;
      }
    }
  }
}
.full-width {
  grid-column: 1/-1;

  & > .select {
    width: 100% !important;
  }
}

.radio-btn {
  display: flex;
  gap: 10px;
  padding: 8px 0 0;
  cursor: pointer;

  & label {
    justify-content: center;
    align-items: center;
  }

  & .option-name {
    font-weight: 400;

    line-height: 21px;
    color: #585a60;
    font-family: 'Poppins' !important;
    cursor: pointer;

    transition: font-size 0.2s ease;

    font-size: 0.9rem;
    margin-bottom: 0;

    @media screen and (min-width: 1210px) {
      margin-bottom: 9px;
      font-size: 16px;
    }
    &:hover {
      font-size: 15px;
    }
  }
}

.custom-radio-btn {
  width: 20px;
  height: 20px;
  border: 1px solid #e8e8e8;
  margin-left: 11px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & .checkmark {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;
    display: inline-block;
    background-color: #00aaf9;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  & input {
    display: none;
  }
  & input:checked + .checkmark {
    opacity: 1;
  }
}
.checked-btn {
  border: 1px solid #00aaf9 !important;
  background: #e4f0fd !important;
}
