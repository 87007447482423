@use './../../assets//scss/utilities/mixins' as *;
@use './../../assets//scss/utilities/variables' as *;

.auth-page {
  & .auth-logo {
    cursor: pointer;
  }
  & .reset-password-form {
    margin-top: 0rem !important;
    & > form {
      & > .MuiBox-root {
        // row-gap: 0px !important;
      }
    }
  }

  .reset-password-content {
    margin-top: 6rem;
  }
  .reset-password-page-description {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: right;
    font-family: 'Tajawal' !important;
    color: #494949;
    margin-top: 1rem;
    max-width: 34rem;
    text-align: center;
    @media screen and (max-width: 1530px) {
      font-size: 20px;
    }
    @media screen and (max-width: 1368px) {
      max-width: 25rem;
    }
    @media screen and (max-width: 1156px) {
      margin-left: 7rem;
      max-width: 24rem;
    }

    @media screen and (max-width: 1056px) {
      max-width: 34rem !important;
      margin-left: 0 !important;
    }
    @media screen and (max-width: 1230px) and (min-width: 1055) {
      max-width: 31rem;
    }
    @media screen and (max-width: 731px) {
      font-size: 1.3rem;
    }

    @media screen and (max-width: 626px) {
      max-width: 26rem !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 1rem;
      max-width: 19rem !important;
    }
  }
  .cancel-btn {
    color: #35bbe3 !important;
    background: #fff !important;
    border: 1px solid #35bbe3 !important;
    margin-top: 14px !important;
  }
}
.reset-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  @media (min-width: 1351px) and (max-width: 1512px) {
    gap: 0.5rem;
    margin-top: 5rem;
  }
  @media screen and (max-width: 1024px) {
    gap: 0 !important;
    margin-top: 0 !important;
  }
  @media screen and (max-width: 600px) {
    margin-top: 0 !important;
  }
  & .small-form {
    justify-content: flex-start !important;
  }
}
.confirm-code-page {
  & .auth-page-content {
    gap: 5rem !important;
  }
}
.confirm-code-content {
  gap: 3rem;

  @media screen and (max-width: 1350px) and (min-width: 1055) {
    gap: 0 !important;
  }
  @media (min-width: 1351px) and (max-width: 1512px) {
    margin-top: 8rem !important;
  }
  @media screen and (max-width: 600px) {
    gap: 0 !important;
  }
}

.reset-code-input {
  & .field-wrapper {
    padding-top: 0 !important;
  }
  label {
    line-height: 25px !important;

    margin-bottom: 2rem !important;
  }
}
