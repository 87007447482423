.auth-page {
  width: 100%;
  background: #fff !important;
  min-height: 100vh;

  .auth-page-img {
    @media (min-width: 1055px) and (max-width: 1450px) {
      width: 494px !important;

      & > img {
        width: 494px !important;
      }
    }

    @media screen and (min-width: 1449px) and (max-width: 1530px) {
      width: 640px !important;

      & > img {
        width: 640px !important;
      }
    }
    @media screen and (min-width: 1665px) {
      width: 688px !important;

      & > img {
        width: 688px !important;
      }
    }
  }

  a {
    cursor: pointer;

    & > span {
      font-family: 'Tajawal' !important;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }

  & .auth-page-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 6%;

    & .auth-page-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      margin-top: 1.4rem;
      flex-basis: 57%;
      gap: 3rem;

      @media screen and (max-width: 1024px) {
        gap: 0 !important;
      }
      @media screen and (max-width: 600px) {
        gap: 1rem !important;
      }
      @media screen and (max-width: 560px) {
        gap: 0 !important;
      }

      & .auth-page-header {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        width: 95%;
        @media screen and (max-width: 420px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        & .auth-logo {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 650px) {
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
              width: 72% !important;
            }
          }

          @media screen and (max-width: 460px) {
            margin-top: 9px;

            & img {
              width: 145px !important;
            }
          }
        }
      }

      & .auth-form {
        margin-top: 0;
        margin-bottom: 25px;
        @media (min-width: 1351px) and (max-width: 1512px) {
          & form {
            & .MuiBox-root {
              grid-row-gap: 17px !important;
            }
          }
        }
        @media screen and (max-width: 414px) {
          & form {
            & .MuiBox-root {
              grid-row-gap: 7px !important;
            }
          }
        }

        @media screen and (max-width: 1550px) {
          width: 95%;
        }

        @media screen and (max-width: 1056px) {
          width: 96%;
        }

        & .auth-btn {
          all: unset;
          background: #35bbe3;
          border-radius: 59px;
          width: 100%;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Tajawal' !important;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          margin-top: 41px;
          cursor: pointer;
          text-align: center;
          transition: all 0.3s;

          @media (min-width: 1351px) and (max-width: 1512px) {
            margin-top: 24px !important;
            height: 57px !important;
          }
          @media (min-width: 1055px) and (max-width: 1350px) {
            height: 48px;
            font-size: 1.1rem;
            width: 95%;
          }
          @media screen and (max-width: 1024px) {
            margin-top: 11px !important;
          }
          @media screen and (max-width: 350px) {
            font-size: 17px;
          }
          @media screen and (max-width: 414px) {
            height: 54px;
            margin-top: 7px !important;
          }
          &:hover {
            scale: 1.01;
          }
        }
        & .disabled-auth-btn {
          background: #dddbdb !important;
          cursor: not-allowed;
        }

        & .forgot-password {
          font-family: 'Tajawal' !important;
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          color: #35bbe3;
          margin-left: 20px;
          line-height: 17px;
          color: #35bbe3;
          margin-left: 20px;
          border-bottom: 1px solid #35bbe3;
          width: fit-content;
          display: flex;

          padding-top: 42px;

          @media screen and (max-width: 11512px) {
            padding-top: 24px !important;
          }
          @media screen and (max-width: 1024px) {
            margin-bottom: 10px !important;
          }
          @media screen and (max-width: 650px) {
            font-size: 1.2rem;
          }
          @media screen and (max-width: 380px) {
            font-size: 1rem;
          }
        }
        & .arabic-text {
          display: flex;
          justify-content: end;
          align-self: end;
          margin-right: 20px;
        }
      }

      & .small-form {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 1.4rem;

        justify-content: center;

        margin-top: 0rem !important;
        @media screen and (max-width: 1157px) and (min-width: 1056px) {
          margin-left: 6rem;
        }
        @media screen and (max-width: 900px) {
          width: 80%;
        }
        @media screen and (max-width: 480px) {
          gap: 1.4rem;
        }
        @media (min-width: 375px) and (max-width: 414px) {
          margin-top: 1rem !important;
        }
        & > form {
          display: flex;
          flex-direction: column;
          & > .MuiBox-root {
            @media (min-width: 375px) and (max-width: 414px) {
              margin-bottom: 16px !important;
            }
            @media screen and (max-width: 414px) {
              grid-row-gap: 7px !important;
            }
          }
        }

        .create-new-account {
          font-family: 'Tajawal' !important;
          align-self: center;
          font-weight: 400;
          font-size: 1.3rem;
          margin-top: 9px;
          line-height: 40px;
          & > a {
            font-family: 'Tajawal' !important;
            color: #35bbe3;
            transition: 0.1s;
            &:hover {
              border-bottom: 1px solid #35bbe3;
              width: fit-content;
            }
          }
          @media screen and (max-width: 1024px) {
            margin-bottom: 10px !important;
          }
          @media screen and (max-width: 650px) {
            font-size: 1.2rem;
            line-height: 22px;
            text-align: center;
          }
          @media screen and (max-width: 380px) {
            font-size: 1rem;
          }
        }
      }

      & > .MuiFormControl-root {
        align-self: flex-end;
        margin: 5px 14% 0 0;

        @media screen and (max-width: 1055px) {
          margin: 0rem 2rem 0 0 !important;
        }
      }

      @media screen and (max-width: 893px) {
        flex-basis: 36%;
      }
      @media (min-width: 1351px) and (max-width: 1512px) {
        margin-top: 14px !important;
      }

      @media screen and (max-width: 1055px) {
        flex-basis: 100% !important;
        padding-top: 0px !important;
        justify-content: start;
      }

      & .content-title {
        font-weight: 700;
        font-size: 36px;
        text-align: center;
        color: #000000;
        line-height: 67px;
        margin-top: 1.5rem;

        font-family: 'Tajawal' !important;

        @media (min-width: 1351px) and (max-width: 1512px) {
          margin-top: 0.5rem !important;
          font-size: 32px !important;
          margin-bottom: 13px !important;
        }
        @media (min-width: 1055px) and (max-width: 1350px) {
          font-size: 27px !important;
          margin-left: 55px !important;
        }

        @media screen and (max-width: 1055px) {
          font-weight: 700;
          line-height: 54px;
          margin-bottom: 10px !important;
        }
        @media screen and (max-width: 650px) {
          font-size: 29px;
          line-height: 32px;
        }
        @media screen and (max-width: 365px) {
          margin-top: 1.5rem !important;
          margin-bottom: -5px !important;
        }
        @media screen and (max-width: 320px) {
          font-size: 26px;
        }
      }
      @media (min-width: 1351px) and (max-width: 1512px) {
        gap: 0 !important;
      }
      @media (min-width: 1441px) and (max-width: 1512px) {
        flex-basis: 53% !important;
      }
      @media (min-width: 1196px) and (max-width: 1440px) {
        flex-basis: 60% !important;
      }
      @media (min-width: 1056px) and (max-width: 1195px) {
        flex-basis: 55% !important;
      }
    }
    & .auth-page-img {
      flex: 1;
      width: 672px;

      @media screen and (max-width: 630px) {
        order: -1;
      }

      @media screen and (max-width: 1055px) {
        order: -1;
        display: none;
      }
      & > img {
        width: 100%;
        height: 100%;
        position: fixed;
        object-fit: cover;
        width: 672px;
        left: 0;
        bottom: 0;
        top: 0;
      }
    }

    & .auth-page-mobile-img {
      background: #35bbe3;
      justify-content: center;
      align-items: baseline;
      height: 44vw;
      width: 100%;
      overflow: hidden;
      display: none;
      & > img {
        width: 40% !important;
      }

      @media screen and (max-width: 1055px) {
        display: flex;
      }
    }
  }
}
