.copy-code-card {
  border-radius: 8px;
  background: linear-gradient(180deg, #29b8f8 0%, #0c93d0 100%);
  box-shadow: 0px 2px 8px 0px rgba(108, 189, 255, 0.6);
  display: flex;
  flex-direction: column;
  padding: 0.4rem 2.1rem 1.5rem;
  min-width: 27rem;
  flex-basis: 49.4%;
  gap: 1.3rem;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1262px) {
    padding: 0 !important;
  }

  @media screen and (max-width: 1045px) {
    padding-bottom: 1rem !important;
    min-width: unset !important;
  }
  & .copy-code-card-header {
    display: flex;
    gap: 2rem;
    padding: 1rem 0 0.3em 0rem;
    justify-content: center;

    & .copy-code-card-header-title {
      color: #f8f8fd;
      font-family: Roboto !important;
      font-size: 18.595px;
      font-weight: 400;
      line-height: 34.568px;
      letter-spacing: 0.139px;
      text-align: center;
      padding: 0 0.5rem;
      @media screen and (max-width: 412px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    & .copy-code-card-header-title-full-sidebar {
      @extend .copy-code-card-header-title;
      @media screen and (max-width: 740px) and (min-width: 600px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  & .copy-code-card-content {
    display: flex;
    gap: 1rem;
    width: 79%;
    @media screen and (max-width: 412px) {
      width: 100%;
      gap: 0.3rem;
      padding: 0 0.4rem;
      justify-content: center;
    }
    .copy-code-card-share-code-btn {
      border-radius: 10px;
      background: #fff;
      flex-basis: 10.5%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.8rem;
      min-height: 3.1rem;
      max-height: 3.1rem;
      cursor: pointer;

      @media screen and (max-width: 412px) {
        min-height: unset;
        padding: 0.3rem;

        & > img {
          width: 100%;
        }
      }
      &:hover {
        background-color: darken(#fff, 5) !important;
      }
    }
  }
}

.copy-code-card-full-sidebar {
  @extend .copy-code-card;
  @media screen and (max-width: 1250px) and (min-width: 600px) {
    padding-bottom: 1rem !important;
    min-width: unset !important;
  }
}

.parrain-page-parrain-card-content-code-field-wrapper {
  border-radius: 10px;
  background: #e1eaf5;
  display: flex;

  width: 100%;
  position: relative;
  min-height: 3.1rem;
  max-height: 3.1rem;
  min-width: 14rem;

  @media screen and (max-width: 412px) {
    min-width: unset;
    min-height: 2.2rem;
    width: 88%;
  }
  &.active {
    background-color: darken(#e1eaf5, 3) !important;
  }

  .parrain-page-parrain-card-content-code-field {
    all: unset;
    padding: 0rem 1.5rem;
    flex-basis: 84%;
    font-family: Poppins !important;
    line-height: normal;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: all ease 0.3s;
    color: #086088;
    text-align: center;
    font-size: 16.735px;
    font-weight: 700;
    @media screen and (max-width: 1654px) {
      padding: 0 !important;
    }

    @media screen and (max-width: 412px) {
      font-size: 14px;
      flex-basis: 91%;
    }

    &::placeholder {
      color: #c2c2c2;
      font-family: Gothic A1 !important;
      font-size: 15.795px;
      font-weight: 700;
      line-height: normal;
    }

    &.copy-card-after-code-generation {
      border: 2px solid #2ba6df37 !important;
    }
  }

  .parrain-page-parrain-card-content-code-field-copy-btn-wrapper {
    border-radius: 10px;
    background: #fff;
    padding: 0.6rem 1.1rem !important;
    height: 100%;
    min-width: unset !important;
    position: absolute;
    right: 0;
    @media screen and (max-width: 412px) {
      padding: 0.3rem !important;
    }
    &:hover {
      background-color: darken(#fff, 5) !important;
    }
    .parrain-page-parrain-card-content-code-field-copy-btn {
      cursor: pointer;

      @media screen and (max-width: 412px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & > .img {
        @media screen and (max-width: 412px) {
          width: 74%;
        }
      }
    }
  }
}
.parrain-social-media-list {
  & > .MuiPaper-root {
    padding: 0.8rem 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 6px 8px 0px rgba(179, 208, 232, 0.6);
    margin-top: 0.5rem;

    @media screen and (max-width: 412px) {
      gap: 0;
    }
  }
}
