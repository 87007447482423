.ExamDetailsContainer {
  box-shadow: 0px 3px 10px #2ba7df29 !important;
  margin-top: 15px;
  max-width:750px;
  &.rtl{
    direction:rtl ;
    margin-left: 1200px;
  }
  .timer {
    display: flex;
    align-items: flex-start;
    width: 20%;
  }
  .cardContent {
    .bloc-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include mq(880px, max) {
        flex-direction: column;
      }
      & > .col-left {
        display: flex;
        flex-direction: column;
        width: calc(64% - 12px);
        @include mq(700px, max) {
          width: 100%;
        }
      }
      & > .col-right {
        display: flex;
        flex-direction: column;
        width: calc(36% - 12px);
        @include mq(700px, max) {
          width: 100%;
        }
      }
      & .modal-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #2ba7df;
        border-radius: 4px;
        padding: 12px 20px;
        width: 240px;
        margin-bottom: 20px;
        box-shadow: 0 10px 18px #0000001c;
        transition: all 300ms ease;
        cursor: pointer;
        @include mq(1000px, max) {
          width: 200px;
          padding: 8px;
        }
        @include mq(500px, max) {
          width: 120px;
          padding: 8px;
        }
        & > img {
          width: 58px;
          margin-right: 20px;
          @include mq(1000px, max) {
            width: 38px;
            margin-right: 18px;
          }
        }
        & > div {
          display: flex;
          flex-direction: column;
          color: #838eab;
          & > .primary-text {
            font-size: 30px;
            font-weight: 600;
            @include mq(1000px, max) {
              font-size: 18px;
            }
          }
          & > .secondary-text {
            font-size: 18px;
            margin-bottom: -6px;
            @include mq(1000px, max) {
              font-size: 14px;
            }
          }
        }
        &:hover {
          box-shadow: 0 2px 4px #00000045;
        }
      }
      .timer {
        display: flex;
        align-items: center;
        width: 20%;
      }
      .timer-text {
        justify-content: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: black;
      }
    }
    .cardHeader {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 5px;
    }

    .card {
      display: flex;
      justify-content: flex-start;
      //width: 480px;

      .customCard {
        //width: 486px;
        height: 100%;
        border-radius: 10px;
        box-shadow: none;
        display: flex;
        text-transform: none;
        border: 1px black solid;

        .examsSubject {
          background-color: $blue;
          color: white;
        }

        .teacherName {
          color: gray;
          font-weight: Bold !important;
        }

        .examDate {
          color: red;
          padding-bottom: 10px;
        }
        .examDetails {
          display: flex;
          justify-content: space-between;
        }

        .text {
          padding-bottom: 5px;
          font-weight: Bold !important;
        }

        .submitFiles {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}
