.plan-card {
  background: #eff9ff;
  border-radius: 10px;
  flex-basis: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  flex: 1;

  padding: 1.8rem 0.4rem;
  gap: 26px;

  @media only screen and (min-width: 1280px) {
    padding: 2rem 2.3rem;
    gap: 1rem;
    max-width: unset;
  }
  & .ooredoo-brand {
    width: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 53%;

      @media only screen and (min-width: 1280px) {
        width: 74%;
      }
    }
  }
  & .plan-card-price {
    background: #fcfeff;
    border: 1px dashed #daedf9;
    box-shadow: 0px 4px 4px rgba(216, 240, 255, 0.64);
    border-radius: 44px;
    font-family: 'Poppins' !important;
    font-weight: 400;
    white-space: nowrap;
    line-height: 32px;
    color: #002e52;

    font-size: 1.2rem;
    padding: 0.5rem 1.2rem;
    @media only screen and (min-width: 1280px) {
      font-size: 21px;
      padding: 0.6rem 1.6rem;
    }
    & span {
      font-weight: 500;
    }
  }
  & .plan-description {
    font-family: 'Poppins' !important;
    font-weight: 300;

    color: #000000;

    font-size: 1.1rem;
    line-height: 1.6rem;
    text-align: center;

    width: 89%;
    margin-top: 0.1rem;

    @media only screen and (min-width: 1280px) {
      margin-top: 0.9rem;
      font-size: 1.1rem;
      line-height: 1.6rem;
      width: 100%;
    }
  }
  & .get-plan-btn {
    all: unset;
    background: #2ba7df;
    box-shadow: 0px 7px 8px rgba(43, 167, 223, 0.22);
    border-radius: 27.0842px;
    color: #fff;

    font-family: 'Poppins' !important;
    font-weight: 500;

    line-height: 24px;
    cursor: pointer;

    font-size: 1.2rem;
    padding: 0.5rem 2.6rem;

    @media only screen and (min-width: 1280px) {
      padding: 0.7rem 3.65rem;
      font-size: 15.7263px;
    }
    &:hover {
      scale: 1.02;
    }
  }
}
