.prrain-products-page {
  padding: 2.7rem 2.9rem;
  @media screen and (max-width: 600px) {
    padding: 0;
    padding-top: 1rem;
  }
  .prrain-products-list-card {
    margin-top: 2.4rem;

    .prrain-products-list {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
      justify-content: center;
      padding: 2rem;
      @media screen and (max-width: 1458px) and (min-width: 1379px) {
        padding: 2rem 1rem;
        gap: 1rem;
      }
      @media screen and (max-width: 600px) {
        padding: 1rem 0;
      }
    }
    .prrain-products-list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > .MuiTablePagination-root {
        display: flex !important;
        justify-content: center !important;
      }
    }
  }
}

.prrain-products-page-full-sidebar {
  @extend .prrain-products-page;
  @media screen and (max-width: 800px) and (min-width: 600px) {
    padding: 2.7rem 0;
  }
  .prrain-products-list {
    @media screen and (max-width: 700px) and (min-width: 600px) {
      padding: 0.2rem 0 !important;
      .add-to-cart-product-card {
        max-width: unset !important;
      }
    }
  }
}
