.notification {
  //   @include mq(960px, max) {
  //     display: none;
  //   }

  & > button > span {
    & > .MuiAvatar-root {
      margin-right: 8px;
    }
    & .MuiTabIndicator-root {
      color: red;
    }
    & > .texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      text-transform: capitalize;
      @include mq(960px, max) {
        display: none;
      }
      & > .title {
        font-size: 14px;
        line-height: 20px;
        color: $blue;
        font-weight: 500;
        @include mq(960px, max) {
          font-size: 12px;
          line-height: 15px;
          color: $blue;
          font-weight: 400;
        }
      }
      & > .subtitle {
        font-size: 12px;
        line-height: 12px;
        color: $grey;
        font-weight: 400;
      }
    }
    & > .icon-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $blue;
      margin-left: 4px;
    }
  }
  .notification-icon {
    opacity: 1;
    height: 1.5rem;
    scale: 1.4;
    filter: invert(31%) sepia(72%) saturate(567%) hue-rotate(153deg) brightness(93%) contrast(88%);
    @include mq(960px, max) {
      width: 1.3rem;
      scale: 1.2;
    }
  }
  .nb-notifications {
    opacity: 1;
  }
  .notification-popper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 8px;
    & > img {
      opacity: 1;
      width: 1.4rem;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .title-container {
      display: flex;
      flex-direction: row;
      margin-left: 6px;
      & > img {
        opacity: 1;
        width: 1.2rem;
        transform: translateY(-3px);
        margin-left: 8px;
        margin-right: 8px;
        scale: 1.2;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .menu-item-title {
    color: #2ba7df;
    font-size: 16px;
    font-weight: bold;
    top: 79px;
    left: 860px;
    font-family: tajawal;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
    opacity: 1;
  }
}
.rtl {
  .notification {
    & > button > span {
      & > .MuiAvatar-root {
        margin-right: 0;
        margin-left: 8px;
      }

      & > .texts {
        align-items: flex-end;
      }

      & > .icon-holder {
        margin-left: 0;
        margin-right: 4px;
      }
    }

    .notification-popper {
      flex-direction: row-reverse;
      margin-left: 8px;
      margin-right: 0;
      & > img {
        margin-left: 8px;
        margin-right: 0;
      }
      .title-container {
        flex-direction: row-reverse;
        & > img {
          transform: scaleX(-1);
          margin-bottom: 5px;
        }
      }
    }

    .menu-item-title {
      text-align: right;
      left: auto;
      margin-right: 8px;
      flex-direction: row-reverse;
    }
  }
}
