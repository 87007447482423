.parraing-gift-code-page {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 1.5rem 1rem 1rem 2.3rem;
  gap: 1.5rem;

  @media screen and (max-width: 633px) {
    padding: 1rem 0rem 1rem 0rem;
  }
  .parraing-gift-code-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .parraing-gift-code-after-generation-cards {
      display: flex;
      gap: 1rem;

      @media screen and (max-width: 1045px) {
        flex-direction: column;
      }
    }

    .parraing-gift-code-after-generation-cards-full-sidebar {
      @extend .parraing-gift-code-after-generation-cards;
      @media screen and (max-width: 1250px) and (min-width: 600px) {
        flex-direction: column;
      }
    }
  }

  .ta-card {
    width: 100%;

    .card-header-wrapper {
      display: flex;
      gap: 0.4rem;
      & .MuiCardHeader-root {
        padding: 0rem 0.1rem 0.8rem !important;
        cursor: pointer;
      }
    }
    .child-full {
      & > .user-history {
        td {
          padding: 6px 4px !important;
          &:nth-child(3) {
            display: flex;
            justify-content: center;
            align-items: center;

            & > div {
              padding: 0.1rem 0.5rem;
              width: fit-content;
              border-radius: 17.665px;
              background: #d4eaf7;
            }
          }
        }
      }
      & > .purchase-history {
        td {
          padding: 6px 4px !important;
          &:nth-child(5) {
            & > div {
              margin: auto;
              padding: 0.1rem 0.5rem;
              width: fit-content;
              color: #fff;
              text-align: center;
              font-family: Gothic A1 !important;
              font-size: 13.286px;
              font-weight: 500;
              border-radius: 6.643px;
              display: flex;
              gap: 0.3rem;
              line-height: 0.1rem;
              justify-content: space-between;
            }
            .pending {
              background: #f59500;
              min-width: 6.15rem;
            }
            .rejected {
              background: #ff839e;
              min-width: 6.15rem;
            }
            .approuved {
              background: #61db99;
              min-width: 6.15rem;
            }
          }
          .purchase-history-action {
            cursor: pointer;
            transition: all ease 0.3s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .parraing-gift-code-page-products-and-info-card {
    display: flex;
    gap: 1.6rem;
    flex: 1;
    @media screen and (max-width: 1189px) {
      flex-direction: column;
    }
  }
  .parrain-page-card-info {
    flex-basis: 54% !important;

    & > iframe {
      min-height: 20rem !important;
    }
  }
  .parrain-page-products-list {
    & div:nth-child(2) {
      gap: 1rem !important;
    }

    .product-card {
      @media screen and (max-width: 1580px) {
        max-width: 22rem !important;
      }
    }
  }
}
