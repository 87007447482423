.balance-card {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 1.44rem;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 8px;
  @media screen and (max-width: 1262px) {
    flex-direction: column;
    gap: 1rem;
  }

  .balance-card-header {
    border-radius: 8px;
    background: #f2faff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5rem;
    flex-basis: 75%;
    @media screen and (max-width: 1424px) {
      padding: 0 1rem;
    }
    @media screen and (max-width: 1262px) {
      padding: 1rem;
    }
    .balance-card-title {
      display: flex;
      gap: 2rem;

      @media screen and (max-width: 412px) {
        gap: 0;
      }
      .balance-card-header-img {
        & > img {
          @media screen and (max-width: 412px) {
            width: 70%;
          }
          @media screen and (max-width: 357px) {
            width: unset;
          }
        }
      }

      .balance-card-header-title {
        color: #1f1f1f;
        font-family: Roboto !important;
        font-size: 20px;
        font-weight: 600;
        line-height: 34.568px;
        letter-spacing: 0.139px;

        @media screen and (max-width: 412px) {
          text-align: center;

          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    .balance-card-description {
      color: #000;

      font-family: Roboto !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 152.344%;
      letter-spacing: 0.139px;

      @media screen and (max-width: 412px) {
        text-align: justify;
      }
    }
  }

  .balance-card-content {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1262px) {
      width: 100%;
    }
    .convert-coins-btn {
      display: flex;
      border-radius: 8px;
      background: #29ade9;

      box-shadow: 0px 4px 0px 0px #1d95cc;

      height: 100%;
      min-height: 9rem;
      min-width: 14.2rem;

      align-items: center;
      justify-content: center;
      transition: all ease 0.3s;
      cursor: pointer;
      &:hover {
        background: darken(#29ade9, 5);
      }

      @media screen and (max-width: 1262px) {
        width: 100%;
        min-height: unset;
      }

      .convert-coins-btn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        @media screen and (max-width: 1262px) {
          gap: 1rem;
          flex-direction: row;

          & > img {
            width: 1.5rem;
          }
        }

        & > p {
          color: #fff;

          text-align: center;
          font-family: Roboto !important;
          font-size: 17.87px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0.149px;
        }
      }
    }
  }
}

.balance-card-full-sidebar {
  @extend .balance-card;
  @media screen and (max-width: 1538px) and (min-width: 600px) {
    flex-direction: column;
    gap: 1rem;

    .balance-card-header {
      padding: 1rem;
    }

    .balance-card-content {
      width: 100%;
    }
    .convert-coins-btn {
      width: 100%;
      min-height: 4rem !important;
    }
    .convert-coins-btn-content {
      gap: 0.5rem;
      flex-direction: row;

      & > p {
        margin-block: 0;
      }
      & > img {
        width: 1.5rem;
      }
    }
  }
}
