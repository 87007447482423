.convert-coins-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  background: #fff !important;
  box-shadow: 0px 18px 16.4px 0px rgba(155, 231, 255, 0.27) !important;
  padding: 2rem;
  transition: all ease 0.3s;
  max-width: 28.3rem;
  height: 36rem;

  @media screen and (max-width: 846px) {
    padding: 1rem;
  }

  @media screen and (max-width: 1520px) {
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    height: 80%;
    max-width: 47%;
    min-height: 29rem;
  }
  @media screen and (max-width: 620px) {
    min-height: 90%;
    max-width: 90%;
  }
  @media screen and (max-width: 539px) {
    min-height: 100%;
  }
  @media screen and (max-width: 479px) {
    min-height: 27rem;
  }
  @media screen and (max-width: 312px) {
    min-height: 30rem;
  }

  .convert-coins-card-img {
    @media screen and (max-width: 1520px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > img {
      width: 100%;
      @media screen and (max-width: 1520px) {
        width: 60%;
      }
    }
  }
  .convert-coins-title {
    color: #000;
    font-family: Roboto !important;
    font-size: 32.5px;
    font-weight: 500;
    line-height: 21.178px;
    letter-spacing: 0.346px;
    text-transform: uppercase;
    @media screen and (max-width: 846px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1520px) {
      font-size: 27px;
    }
  }
  .convert-coins-description {
    color: #000;
    text-align: center;
    font-family: Roboto !important;
    font-size: 19px;
    font-weight: 300;
    line-height: 24.2px;
    letter-spacing: 0.346px;
    margin-top: 1rem;
    @media screen and (max-width: 846px) {
      font-size: 17px;
    }

    @media screen and (max-width: 1520px) {
      margin-top: 0;
      font-size: 16px;
      line-height: 22px;
      height: 8rem;
      overflow: auto;
    }
    @media screen and (max-width: 620px) {
      height: unset;
      overflow: unset;
    }
  }
  .convert-coins-btn {
    color: #fffefe;
    font-family: Roboto !important;
    font-size: 22px;
    font-weight: 500;
    line-height: 21.178px;
    letter-spacing: 0.346px;
    text-transform: capitalize;
    border-radius: 8px;
    background: #29b8f8;
    box-shadow: 0px 4px 0px 0px #9be0ff;
    width: 100%;
    min-height: 4rem;
    margin-top: 1rem;
    @media screen and (max-width: 846px) {
      min-height: 3.5rem;
    }
    @media screen and (max-width: 1520px) {
      font-size: 17px;
      min-height: 3rem;
      margin-top: 0;
    }

    &:hover {
      background: darken(#29b8f8, 5);
    }
  }
}
