.ta-modal {
  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      border-radius: 20px;
      min-width: 600px;
      @include mq(775px, max) {
        min-width: 80%;
      }
      & > .MuiDialogTitle-root {
        & > .MuiTypography-root {
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > .label {
            font-size: 20px;
            color: #2ba7df;
            @include mq(775px, max) {
              font-size: 16px;
            }
          }
          & > .close-btn {
            font-size: 12px;
            color: #2ba7df;
            font-weight: 600;
            cursor: pointer;
            transition: all 300ms ease;
            &:hover {
              color: #3870a3;
            }
          }
        }
      }
    }
  }
}

.ta-delete-modal {
  @extend .ta-modal;
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: auto;
    }
  }
}
.add-content-modal {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      & > .MuiDialogContent-root {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 100px;
        padding-top: 20px;
        @include mq(775px, max) {
          flex-direction: column;
          align-items: center;
        }
        & > .items-group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @include mq(775px, max) {
            margin-bottom: 20px;
          }
          & > img {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

.add-child-modal {
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: auto;

  & .MuiToggleButton-root {
    border: 2px solid #2ba7df;
    color: #2ba7df;
    width: 150px !important;
    height: 40px !important;
  }
  & .MuiToggleButton-root.Mui-selected {
    background-color: #2ba7df;
    color: white;
  }
  .MuiToggleButton-root.Mui-selected:hover {
    background-color: #2ba7df;
  }
  .MuiToggleButton-root:hover {
    background-color: rgba(252, 252, 252, 0.6);
  }

  .toggel-bt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    .bt-title-add-child {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.MuiSwitch-root {
  & > .MuiSwitch-colorPrimary.Mui-checked {
    color: #2ba7df;
  }
  & > .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #2ba7df;
  }
}

.ta-form {
  &.centred {
    display: flex;
    flex-direction: column;
  }

  align-items: center !important;
  justify-content: center !important;

  & .btn-red-border {
    border: 2px solid #ed5454;
  }

  & > .input {
    &.m-size {
      width: 80% !important;
    }
    &.size-sm {
      margin-bottom: 4px;
      & > .MuiSelect-root {
        padding: 10.5px 14px;
      }
    }
    &.mb {
      margin-bottom: 8px;
    }
  }
  & > .ta-btns-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-bottom: 22px;
    & > *:not(:first-child) {
      margin-right: 12px;
    }
  }
  & > .photo {
    width: 80px;
    height: 80px;
    @include mq(950px, max) {
    }
  }
  & > .child-Info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .name {
      margin-top: 10px;
      font-size: 15px;
      font-weight: bold !important;
    }
  }
  .parent-name {
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold !important;
  }

  .ta-name {
    display: flex;
    align-items: center;
    justify-content: center;
    & > *:first-child {
      margin-right: 12px;
    }
  }
  .ta-information {
    margin-top: -20px;
    width: 70%;
  }
  .parent-phone-text {
    display: contents;
    font-size: 15px;
    font-weight: 500;
    color: black;
  }
}
.confirm-parent-request {
  .parent-name {
    color: #2ba7df !important;
    text-transform: capitalize;
  }
  .parent-request-confirm-btn {
    background: #2ba7df !important;
    color: #ffffff !important;
    border: 2px solid #2ba7df !important;
  }
}
.reject-parent-request {
  .parent-name {
    color: #fb726e !important;
    text-transform: capitalize;
  }
  .parent-request-confirm-btn {
    background: #fb726e !important;
    color: #ffffff !important;
    border: 2px solid #fb726e !important;
  }
}

.ta-modal-content-scroll {
  max-height: 35rem;
  //overflow-y: hidden !important;
  .fix-display {
    display: contents;
    align-content: flex-start;
    font-size: 13px;
    font-weight: 500;
  }
}

.tests-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  height: 500px;
  & > * {
    width: calc(100% / 4 - 12px) !important;
    margin: 0 6px !important;
    margin-bottom: 12px !important;
    padding: 6px !important;
  }
}

.test-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  & > .label {
    color: #0d0e0f;
    font-size: 18.5px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  & > .ta-btn {
    width: 183px;
    padding: 6px;
  }
  & > hr {
    width: 100%;
    border: none;
    border-top: 1px solid #e2e2e4;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  & > .docs-container {
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    margin-bottom: 16px;
    & > .description {
      font-size: 16px;
      font-weight: 400;
      color: #0d0e0f;
      margin-bottom: 2px;
    }
    & > .doc {
      color: #838eab;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      transition: all 300ms ease;
      margin-bottom: 2px;
      display: flex;
      &:hover {
        color: #18698d;
        font-weight: 600;
      }
      & > .MuiSvgIcon-root {
        margin: 0 2px;
      }
    }
  }
}

.timer-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px !important;
  & > img {
    width: 240px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  & > .label {
    color: #0d0e0f;
    font-size: 24px;
    font-weight: 600;
  }
  & > .sub-label {
    color: #a2a2a7;
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.switch-classes-container {
  display: flex;
  flex-direction: column;
  & > .title {
    font-size: 14px;
    font-weight: 600;
    color: #0d0e0f;
  }
  &.progress {
    cursor: progress !important;
  }
}

.switch-classe-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 22px 42px;
  cursor: pointer;
  & > .texts {
    display: flex;
    flex-direction: column;
    z-index: 8;
    transform: translateX(12px);
    & > .group {
      display: flex;
      & > .number {
        font-size: 104px;
        font-weight: 600;
        line-height: 74px;
        color: #2ba7df;
      }
      & > .sup {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #838eab;
      }
    }
    & > .label {
      font-size: 11px;
      color: #2ba7df;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  & > .box {
    width: 130px;
    height: 54px;
    border: 3px solid #838eab;
    border-radius: 16px;
    z-index: 4;
    margin-top: -48px;
    &::before {
      content: '';
      width: 64px;
      height: 8px;
      background: white;
      display: flex;
      margin: 0 auto;
      margin-top: -6px;
    }
    &::after {
      transition: all 300ms ease;
      content: '';
      width: 142px;
      height: 34px;
      background: #d5edf9;
      display: flex;
      margin: 0 auto;
      z-index: -1000;
      transform: translateX(-50%) translateY(24%) rotate(-3deg);
      margin-left: 61px;
    }
  }
  &:hover > .box::after {
    background: #f9ebd5;
    transform: translateX(-50%) translateY(24%) rotate(6deg);
  }
}

.switch-classes-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 42px auto;
  margin-bottom: 8px;
}

.switch-users-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > .switch-classe-item {
    cursor: default;
    &:hover > .box::after {
      background: #d5edf9;
      transform: translateX(-50%) translateY(24%) rotate(-3deg);
    }
    & > .texts > .group {
      & > .MuiCircularProgress-root {
        color: #2ba7df !important;
        position: absolute;
        top: 2px;
        left: -24px;
        z-index: 12;
      }
    }
  }
  & > .btns-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-bottom: 40px;
    & > * {
      margin: 8px !important;
    }
    & > .ta-btn {
      width: 160px;
      height: auto !important;
    }
  }
}

.suggestion-modal-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px !important;
  & > .avatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    & > .MuiAvatar-root {
      width: 48px;
      height: 48px;
    }
    & > .texts {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 14px;
      & > .name {
        color: #757575;
        font-size: 20px;
        font-weight: 600;
      }
      & > .date {
        color: #757575;
        font-size: 12px;
      }
    }
  }
  & > .label {
    color: #18698d;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  & > img {
    width: 100%;
    margin-bottom: 16px;
  }
  & > .title {
    color: #0d0e0f;
    font-size: 16.5px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  & > .description {
    color: #a2a2a7;
    font-size: 15px;
    margin-bottom: 24px;
  }
  & > .MuiButtonBase-root {
    width: 104px;
  }
}

.delete-confirmation-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  & > .delete-Icon {
    font-size: 150px !important;
    color: rgb(238, 148, 96);
  }
  & > .delete-title {
    font-size: 30px;
    color: #757575;
  }
  & > .delete-description {
    font-size: 15px;
    margin-bottom: 20px;
    color: #636161;
  }
}

.ta-btns-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;

  &.right-small {
    position: relative;
    left: 140px !important;
  }
}

.only-text {
  padding: 6px 14px !important;
}

.only-icon {
  min-width: auto !important;
}

.files-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  &.exams {
    padding: 0 18px;
    margin-top: 0;
    margin-bottom: 16px;
  }
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
  &.last-with-margin {
    & > * {
      margin-bottom: 8px !important;
    }
  }
  // & > .file {
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   & > .title {
  //     background: #d5edf9;
  //     border-top-left-radius: 4px;
  //     border-bottom-left-radius: 4px;
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: flex-start;
  //     align-items: center;
  //     padding: 4px 8px;
  //     flex-grow: 1;
  //     transition: all 300ms ease;
  //     cursor: pointer;
  //     &.rounded {
  //       border-radius: 4px;
  //     }
  //     & > img {
  //       width: 20px;
  //       margin-right: 4px;
  //     }
  //     & > span {
  //       font-size: 14px;
  //       font-weight: 500;
  //       text-transform: lowercase;
  //       color: black;
  //     }
  //     &:hover {
  //       background: #80cff3;
  //     }
  //   }
  //   & > .ta-btn {
  //     border-top-left-radius: 0 !important;
  //     border-bottom-left-radius: 0 !important;
  //   }
  // }
}

.text-ellipsis {
  & > .MuiButton-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.sessionModal {
  display: flex;
  flex-direction: column;
  margin: 20px;
}
.sessionDescription {
  font-weight: bold !important;
  padding-top: 5px;
  @media screen and (max-width: 599px) {
    font-size: 15px !important;
  }
}
.sessionLevels {
  font-weight: bold !important;
  .sessionLevels {
    color: $grey;
  }
}
.infoSpan {
  color: $grey;
  font-weight: bold;
  @media screen and (max-width: 599px) {
    margin: 5px 0px;
  }
}
.infoValue {
  color: $blue;
  font-weight: lighter !important;
  margin-left: 5px !important;
  text-shadow: 0 0 0 $blue;
}
.sessionInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 10px;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}
.levels {
  border: #ed5454 solid 1px;
  border-radius: 4px;
  margin-left: 5px !important;
  color: #ed5454;
  padding: 2px 5px;
  font-size: 12px;
  justify-content: center;
  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}
.sessionDocuments {
  padding-top: 10px;
}

.d17 {
  display: flex;
  align-items: center;
  justify-content: center;
  .text-modal-D17 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Qr-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px !important;
    margin-bottom: 20px;
  }
}

.confirm-text {
  font-size: 15px;
  padding: 30px;
  text-align: center;
  font-weight: bold;
}
.confirm-arabic-text {
  direction: rtl !important;
}
.resend-code-link {
  color: #0d0e0f;
  text-align: center;
  font-family: tajawal;
  font-weight: bold;
  font-size: 14px;
  opacity: 1;
  text-transform: none;
}
.confirm-transfer-link {
  color: #2ba7df;
  text-decoration: underline;
  text-underline: #2db3f3;
  font-weight: bold;
  align-items: center;
  position: relative;
  font-size: 14px;
  opacity: 1;
  text-transform: none;
  cursor: pointer;
}
.btn-transfer {
  display: flex;
  justify-content: flex-end;
}
.link-transfer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
