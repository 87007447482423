.error {
  margin: 0;
  color: #ed3863;
  font-size: 13px;
  display: flex;
  padding-top: 6px;
  margin-left: 2px;
  &::after {
    content: '\a';
    white-space: pre;
  }
  svg {
    width: 17px;
    height: 15px;
  }
}
.arabic-error {
  justify-content: flex-end;
}
