.prrain-product-details-page {
  margin-top: 1.4rem;
  padding: 0 3.3rem;

  @media screen and (max-width: 720px) {
    padding: 0 0.3rem;
  }

  .prrain-product-details {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 1rem;

    @media screen and (max-width: 1260px) {
      justify-content: center;
    }
    @media screen and (max-width: 760px) {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & > .MuiCardContent-root {
      justify-content: center;
      gap: 6.5rem;
      justify-content: center;
      padding: 7.5rem 10rem 5.7rem 10rem !important;

      @media screen and (max-width: 1688px) {
        padding: 7.5rem 5rem 5.7rem 10rem !important;
      }
      @media screen and (max-width: 1550px) {
        padding: 7.5rem 5rem 5.7rem 4rem !important;
      }
      @media screen and (max-width: 1260px) {
        flex-direction: column;
      }

      @media screen and (max-width: 760px) {
        padding: 2.5rem 2rem 2.7rem !important;
      }

      @media screen and (max-width: 720px) {
        padding: 1rem 0rem !important;
      }

      @media screen and (max-width: 440px) {
        padding: 0rem !important;
      }
    }
    .prrain-product-details-text {
      display: flex;
      flex-direction: column;
      gap: 6.4rem;
      justify-content: space-between;
      flex-basis: 38%;
      @media screen and (max-width: 1520px) {
        gap: 3rem;
      }
      @media screen and (max-width: 1450px) {
        gap: 3rem;
        max-height: 77%;
      }

      .product-name {
        color: #1c1c1c;
        font-family: Gothic A1 !important;
        font-size: 45px;
        font-weight: 700;
        line-height: normal;

        @media screen and (max-width: 1520px) {
          font-size: 2.5rem;
        }
        @media screen and (max-width: 1495px) {
          font-size: 2rem;
        }
      }
      .product-description {
        color: #a2a2a7;
        font-family: Gothic A1 !important;
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: 1520px) {
          font-size: 1.3rem;
        }
        @media screen and (max-width: 1495px) {
          font-size: 1rem;
        }
      }
      .product-amount {
        color: #1c1c1c;
        font-family: Gothic A1 !important;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: 1520px) {
          font-size: 1.6rem;
        }
        @media screen and (max-width: 1495px) {
          font-size: 1.3rem;
        }
      }
      & > button {
        border-radius: 17.55px;
        background: #2998f4;
        color: #fff;

        text-align: center;
        font-family: Gothic A1 !important;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1rem;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          background: darken(#2998f4, 10);
        }
      }
    }
    .prrain-product-details-images {
      flex-basis: 50%;
      display: flex;
      gap: 1rem;
      @media screen and (max-width: 1450px) {
        flex-direction: column;
      }

      @media screen and (max-width: 1260px) {
        justify-content: center;
        align-items: center;
      }
      @media screen and (max-width: 1085px) {
        flex-direction: column;
      }
      .prrain-product-details-selected-image {
        border-radius: 20.2px;
        border: 0.673px solid #a2a2a7;
        background: #f9f9f9;
        max-width: 32.5rem;

        height: 34.6rem;
        min-width: 27.1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 828px) {
          min-width: unset;
          width: 100%;
        }
        @media screen and (max-width: 600px) {
          height: 100%;
        }

        & > img {
          object-fit: fill;
          border-radius: 20.2px;
          width: 100%;
          height: 100%;

          @media screen and (max-width: 828px) {
            max-width: 70%;
            max-height: 60%;
          }
        }
      }
      .prrain-product-details-images-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media screen and (max-width: 1450px) {
          flex-direction: row;
        }
        @media screen and (max-width: 600px) {
          width: 290px;
        }
        .prrain-product-details-product-image {
          width: 9rem;
          border-radius: 20.2px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 10rem;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            border-radius: 20.2px;
          }

          @media screen and (max-width: 1520px) {
            max-width: 14rem;
          }

          @media screen and (max-width: 1450px) {
            min-width: 9rem;
            max-width: 14rem;
          }
          @media screen and (max-width: 1085px) {
            min-width: unset;
          }
          @media screen and (max-width: 600px) {
            height: 100%;
          }
        }
      }
    }
  }
}

.prrain-product-details-page-full-sidebar {
  @extend .prrain-product-details-page;

  @media screen and (max-width: 922px) {
    padding: 0 0.3rem;
  }
  .prrain-product-details {
    & > .MuiCardContent-root {
      @media screen and (max-width: 1840px) {
        padding: 7.5rem 5rem 5.7rem 5rem !important;
      }

      @media screen and (max-width: 1060px) {
        padding: 3.5rem 1rem 2.7rem 1rem !important;
      }
    }
  }
  @media screen and (max-width: 1730px) {
    .prrain-product-details-images {
      flex-direction: column !important;
    }

    .prrain-product-details-images-list {
      flex-direction: row !important;
    }
  }
  @media screen and (max-width: 1530px) {
    .prrain-product-details {
      justify-content: center;
      & > .MuiCardContent-root {
        flex-direction: column;
      }
    }
    .prrain-product-details-images {
      justify-content: center;
      align-items: center;
    }
  }

  .prrain-product-details {
    @media screen and (max-width: 790px) {
      padding: 1rem 0;
    }
  }
}
