@use './../../assets//scss/utilities/mixins' as *;
@use './../../assets//scss/utilities/variables' as *;

.register-page {
  & .auth-page-content {
    gap: 0 !important;
  }
  & .register-form {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    width: 92%;
    margin-right: 2rem;

    & .register-form-content {
      .auth-btn {
        margin-top: 20px !important;
      }
      .form-control {
        .field-wrapper {
          padding-top: 15px !important;

          .field {
            padding: 0 15px !important;
          }
        }
      }
      .select-wrapper {
        padding-top: 5px !important;
      }
    }
    & .return-to-login {
      display: flex;

      justify-content: center;
      margin-top: 15px;
      font-family: 'Tajawal' !important;
      align-self: center;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 17px;
      @media (min-width: 1055px) and (max-width: 1350px) {
        font-size: 1.1rem;
        margin-top: 5px;
      }
      @media screen and (max-width: 327px) {
        flex-direction: column;
        align-items: center;
      }
      & > a {
        font-family: 'Tajawal' !important;
        color: #35bbe3;
        transition: 0.1s;
        &:hover {
          border-bottom: 1px solid #35bbe3;
          width: fit-content;
        }
      }

      @media (min-width: 1351px) and (max-width: 1512px) {
        margin-top: 0 !important;
      }
      @media screen and (max-width: 650px) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: 380px) {
        font-size: 1rem;
      }
    }
    & .arabic-return-to-login {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 1055px) {
      width: 96%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (min-width: 1055px) and (max-width: 1350px) {
      width: 83%;
      margin-left: 53px;
      & > form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    & > form {
      @media screen and (max-width: 1055px) {
        width: 85%;
      }
      @media screen and (max-width: 650px) {
        width: 90%;
      }
    }
  }
  .form-grid {
    column-gap: 38px !important;
    row-gap: 10px !important;
    width: 100%;
    @media (min-width: 1055px) and (max-width: 1350px) {
      column-gap: 22px !important;
    }
    @media screen and (max-width: 1550px) {
      & .form-control-order {
        order: -1;
      }

      & .form-control-arrange {
        order: 1;
      }
    }

    @media screen and (max-width: 1055px) {
      grid-template-columns: repeat(2, 1fr) !important;
      & .form-control-order {
        order: 0;
      }
      & .form-control-arrange {
        order: 0;
      }
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr) !important;
      & .form-control-order {
        order: -1;
      }
      & .form-control-arrange {
        order: 1;
      }
    }
  }
}
.register-form {
  .form-control {
    .field-wrapper {
      padding-top: 28px !important;
    }
  }
  & > .auth-btn {
    margin-top: 14px !important;
  }
}
