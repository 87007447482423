.option-card {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.7rem 0.95rem 1.3rem;
  min-width: 11.5rem;
  grid-gap: 1rem;
  gap: 1rem;
  min-height: 13.8rem;
  flex: 1;

  @media only screen and (min-width: 1210px) {
    max-width: 22.8rem;
    padding: 1.5rem 4rem;
    border-radius: 32px;

    &:first-child {
      order: 1;
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: -1;
    }
  }

  @media only screen and (max-width: 430px) {
    min-width: 7.5rem !important;
  }

  & h5 {
    font-family: 'Tajawal' !important;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: #000000;
    margin-block: 0;

    @media only screen and (min-width: 1210px) {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
}
